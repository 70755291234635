import React, { useState, useEffect, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { Tabs } from 'admin-base-component-library';
import { withStyles, CircularProgress } from '@material-ui/core';
import flow from 'lodash.flow';

import General from './Tabs/General';
import UserContext from '../../context/UserContext';

import { useUserPermission } from './permissions.js';
import { getSegments } from '../../services/segment';
import { getRestaurantByID } from '../../services/restaurant';

import styles from './styles';

const ProductCreate = ({
  classes,
  history,
  match: { params },
  t,
}) => {
  const userPermission = useUserPermission();
  const { user } = useContext(UserContext);
  const [product, setProduct] = useState([]);
  const [restaurant, setRestaurant] = useState(null);
  const [segments, setSegments] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      const fetchRestaurant = await getRestaurantByID(user?.restaurant?.id);
      const fetchSegments = await getSegments();

      const segmentsData = fetchSegments.map(item => ({
        value: item.id,
        label: item.name,
      }));

      setRestaurant(fetchRestaurant);
      setSegments(segmentsData);
      setLoading(false);
    };

    init();
  }, []);

  if (loading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Tabs
      btnCloseLabel={'Fechar'}
      components={[
        {
          name: t('Novo produto'),
          route: `/${t('produtos')}/${t('novo')}`,
          screen: (
            <General
              t={t}
              params={params}
              classes={classes}
              history={history}
              segments={segments}
              restaurant={restaurant}
              setProduct={setProduct}
              product={product}
              userPermission={userPermission}
            />
          ),
        },
      ]}
    />

  );
};


export default flow(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(ProductCreate);
