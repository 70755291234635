import i18n from 'i18next';
import * as yup from 'yup';

const validEmail = (email) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(email.trim());
};

const phoneRegex = /\(\d{2,}\) \d{4,}-\d{4}/;
const cepRegex = /^[0-9]{5}-[0-9]{3}$/;
const dateRegex = /^(\d){2}\/(\d){2}\/(\d){4}$/;

const defaultMessage = () => {
  const defaultMessageError = 'Obrigatório';
  yup.setLocale({
    mixed: { required: defaultMessageError, default: defaultMessageError },
    string: { required: defaultMessageError, default: defaultMessageError },
    number: { required: defaultMessageError, default: defaultMessageError },
    array: { required: defaultMessageError, default: defaultMessageError },
  });
};

export const fieldRequired = (field) => {
  const t = value => i18n.t(value);
  return `${t('O campo')} ${t(field)} ${t('é obrigatório')}.`;
};

export {
  validEmail, defaultMessage, phoneRegex, cepRegex, dateRegex,
};
