const fields = (history, t, userPermission) => [
  {
    align: 'left',
    name: 'Restaurante',
    key: 'restaurant',
    tableHidden: userPermission('restaurant', 'tableHidden'),
  },
  {
    align: 'left',
    name: 'Nome/Marca(s)/Gramatura',
    key: 'name',
    sortable: true,
  },
  {
    align: 'center',
    name: 'Fornecedores',
    key: 'suppliersCount',
    type: 'clickable',
    onClickCell: row => history.push({
      pathname: `/${t('produtos')}/${t('editar')}/${row.id}/${t('fornecedores')}`,
    }),
  },
  {
    align: 'left',
    name: 'Segmentos',
    key: 'segmentsSummary',
    type: 'tooltip',
    tooltipKey: 'segmentsTooltip',
  },
  {
    align: 'center',
    name: 'Ações',
    key: 'actions',
    type: 'action',
    buttons: ['view', 'edit', 'remove'],
  },
];

export const fieldsSupplier = (edit, changeStatus) => [
  {
    align: 'left',
    name: 'Nome da empresa',
    key: 'name',
    sortable: true,
  },
  {
    align: 'left',
    name: 'Nome do representante',
    key: 'contactName',
    sortable: true,
  },
  {
    align: 'center',
    name: 'Ativo',
    key: 'status',
    type: edit ? 'checkbox' : 'default',
    onChange: (event, row) => changeStatus(row, event),
  },
];

export const tableImport = [
  {
    align: 'left',
    name: 'Linha',
    key: 'line',
  },
  {
    align: 'left',
    name: 'Erros',
    key: 'error',
    cellStyle: {
      whiteSpace: 'pre-line',
      wordBreak: 'break-word',
    },
  },
];

export default fields;
