import { PAYMENT_STATUS } from '../../services/restaurant';

export const defaultValues = {
  name: '',
  contact: '',
  cnpj: null,
  email: '',
  paymentStatus: PAYMENT_STATUS[0],
  active: true,
  parent: { label: 'Nenhum' },
  phone: '',
  address: {
    zipCode: '',
    street: '',
    number: '',
    complement: '',
    state: '',
    city: '',
    neighborhood: '',
  },
};

export const form = (
  restaurants,
  view,
  isCreating = false,
  hasChild = false,
  hasParent = false,
) => [
  {
    name: 'name',
    label: 'Nome do restaurante',
    required: true,
    grid: {
      xs: 12, md: 6,
    },
  },
  {
    name: 'cnpj',
    label: 'CNPJ',
    format: 'cnpj',
    grid: {
      xs: 12, md: 3,
    },
  },
  {
    name: 'paymentStatus',
    label: 'Status do pagamento',
    type: 'select',
    options: PAYMENT_STATUS.map(item => ({ value: item, label: item })),
    grid: {
      xs: 12, md: 3,
    },
  },
  {
    name: 'contact',
    label: 'Nome do responsável',
    grid: {
      xs: 12, md: 4,
    },
  },
  {
    name: 'email',
    label: 'E-mail',
    format: 'email',
    readOnly: data => !!data?.email,
    grid: {
      xs: 12, md: 5,
    },
  },
  {
    name: 'phone',
    label: 'Telefone',
    format: 'phone',
    required: true,
    grid: {
      xs: 12, md: 3,
    },
  },
  {
    name: 'active',
    label: 'Ativo',
    type: 'select',
    options: [
      { value: true, label: 'Sim' },
      { value: false, label: 'Não' },
    ],
    grid: {
      xs: 12, md: 3,
    },
  },
  {
    name: 'parent',
    label: 'Restaurante Matriz',
    type: 'autocomplete',
    readOnly: (
      _, { productsFromRestaurant, excludeProductsList },
    ) => !!productsFromRestaurant.value || !!excludeProductsList[0],
    hidden: hasChild,
    options: restaurants,
    grid: {
      xs: 12, md: 6,
    },
  },
  {
    name: 'address',
    type: 'address',
  },
  {
    label: 'Produtos',
    type: 'title',
    hidden: view === 'visualizar' || (view === 'editar' && hasParent),
    grid: {
      xs: 12,
    },
  },
  {
    name: 'excludeProductsList',
    type: 'checkbox',
    readOnly: (_, { parent }) => !!parent.value,
    hidden: view === 'visualizar' || (view === 'editar' && hasParent) || isCreating,
    options: [{ value: true, label: 'Excluir todos os produtos' }],
    grid: {
      xs: 12,
    },
  },
  {
    name: 'productsFromRestaurant',
    label: 'Copiar lista de produtos',
    type: 'autocomplete',
    readOnly: (_, { parent }) => !!parent.value,
    hidden: view === 'visualizar' || (view === 'editar' && hasParent),
    options: restaurants,
    grid: {
      xs: 12,
    },
  },
];

const filterFields = [
  'name',
  'contact',
  'neighborhood',
  'phone',
  'paymentStatus',
];

export const formFilterDefaultValues = supplierName => ({
  search: '',
  field: filterFields,
  supplierName,
});

export const formFilter = supplierName => [
  {
    name: 'supplierName',
    label: 'Fornecedor',
    readOnly: true,
    hidden: !supplierName,
    grid: { xs: 6, sm: 3 },
    size: 'small',
  },
  {
    name: 'search',
    label: 'Buscar',
    grid: { xs: 6, sm: 3 },
    size: 'small',
  },
  {
    name: 'field',
    label: 'Campo',
    type: 'select',
    options: [
      { value: [filterFields[0]], label: 'Nome do restaurante' },
      { value: [filterFields[1]], label: 'Nome do responsável' },
      { value: [filterFields[2]], label: 'Bairro' },
      { value: [filterFields[3]], label: 'Telefone' },
      { value: [filterFields[4]], label: 'Status do pagamento' },
      { value: filterFields, label: 'Todos' },
    ],
    grid: { xs: 6, sm: 3 },
    size: 'small',
  },
];

export const formSupplierLink = suppliers => [
  {
    name: 'supplier',
    label: 'Fornecedores',
    type: 'autocomplete',
    options: suppliers,
    required: true,
    grid: { xs: 6 },
  },
];
