import React from 'react';
import flow from 'lodash.flow';
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import styles from '../styles';

const InfoField = ({
  t,
  classes,
  label,
  name,
  values,
}) => (
  !!values[name] ? (
    <div className={classes.info}>
      <b>{`${t(label)}: `}</b>
      {values[name]}
    </div>
  ) : null
);

export default flow(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(InfoField);
