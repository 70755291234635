import {
  white,
  black,
  logoMain,
  logoSecondary,
  logoLighter,
} from '../assets/colors';

export default {
  common: {
    black,
    white,
    neutral: '#E4E7EB',
    muted: '#9EA0A4',
  },
  menu: {
    background: logoMain,
    hover: logoLighter,
    selected: logoSecondary,
    dividers: logoLighter,
    text: white,
    textSelected: logoMain,
  },
  primary: {
    contrastText: white,
    main: logoMain,
    light: '#F6F9FD',
  },
  secondary: {
    contrastText: white,
    main: logoSecondary,
  },
  success: {
    contrastText: white,
    main: '#45B880',
    light: '#C6F3DA',
    dark: '#00783E',
  },
  info: {
    contrastText: white,
    main: '#1070CA',
    light: '#F1FBFC',
    dark: '#007489',
  },
  warning: {
    contrastText: white,
    main: '#FFB822',
    light: '#FDF8F3',
    dark: '#95591E',
  },
  danger: {
    contrastText: white,
    main: '#ED4740',
    light: '#FEF6F6',
    dark: '#BF0E08',
  },
  tomato: {
    contrastText: white,
    main: '#DE350B',
    light: '#FFBDAD',
  },
  text: {
    primary: '#434242',
    secondary: '#656464',
    disabled: '#AFAFAF',
  },
  background: {
    default: '#F8FAFC',
    dark: '#172B4D',
    paper: white,
  },
  border: logoLighter,
  divider: logoLighter,
};
