import React from 'react';
import { Alert as AlertMUI } from '@material-ui/lab';

import { USER_ROLES } from '../../../services/user';
import { formatCurrency } from '../../../utils/formatters';

const AlertMinOrderSupplier = ({
  t,
  classes,
  user,
  minOrder,
  childHasDeficit,
}) => (
  <>
    {user?.type !== USER_ROLES.master
      && minOrder
      && minOrder.map(({ name, amount, hasSelectedProduct }) => (
        hasSelectedProduct && amount > 0 ? (
          <AlertMUI
            className={classes.alertDanger}
            icon={false}
            variant="filled"
            severity="warning"
          >
            {user?.type !== USER_ROLES.master ? t(`Faltam ${formatCurrency(amount)} para fechar o pedido mínimo da ${name}.`) : t('Verifique na aba de Restaurantes Associados os valores faltantes para o pedido mínimo do fornecedor')}
          </AlertMUI>
        ) : null))
    }
    {
      childHasDeficit && user?.type === USER_ROLES.master && (
        <AlertMUI
          className={classes.alertDanger}
          icon={false}
          variant="filled"
          severity="warning"
        >
          {t('Verifique na aba de Restaurantes Associados os valores faltantes para o pedido mínimo do fornecedor')}
        </AlertMUI>
      )
    }
  </>
);

export default AlertMinOrderSupplier;
