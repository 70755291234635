import config from '../config';
import { hasNoPermissionForPath } from './permissions';

const requestNewSupplier = (subjectText) => {
  const { env } = config;
  const subject = `subject=${subjectText}`;
  const body = 'body=Preencha as informações abaixo para solicitar o cadastro de um novo fornecedor:%0D%0DNome da Empresa:%0DNome do Representante:%0DTelefone:';
  const mailto = `mailto:${env.mail.email}?${subject}&${body}`;
  window.location.href = mailto;
};

const topBarButtons = (t, userType) => ([
  {
    title: t('Solicitar Fornecedor'),
    action: () => requestNewSupplier(
      t('Solicitar Fornecedor'),
    ),
    hidden: hasNoPermissionForPath(userType, 'requestSupplier'),
  },
]);

export default topBarButtons;
