import moment from 'moment';
import { FirebaseService } from 'admin-base-component-library';

export const generateTag = str => str
  .replace(/\s+/g, '-')
  .normalize('NFD')
  .replace(/[^a-zA-Zs-]/g, '')
  .toLowerCase();

export const arrayToCollectionAssociate = (data) => {
  if (Array.isArray(data)) {
    return data.reduce((accumulate, current) => ({
      ...accumulate,
      [current.value]: true,
    }), {});
  }

  return null;
};

export const arrayToCollectionAssociateToOptions = (collectionArray, options) => {
  if (!collectionArray) {
    return '';
  }

  return options.reduce((allItens, current) => {
    if (collectionArray[current.value]) {
      allItens.push(current);
    }

    return allItens;
  }, []);
};

export const updateArrayAssociate = async ({
  collection,
  field,
  newValues = {},
  oldValues = {},
  id,
}) => {
  const oldValuesFormat = Array.isArray(oldValues)
    ? arrayToCollectionAssociate(oldValues) : oldValues;

  const newValuesFormat = Array.isArray(newValues)
    ? arrayToCollectionAssociate(newValues) : newValues;

  Promise.all(
    Object.keys(oldValuesFormat || {}).map(async (value) => {
      if (!newValuesFormat || !newValuesFormat[value]) {
        const data = await FirebaseService.getInstanceById(collection, value);
        if (data[field][id]) {
          const remove = { ...data[field] };
          delete remove[id];
          FirebaseService.update(collection, value, {
            [field]: remove,
          });
        }
      }
    }),
  );

  Promise.all(
    Object.keys(newValues || {}).map(async (value) => {
      if (!oldValues || !oldValues[value]) {
        const data = await FirebaseService.getInstanceById(collection, value);
        FirebaseService.update(collection, value, {
          [field]: {
            ...data[field],
            [id]: true,
          },
        });
      }
    }),
  );
};

export const TimestampToDate = (date, format = 'DD/MM/YYYY') => (
  moment(new Date(date.seconds * 1000)).format(format)
);

export const TimestampToDateTime = date => (
  TimestampToDate(date, 'DD/MM/YYYY HH:mm')
);

export const TimestampToDateWithFormatUS = date => (
  TimestampToDate(date, 'YYYY-MM-DDTHH:mm')
);

export const numberToFloat = value => (
  value ? Number(value.replace(/\./g, '').replace(',', '.')) : 0
);

export const numberToCurrency = value => (
  value.toLocaleString('pt-br', { minimumFractionDigits: 2 })
);
