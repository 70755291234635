import React, { useEffect, useState } from 'react';
import {
  CustomTable, CustomForm, Alert, Screen,
} from 'admin-base-component-library';
import { CircularProgress } from '@material-ui/core';
import orderData from 'lodash.orderby';
import { formSupplierLink } from '../form';
import { tableSupplier as table } from '../table';
import { restaurantLinkSupplier, restaurantUnLinkSupplier } from '../../../services/restaurant';

const Supplier = ({
  t,
  params,
  restaurant,
  suppliers,
  setRestaurant,
}) => {
  const [loading, setLoading] = useState(true);

  const fetchData = async () => suppliers.filter(({ id }) => restaurant.suppliers.indexOf(id) > -1);

  useEffect(() => {
    fetchData();
    setLoading(false);
  }, [suppliers]);

  const handleSave = async ({ supplier }) => {
    await restaurantLinkSupplier(restaurant, supplier.value);
    setRestaurant({
      ...restaurant,
      suppliers: [
        ...restaurant.suppliers,
        supplier.value,
      ],
    });

    Alert({
      title: t('Fornecedor Atualizado'),
      text: t('Fornecedor vinculado com sucesso.'),
      type: 'success',
    });
  };

  const removeItem = async (supplier) => {
    await restaurantUnLinkSupplier(restaurant, supplier.id);
    setRestaurant({
      ...restaurant,
      suppliers: restaurant.suppliers.filter(id => id !== supplier.id),
    });

    Alert({
      title: t('Fornecedor Atualizado'),
      text: t('Fornecedor desvinculado com sucesso.'),
      type: 'success',
    });
  };


  const Table = props => (
    <CustomTable
      tableCells={table(params.type === t('visualizar'))}
      sortData={(order, orderBy, values) => {
        if (order) {
          return orderData(values, [orderBy], [order]);
        }
        return values;
      }}
      maxHeight="70vh"
      isVertical
      numberedItems
      {...props}
    />
  );

  const FilterForm = (props) => {
    if (params.type !== t('editar')) {
      return null;
    }

    if (loading) {
      return (
        <CircularProgress />
      );
    }

    return (
      <CustomForm
        {...props}
        fields={formSupplierLink(suppliers.reduce((suppliersWithoutRestaurant, supplier) => {
          if (restaurant.suppliers.indexOf(supplier.id) === -1) {
            suppliersWithoutRestaurant.push({
              value: supplier.id,
              label: `${supplier.phone} - ${supplier.name} (${supplier.contactName})`,
            });
          }

          return suppliersWithoutRestaurant;
        }, []))}
        buttonOkLabel="Adicionar"
        buttonCancel={false}
        confirmEdit={handleSave}
        filterForm
      />
    );
  };


  return (
    <Screen
      isAdded={false}
      fetchData={fetchData}
      title="Supplier"
      removeMessage={`${t('Tem certeza que deseja excluir')} ${t('este fornecedor')}?`}
      mainComponent={Table}
      filtersComponent={FilterForm}
      removeItem={removeItem}
      pagination={false}
    />
  );
};

export default Supplier;
