import { Exceptions, FirebaseService } from 'admin-base-component-library';
import { generateTag } from '../Service';

export const saveNeighborhood = async ({
  name,
  state = 'SC',
  city = 'Joinville',
}, id) => {
  const tag = generateTag(`${state} ${city} ${name}`);

  const neighborhood = await FirebaseService.getDataList({
    collectionName: 'neighborhood',
    filters: [
      { field: 'tag', type: '==', value: tag },
    ],
  });

  if (name.toLowerCase() === 'todos') {
    throw new Exceptions.RenderException('O bairro não pode ter o nome todos.');
  }

  if (neighborhood.filter(({ id: neighborhoodId }) => neighborhoodId !== id).length > 0) {
    throw new Exceptions.RenderException('Já existe um bairro com este nome.');
  }

  const data = {
    name, tag, state, city,
  };

  if (id) {
    await FirebaseService.update('neighborhood', id, data);
    return { id };
  }

  return FirebaseService.create('neighborhood', data);
};

export const getNeighborhoods = async () => FirebaseService.getDataList({
  collectionName: 'neighborhood',
});
