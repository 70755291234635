import React, { useState, useContext, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Screen, CustomTable, CustomForm, Alert,
} from 'admin-base-component-library';

import { StarBorder, Star } from '@material-ui/icons';

import flow from 'lodash.flow';
import { withStyles, CircularProgress } from '@material-ui/core';
import {
  getBudgets,
  getChildBudget,
  getStatusLabel,
  removeBudget,
  setIsTemplate,
  ITEMS_PER_PAGE,
  BUDGET_STATUS,
  deleteChildBudget,
  getRecentBudgetResponses,
} from '../../services/budget';
import { getRestaurants } from '../../services/restaurant';
import { TimestampToDateTime } from '../../services/Service';

import UserContext from '../../context/UserContext';

import { useUserPermission } from './permissions';

import form, { filterFields } from './form';
import table from './table';
import styles from './styles';
import { USER_ROLES } from '../../services/user';

const OrderBudget = ({ t, history, classes }) => {
  const { user } = useContext(UserContext);
  const userPermission = useUserPermission();
  const [loading, setLoading] = useState(false);
  const [restaurants, setRestaurants] = useState(null);
  const [restaurantsOptions, setRestaurantsOptions] = useState([]);
  const [requestBudgetId, setRequestBudgetId] = useState();

  useEffect(() => {
    const getInit = async () => {
      setLoading(true);
      const fetchRestaurants = await getRestaurants();
      const optionsRestaurants = [];
      setRestaurants(fetchRestaurants.reduce((allRestaurants, current) => {
        const label = current.active === false ? getInactiveName(current.name) : current.name;
        optionsRestaurants.push({ value: current.id, label });
        return {
          ...allRestaurants,
          [current.id]: {
            ...current,
          },
        };
      }, {}));
      setRestaurantsOptions(optionsRestaurants);
      setLoading(false);
    };

    if (user?.type === USER_ROLES.admin) {
      getInit();
    }
  }, [user]);

  const getInactiveName = name => `(Inativo) - ${name}`;

  const getResponsesLabel = (responses = 0, suppliers = 0) => `${responses}/${suppliers}`;

  const formatResponses = responses => responses.reduce((responseAcc, response) => ({
    ...responseAcc,
    [response.idBudget]: [
      ...(responseAcc[response.idBudget] ?? []),
      response,
    ],
  }), {});

  const fetchData = async ({ page, filters, ordering }) => {
    const isFranchise = user?.type === USER_ROLES.franchise;
    const isAdmin = user?.type === USER_ROLES.admin;

    let params = { ...filters };
    if (user?.restaurant?.id) {
      params = { ...filters, restaurantId: user.restaurant.id };
    }

    if (isFranchise) {
      params = {
        ...filters,
        status: [
          BUDGET_STATUS.REQUESTED,
          BUDGET_STATUS.FINALIZED,
          BUDGET_STATUS.CANCELLED,
          BUDGET_STATUS.OPEN,
        ],
        restaurantId: user.restaurant.parent,
      };
    }

    const fetch = await getBudgets({ filter: params, page, ordering });
    const responses = isAdmin ? await getRecentBudgetResponses() : [];

    const formattedResponses = formatResponses(responses);

    const budgetData = fetch?.data;

    const findBudget = budgetData?.find(item => item?.status === BUDGET_STATUS.REQUESTED);

    const hasRequest = !!findBudget;
    if (hasRequest) {
      setRequestBudgetId(findBudget?.id);
    }

    let childBudget;
    if (isFranchise) {
      childBudget = await getChildBudget({
        filters: [
          { field: 'restaurantId', type: '==', value: user?.restaurant?.id },
        ],
      });
    }
    if (!!childBudget && childBudget.length > 0 && isFranchise) {
      childBudget.forEach((item) => {
        const budgetIndex = budgetData.findIndex(current => current.id === item.idMainBudget);
        budgetData[budgetIndex] = {
          ...budgetData[budgetIndex],
          status: budgetData[budgetIndex]?.status === BUDGET_STATUS.REQUESTED
            ? item?.status : budgetData[budgetIndex]?.status,
          idMainBudget: item.idMainBudget,
          id: item.id,
          requestBudgetId: findBudget?.id,
        };
      });
    }

    const data = budgetData?.map((budget) => {
      let restaurantName = '';
      if (restaurants) {
        const obj = restaurants[budget.restaurantId];
        const isInactive = obj.active === false;
        restaurantName = isInactive ? getInactiveName(obj.name) : obj.name;
      }

      return {
        ...budget,
        createdAt: TimestampToDateTime(budget.createdAt),
        endsAt: budget.endsAt ? TimestampToDateTime(budget.endsAt) : '',
        statusLabel: t(getStatusLabel(budget.status)),
        template: budget.isTemplate ? <Star /> : <StarBorder />,
        restaurantName,
        responses: budget.status !== BUDGET_STATUS.OPEN ? t('N/A') : t(getResponsesLabel(formattedResponses[budget.id]?.length ?? 0, budget.suppliers.length)),
      };
    });

    return { data, nextPage: fetch.nextDoc };
  };

  const handleDuplicate = async (budget) => {
    const pathname = `/${t('orcamentos')}/${t('novo')}/${budget.id}`;
    if (user?.type === USER_ROLES.franchise) {
      history.push({
        pathname,
        state: { requestBudgetId: budget?.requestBudgetId || requestBudgetId, isDuplicate: true },
      });
      return;
    }
    history.push({
      pathname,
      state: { isDuplicate: true },
    });
  };

  const view = async (budget) => {
    if (user?.restaurant?.id) {
      history.push({
        pathname: `/${t('orcamento-resposta')}/${budget.id}`,
        state: {
          status: budget.status,
          idMainBudget: budget.idMainBudget || budget.id,
          mainBudgetRestaurantId: budget.restaurantId,
        },
      });
    } else {
      history.push(`/${t('orcamento-resposta')}/${budget.id}/${budget.restaurantId}`);
    }
  };

  const toggleTemplate = async (budget) => {
    setLoading(true);
    await setIsTemplate(budget);
    setLoading(false);
  };


  const Table = (props) => {
    const { data } = props;
    const hasRequest = data?.data?.find(item => item.status === BUDGET_STATUS.REQUESTED);
    return (
      <CustomTable
        fixedColumn="end"
        {...props}
        tableCells={table(
          {
            handleDuplicate,
            toggleTemplate,
            userPermission,
            hasRequest,
            isFranchise: user?.type === USER_ROLES.franchise,
            isParent: user?.type === USER_ROLES.master,
          },
        )}
        infiniteScroll
        maxHeight="70vh"
        dense
        isVertical
        numberedItems
      />
    );
  };

  const Form = props => (
    <CustomForm
      {...props}
      fields={form}
    />
  );

  const FormFilter = props => (
    <CustomForm
      {...props}
      fields={filterFields(restaurantsOptions, userPermission)}
      defaultValues={{ status: false }}
      buttonOkLabel="Filtrar"
      buttonCancel={false}
      filterForm
      inlineFilters
    />
  );

  const removeItem = async (budget, data) => {
    setLoading(true);
    if (user?.type === USER_ROLES.franchise) {
      await deleteChildBudget(budget);
    } else {
      await removeBudget(budget);
      if (user?.type === USER_ROLES.master) {
        setRequestBudgetId(null);
      }
    }
    const cancelledBudget = { ...budget };
    cancelledBudget.status = BUDGET_STATUS.CANCELLED;
    cancelledBudget.statusLabel = t(getStatusLabel(BUDGET_STATUS.CANCELLED));
    const changedData = [...data.data];
    changedData[data.data.indexOf(budget)] = cancelledBudget;
    setLoading(false);
    return changedData;
  };

  const getItemData = async (budget, action) => {
    history.push({
      pathname: `/${t('orcamentos')}/${t(action)}/${budget.id}`,
      state: {
        status: budget.status,
        mainBudgetRestaurantId: budget.restaurantId,
        idMainBudget: budget.idMainBudget || budget.id,
      },
    });
  };

  const handleCreateOrderBudget = async () => {
    if (user?.type === USER_ROLES.master && !!requestBudgetId) {
      Alert({
        title: t('Não é possivel criar uma nova solicitação'),
        text: t('Você já possui uma solicitação aberta.'),
        type: 'error',
      });
      return;
    }
    history.push(`/${t('orcamentos')}/${t('novo')}`);
  };

  if (loading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Screen
      fetchData={fetchData}
      title="Orçamento"
      removeMessage={`${t('Tem certeza que deseja excluir')} ${t('este orçamento')}?`}
      mainComponent={Table}
      formComponent={Form}
      filtersComponent={FormFilter}
      infiniteScroll
      startPage={null}
      infiniteScrollItemsPerPage={ITEMS_PER_PAGE}
      removeItem={removeItem}
      isAdded={false}
      view={view}
      getItemData={budget => getItemData(budget, 'editar')}
      buttons={user.type !== USER_ROLES.admin && user.type !== USER_ROLES.franchise ? [
        {
          label: `${t('Novo')} ${t('Orçamento')}`,
          action: () => handleCreateOrderBudget(),
        },
      ] : null}
      inlineFilters
    />
  );
};

export default flow(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(OrderBudget);
