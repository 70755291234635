import React from 'react';
import { CustomForm, Alert } from 'admin-base-component-library';
import { fields } from '../form';
import { saveProduct } from '../../../services/product';

const General = ({
  t,
  restaurant,
  segments,
  params,
  history,
  classes,
  product,
  setProduct,
  userPermission,
}) => {
  const budgetId = localStorage.getItem('budgetId');
  const parsedBudgetId = JSON.parse(budgetId);

  const handleSave = async (values) => {
    if (parsedBudgetId) {
      await saveProduct({ restaurantId: restaurant.id, ...values }, product);

      Alert({
        title: t('Produto Adicionado'),
        text: t('Produto salvo com sucesso.'),
        type: 'success',
      });

      history.push(`/${t('orcamentos')}/${t('editar')}/${parsedBudgetId}/${t('produtos')}`);
      return;
    }
    await saveProduct(values, product);
    setProduct({
      ...product,
      ...values,
    });
    Alert({
      title: t('Produto Atualizado'),
      text: t('Produto salvo com sucesso.'),
      type: 'success',
    });
    handleCancel();
  };

  const handleCancel = async () => {
    if (parsedBudgetId) {
      history.push(`/${t('orcamentos')}/${t('editar')}/${parsedBudgetId}/${t('produtos')}`);
      return;
    }

    history.push(`/${t('produtos')}`);
  };

  return (
    <div className={classes.root}>
      <CustomForm
        fields={fields([], segments, userPermission, true)}
        disabled={!(parsedBudgetId || params.type === t('editar'))}
        confirmEdit={handleSave}
        cancelEdit={handleCancel}
        defaultValues={{
          ...product,
          restaurant: restaurant.name,
        }}
      />
    </div>
  );
};

export default General;
