
export const defaultValues = {
  name: '',
  contact: '',
  cnpj: null,
  email: '',
  phone: '',
  address: {
    zipCode: '',
    street: '',
    number: '',
    complement: '',
    state: '',
    city: '',
    neighborhood: '',
  },
};

export const form = [
  {
    name: 'name',
    label: 'Nome do restaurante',
    grid: {
      xs: 12, md: 6,
    },
  },
  {
    name: 'contact',
    label: 'Nome do responsável',
    grid: {
      xs: 12, md: 6,
    },
  },
  {
    name: 'cnpj',
    label: 'CNPJ',
    format: 'cnpj',
    grid: {
      xs: 12, md: 4,
    },
  },
  {
    name: 'email',
    label: 'E-mail',
    format: 'email',
    readOnly: data => !!data?.email,
    grid: {
      xs: 12, md: 4,
    },
  },
  {
    name: 'phone',
    label: 'Telefone',
    format: 'phone',
    grid: {
      xs: 12, md: 4,
    },
  },
  {
    name: 'address',
    type: 'address',
  },
];
