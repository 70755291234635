import React, { useState, useEffect } from 'react';
import { CustomForm, CustomTable, Alert } from 'admin-base-component-library';
import {
  Modal,
  Fade,
  Backdrop,
  Paper,
  CircularProgress,
  Button,
} from '@material-ui/core';

import fields, { defaultValues } from '../form';
import { generalProductsTable } from '../table';
import { BUDGET_STATUS, getBudgetById, getChildBudget } from '../../../services/budget';
import { numberToCurrency, numberToFloat } from '../../../services/Service';
import { getProductById } from '../../../services/product';
import Supplier from '../../Products/Tabs/Supplier';
import { USER_ROLES } from '../../../services/user';

const General = ({
  t,
  params,
  classes,
  setGeneral,
  general,
  handleCancel,
  handleSave,
  isParent,
  isFranchise,
  restaurant,
  isChildBudget,
  handleSend,
  statusBudget,
  user,
}) => {
  const [childProducts, setChildProducts] = useState([]);
  const [formattedChildProducts, setFormattedChildProducts] = useState([]);
  const [modal, setModal] = useState(false);
  const [productEdit, setProductEdit] = useState(null);
  const [isNew, setIsNew] = useState(false);
  const [status, setStatus] = useState(statusBudget);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getInit = async () => {
      setLoading(true);
      try {
        const childBudget = await getChildBudget({
          filters: [
            { field: 'idMainBudget', type: '==', value: params.idDuplicate },
            { field: 'status', type: '==', value: BUDGET_STATUS.SENT },
          ],
        });

        if (isParent && !isChildBudget) {
          const budget = await getBudgetById({
            budgetId: params.idDuplicate,
            isParent,
          });

          if (budget.status !== status) {
            setStatus(budget.status);
          }
        }


        const formattedProducts = childBudget.reduce((acc, current) => {
          current.products.forEach((item) => {
            const findProductIndex = acc.findIndex(product => product.productId === item.productId);

            if (findProductIndex > -1) {
              acc[findProductIndex].observation.push(item?.observation);
              acc[findProductIndex] = {
                ...acc[findProductIndex],
                quantity: numberToCurrency(
                  numberToFloat(acc[findProductIndex].quantity) + numberToFloat(item.quantity),
                ),
              };
              return;
            }
            acc.push({
              ...item,
              observation: [item.observation],
            });
          });
          return [
            ...acc,
          ];
        }, []);

        setFormattedChildProducts(formattedProducts?.map(item => ({
          disabledSuppliers: item.disabledSuppliers,
          name: item.productName,
          productId: item.productId,
          productName: item.productName,
          segments: item.segments,
          unit: item.unit,
        })));

        setChildProducts(formattedProducts?.map(item => ({
          ...item,
          observation: item?.observation?.filter(current => current !== '').join(', '),
        })));

        setLoading(false);
      } catch {
        setLoading(false);
      }
    };
    setIsNew(params?.type === 'novo');
    if (isParent && params.type !== 'novo') {
      getInit();
    }
  }, []);

  const openModal = async (open, product) => {
    setProductEdit(null);
    setModal(open);

    if (open && product) {
      const fetchProduct = await getProductById(product.productId);
      if (fetchProduct.error || !fetchProduct.segments) {
        setModal(false);
        Alert({
          title: t('Erro'),
          text: t('Produto não encontrado ou sem segmentos vinculados.'),
          type: 'error',
        });
        return;
      }

      setProductEdit({
        ...fetchProduct,
        observation: fetchProduct?.observation ? fetchProduct.observation : '',
        segments: fetchProduct.segments.map(segment => ({
          value: segment,
        })),
      });
    }
  };

  const isMasterAndRequested = status === BUDGET_STATUS.REQUESTED
    && user.type === USER_ROLES.master;
  const isEditQuantity = status === BUDGET_STATUS.OPEN
    || status === BUDGET_STATUS.FINALIZED || (isMasterAndRequested && isChildBudget);

  const Loading = () => (
    <div className={classes.progressWrapper}>
      <CircularProgress />
    </div>
  );

  const ProductsTable = () => (
    <CustomTable
      tableCells={generalProductsTable({
        editProduct: row => openModal(true, row),
        isEditQuantity,
        isMasterAndRequested,
      })}
      data={childProducts}
      pagination={false}
      edit={row => openModal(true, row)}
      isVertical
      numberedItems
    />
  );

  const buttonOkLabel = () => {
    if (isParent && !isChildBudget && isNew) {
      return t('Solicitar Produtos');
    }

    if (isParent && !isChildBudget && !isNew) {
      return t('Salvar');
    }

    return t('Adicionar Produtos');
  };

  const isDisabled = !(params.type === t('novo') || params.type === t('editar')) || isEditQuantity;

  return (
    <div className={classes.root}>
      {loading ? <Loading /> : (
        <CustomForm
          fields={fields(t,
            isParent,
            isFranchise,
            ProductsTable,
            isNew,
            isChildBudget,
            isMasterAndRequested,
            isDisabled,
          )}
          disabled={isDisabled}
          cancelEdit={handleCancel}
          defaultValues={{
            ...defaultValues,
            ...general,
          }}
          buttonOkLabel={buttonOkLabel()}
          confirmEdit={handleSave}
          handleChanges={value => setGeneral(value)}
          customButtons={!isEditQuantity && isParent && !isNew && childProducts.length > 0 && [
            {
              label: 'Enviar',
              action: () => handleSend({ childProducts: formattedChildProducts }),
            },
          ]}
        />
      )}

      <Modal
        open={modal}
        className={classes.modalForm}
        onClose={() => openModal(false)}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <Paper className={classes.modalContainer}>

            {!productEdit && (
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
            )}

            { productEdit && (
              <>
                <Supplier
                  t={t}
                  classes={classes}
                  product={productEdit}
                  setProduct={setProductEdit}
                  params={{
                    ...params,
                    type: t('editar'),
                  }}
                  restaurant={restaurant}
                />
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={() => openModal(false)}
                >
                  {t('Fechar')}
                </Button>

              </>
            )}
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
};

export default General;
