export const table = () => ([
  {
    align: 'left',
    name: 'Nome do Restaurante',
    key: 'name',
    sortable: true,
  },
  {
    align: 'left',
    name: 'Nome do responsável',
    key: 'contact',
    sortable: true,
  },
  {
    align: 'left',
    name: 'Telefone',
    key: 'phone',
    sortable: true,
  },
  {
    align: 'center',
    name: 'Ações',
    key: 'actions',
    type: 'action',
    buttons: ['view'],
  },
]);
