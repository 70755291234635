import i18n from 'i18next';
import moment from 'moment';
import * as yup from 'yup';
import { defaultMessage, fieldRequired } from '../../utils/validation';

defaultMessage();

export const saveValidation = () => {
  const t = value => i18n.t(value);
  return yup.object().shape({
    name: yup.string().required(fieldRequired('Nome')),
    endsAt: yup.date().nullable().typeError(fieldRequired('Data limite')).min(moment(), t('Data limite inválida'))
      .max(moment().add(2, 'days'), t('Data limite inválida'))
      .required(fieldRequired('Data limite')),
  });
};

export const saveQuantityValidation = () => yup.object().shape({
  name: yup.string().required(fieldRequired('Nome')),
  endsAt: yup.date().nullable().typeError(fieldRequired('Data limite'))
    .required(fieldRequired('Data limite')),
});

export const sendBudget = () => {
  const t = value => i18n.t(value);
  return yup.object().shape({
    name: yup.string().required(fieldRequired('Nome')),
    endsAt: yup.date().min(moment(), t('Data limite inválida')).max(moment().add(2, 'days'), t('Data limite inválida')).required(fieldRequired('Data limite')),
    products: yup.array().required(t('Adicione produtos ao orçamento.')),
  });
};

export default () => {
  const t = value => i18n.t(value);
  return yup.object().shape({
    name: yup.string().required(fieldRequired('Nome')),
    endsAt: yup.date().min(moment(), t('Data limite inválida')).max(moment().add(2, 'days'), t('Data limite inválida')).required(fieldRequired('Data limite')),
    products: yup.array().required(t('Adicione produtos ao orçamento.')).of(
      yup.object().shape({
        quantity: yup.string().required('Preencha as quantidades de todos os produtos do orçamento.'),
      }),
    ),
  });
};
