import { FirebaseService, Exceptions } from 'admin-base-component-library';
import orderData from 'lodash.orderby';
import { generateTag } from '../Service';

export const ITEMS_PER_PAGE = 50;

export const saveSegment = async (name, id) => {
  const tag = generateTag(name);

  const segment = await FirebaseService.getDataList({
    collectionName: 'segment',
    filters: [
      { field: 'tag', type: '==', value: tag },
    ],
  });

  if (segment.filter(({ id: segmentId }) => segmentId !== id).length > 0) {
    throw new Exceptions.RenderException('Já existe um segmento com este nome.');
  }

  if (id) {
    return FirebaseService.update('segment', id, { name, tag });
  }

  return FirebaseService.create('segment', {
    name,
    tag,
    suppliers: {},
  });
};

export const getSegments = async () => {
  const segments = await FirebaseService.getDataList({
    collectionName: 'segment',
  });

  return segments.map(segment => ({
    ...segment,
    supplierCount: Object.keys(segment.suppliers || {}).length,
  }));
};

export const getPaginatedSegments = async ({ page, ordering }) => {
  let orderBy = ordering?.orderBy;
  if (orderBy === 'supplierCount') {
    orderBy = null;
  }
  const segments = await FirebaseService.getDataList({
    collectionName: 'segment',
    order: orderBy || null,
    orderType: ordering?.orderType || 'asc',
    limit: ITEMS_PER_PAGE,
    paginate: true,
    startAfterDoc: page,
  });

  let data = segments?.data?.map(segment => ({
    ...segment,
    supplierCount: Object.keys(segment.suppliers || {}).length,
  }));

  if (ordering?.orderBy === 'supplierCount') {
    data = orderData(data, [ordering.orderBy], [ordering.orderType]);
  }

  return { nextPage: segments.nextDoc, data };
};

export const removeSegment = async id => FirebaseService.remove('segment', id);
