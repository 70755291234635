import React, { useState, useEffect } from 'react';
import { CustomForm } from 'admin-base-component-library';
import {
  Modal,
  Fade,
  Backdrop,
  Button,
  Paper,
  TextField,
  IconButton,
} from '@material-ui/core';
import {
  Close as IconClose,
} from '@material-ui/icons';

import copy from 'copy-to-clipboard';
import { formSelectSupplier } from '../form';
import { numberToCurrency, numberToFloat } from '../../../services/Service';
import { getRestaurantByID } from '../../../services/restaurant';
import { generatePDF } from '../../../utils/pdf';
import { formatPhoneToInternational } from '../../../utils/formatters';


const OrderBudgetReportCreateOrderModal = ({
  t,
  modal,
  classes,
  closeModal,
  budget,
  restaurant,
  childBudgets,
}) => {
  const [order, setOrder] = useState({ order: '', supplier: '' });
  useEffect(() => {
    setOrder({ order: '', supplier: '' });
  }, [modal, budget]);

  const getSuppliers = () => {
    const filteredBudgets = budget?.products.filter(item => item.selected?.supplier.name !== 'Nenhum') || [];

    const suppliers = filteredBudgets.reduce((allSuppliers, product) => {
      if (!product.selected || allSuppliers[product.selected.supplier.id]) {
        return allSuppliers;
      }
      return {
        ...allSuppliers,
        [product.selected.supplier.id]: {
          value: product.selected.supplier.id,
          label: product.selected.supplier.name,
          email: product.selected.supplier.email,
          phone: product.selected.supplier.phone,
        },
      };
    }, {});

    return Object.values(suppliers);
  };

  const allSuppliers = getSuppliers();

  const allSuppliersOrdesValue = allSuppliers.reduce((orders, supplier, index) => {
    let total = 0;
    let paymentMethod = '';
    const separator = '_______________________________________';
    const indexCount = index + 1 ? `${index + 1} - ` : '';
    const contact = restaurant.contact ? `(${restaurant.contact})` : '';

    const products = budget.products.reduce((productText, product) => {
      if (product?.selected?.supplier?.id !== supplier.value) {
        return productText;
      }
      total += product.selected.priceTotalValue;
      paymentMethod = product?.selected?.response?.paymentMethod;
      const observation = !!product.observation ? ` (${product.observation})` : '';
      const brand = !!product?.selected?.brand ? ` (Marca/Gramatura: ${product.selected.brand})` : '';
      const unit = !!product.unit ? `(${product.unit}) ` : '(un) ';
      return `${productText}
${product.quantity} ${unit}- ${product.productName}${brand}${observation} R$ ${product.selected.priceTotal} (R$ ${product.selected.unitPrice} / ${!!product.unit ? product.unit : 'un'})\n `;
    }, '');

    const orderValue = `${indexCount}Pedido de compra - ${supplier.label}
${products}
Total: R$ ${numberToCurrency(total)}
 
${budget.observation ? `Obs Geral: ${budget.observation}` : ''}
 
${restaurant.name} ${contact}
${restaurant.phone}
 
Pagamento: ${paymentMethod}
${separator}`;

    return [...orders, orderValue];
  }, []).join('\n\n\n');

  const handleState = async (values) => {
    if (!values.supplier.value) {
      setOrder({
        ...values,
      });
      return;
    }

    const separator = '_______________________________________';
    let total = 0;
    let paymentMethod = '';
    const contact = restaurant.contact ? `(${restaurant.contact})` : '';

    if (childBudgets.length > 0) {
      const franchiseesRestaurant = [];
      await Promise.all(childBudgets.map(async (item) => {
        franchiseesRestaurant.push(await getRestaurantByID(item.restaurantId));
      }));

      const groupBudgets = childBudgets.reduce((acc, current, index) => {
        total = 0;
        const obs = current.observation ? `Obs Geral: ${current.observation}
        ` : '';
        const deliveryTime = current.deliveryTime ? `Horário de entrega: ${current.deliveryTime}
        ` : '';
        const findFranchiseesRestaurant = franchiseesRestaurant.find(
          item => item.id === current.restaurantId,
        );
        const { contact: franchisessContact, address } = findFranchiseesRestaurant;

        const franchiseContact = franchisessContact ? `(${franchisessContact})` : '';
        const franchiseAddress = address?.zipCode ? `${address.street}, ${address.number} - ${address.neighborhood} ${address.city}/${address.state} - ${address.zipCode}
        ` : '';
        const products = current.products.reduce((accProduct, currentProduct) => {
          const findProduct = budget.products.find(
            item => item.productId === currentProduct.productId,
          );
          if (findProduct?.selected?.supplier?.id !== values.supplier.value) {
            return accProduct;
          }

          const unit = !!findProduct.unit ? `(${findProduct.unit}) ` : '(un) ';
          paymentMethod = findProduct?.selected?.response?.paymentMethod;
          const brand = !!findProduct?.selected?.brand ? ` (Marca/Gramatura: ${findProduct.selected.brand})` : '';
          const observation = !!findProduct.observation ? ` (${findProduct.observation})` : '';
          const productTotalValue = numberToFloat(currentProduct.quantity) * numberToFloat(
            findProduct.selected.unitPrice,
          );
          const productTotal = numberToCurrency(productTotalValue);
          total += productTotalValue;
          return `${accProduct}
          ${currentProduct.quantity} ${unit}- ${currentProduct.productName}${brand}${observation} R$ ${productTotal} (R$ ${findProduct.selected.unitPrice} / ${!!findProduct.unit ? findProduct.unit : 'un'})\n `;
        }, '');

        if (products === '') {
          return acc;
        }

        const budgetsText = `${acc}
        (${index + 1}) Pedido de compra - ${values.supplier.label}\n

        ${findFranchiseesRestaurant.name} ${franchiseContact}
        ${franchiseAddress}${findFranchiseesRestaurant.phone}

        ${products}

        Total: R$ ${numberToCurrency(total)}\n

        ${obs}Pagamento: ${paymentMethod}
        ${deliveryTime}
        ${separator}
      `;

        return budgetsText;
      }, '');

      setOrder({
        ...values,
        order: await groupBudgets,
      });

      return;
    }

    const products = budget.products.reduce((productText, product) => {
      if (product?.selected?.supplier?.id !== values.supplier.value) {
        return productText;
      }
      total += product.selected.priceTotalValue;
      paymentMethod = product?.selected?.response?.paymentMethod;
      const brand = !!product?.selected?.brand ? ` (Marca/Gramatura: ${product.selected.brand})` : '';
      const observation = !!product.observation ? ` (${product.observation})` : '';
      const unit = !!product.unit ? `(${product.unit}) ` : '(un) ';
      return `${productText}
${product.quantity} ${unit}- ${product.productName}${brand}${observation} R$ ${product.selected.priceTotal} (R$ ${product.selected.unitPrice} / ${!!product.unit ? product.unit : 'un'})\n`;
    }, '');

    const orderValue = `Pedido de compra - ${values.supplier.label}
${products}
Total: R$ ${numberToCurrency(total)}
 
${budget.observation ? `Obs Geral: ${budget.observation}` : ''}
 
${restaurant.name} ${contact}
${restaurant.phone}
 
Pagamento: ${paymentMethod}
${separator}`;

    setOrder({
      ...values,
      order: orderValue,
    });
  };

  const handleSendEmail = (text, email) => {
    window.location.href = encodeURI(`mailto:${email}?body=${text}&subject=Pedido de Orçamento`);
  };

  const handlePDFOpen = () => generatePDF((order.order || allSuppliersOrdesValue).split('\n '));

  const handleSendMessage = (orderText, phone) => window.open(`https://api.whatsapp.com/send?phone=${formatPhoneToInternational(phone)}&text=${orderText.replace(/\n/g, '%0a')}`);

  return (
    <Modal
      open={modal}
      className={classes.modalForm}
      onClose={closeModal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modal}>
        <Paper className={classes.modalReport}>
          <div className={classes.modalCloseContaier}>
            <IconButton onClick={closeModal}>
              <IconClose />
            </IconButton>
          </div>

          <CustomForm
            fields={formSelectSupplier(allSuppliers, handleState)}
            buttonOk={false}
            buttonCancel={false}
            defaultValues={order}
          />

          {(order?.supplier?.value || allSuppliersOrdesValue) && (
            <>
              <TextField
                id="order"
                label=""
                multiline
                rows={10}
                fullWidth
                value={order.order || allSuppliersOrdesValue}
                variant="outlined"
              />
              <div className={classes.buttonContainer}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={() => copy(order.order || allSuppliersOrdesValue)}
                >
                  {t('Copiar')}
                </Button>

                {(order.supplier.email || restaurant.email) && (
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={() => handleSendEmail(
                      order.order || allSuppliersOrdesValue,
                      order.supplier.email || restaurant.email,
                    )}
                  >
                    {t('Enviar E-mail')}
                  </Button>
                )}

                {((order.supplier && order.supplier.phone)
                  || (!order.supplier && restaurant.phone)) && (
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={() => handleSendMessage(
                      order.order || allSuppliersOrdesValue,
                      (order.supplier ? order.supplier.phone : restaurant.phone),
                    )}
                  >
                    {t('Enviar WhatsApp')}
                  </Button>
                )}

                {(order.order || allSuppliersOrdesValue) && (
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={handlePDFOpen}
                  >
                    {t('Gerar PDF')}
                  </Button>
                )}
              </div>
            </>
          )}
        </Paper>
      </Fade>
    </Modal>
  );
};

export default OrderBudgetReportCreateOrderModal;
