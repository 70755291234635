import { hasNoPathPermission } from 'admin-base-component-library';
import { USER_ROLES } from '../services/user';


const disabledAll = {
  users: true,
  segments: true,
  suppliers: true,
  restaurants: true,
  products: true,
  budget: true,
  budgetSupplier: true,
  requestSupplier: true,
  franchisee: true,
};

const noPermission = {
  [USER_ROLES.restaurant]: {
    ...disabledAll,
    products: false,
    suppliers: false,
    budget: false,
    requestSupplier: false,
  },
  [USER_ROLES.franchise]: {
    ...disabledAll,
    budget: false,
  },
  [USER_ROLES.supplier]: {
    ...disabledAll,
    budgetSupplier: false,
  },
  [USER_ROLES.admin]: {
    budgetSupplier: true,
    requestSupplier: true,
    franchisee: true,
  },
  [USER_ROLES.master]: {
    ...disabledAll,
    products: false,
    suppliers: false,
    budget: false,
    requestSupplier: false,
    franchisee: false,
  },
};

const hasNoPermissionForPath = (userType, path) => (
  hasNoPathPermission(noPermission, userType, path)
);

export {
  hasNoPermissionForPath,
};
