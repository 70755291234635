import React, { useState, useEffect } from 'react';
import { CustomForm, CustomTable } from 'admin-base-component-library';
import orderData from 'lodash.orderby';

import ErrorIcon from '@material-ui/icons/Error';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { formFranchiseesRestaurant } from '../form';
import { tableResponseFranchisees } from '../table';
import {
  BUDGET_STATUS,
  getChildBudget,
  getStatusLabel,
} from '../../../services/budget';
import { getFranchisees } from '../../../services/restaurant';
import { numberToFloat, TimestampToDateTime } from '../../../services/Service';
import { formatCurrency } from '../../../utils/formatters';

const FranchiseesRestaurant = ({
  t,
  params,
  classes,
  history,
  isParent,
  setIsChildBudget,
  setLoading,
  user,
  mainBudgetRestaurantId,
  suppliersData,
  budget: mainBudget,
  setChildHasDeficit,
  setChildDeficitSuppliers,
}) => {
  const [franchiseesRestaurant, setFranchiseesRestaurant] = useState([]);
  const [mainRestaurantId, setMainRestaurantId] = useState(null);
  const [budgetProducts, setBudgetProducts] = useState(null);
  const [budgetFormatted, setBudgetFormatted] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    const getInit = async () => {
      try {
        setTableLoading(true);
        const childBudget = await getChildBudget({
          filters: [
            { field: 'idMainBudget', type: '==', value: params.idDuplicate || params.id },
          ],
        });

        const franchises = await getFranchisees(user.restaurant.id);
        const formattedData = franchises.map((item) => {
          const findBudget = childBudget.find(current => current.restaurantId === item.id);
          if (findBudget) {
            return {
              ...item,
              ...findBudget,
              name: item.name,
              statusLabel: getStatusLabel(findBudget.status),
              contactName: item.contact,
              updatedAt: findBudget?.sentAt
                ? TimestampToDateTime(findBudget?.sentAt)
                : findBudget?.updatedAt && TimestampToDateTime(findBudget?.updatedAt),
            };
          }
          return {
            ...item,
            statusLabel: getStatusLabel(BUDGET_STATUS.REQUESTED),
            contactName: item.contact,
            updatedAt: t('Sem resposta'),
          };
        });
        setBudgetFormatted(formattedData);

        if (!!mainBudget && !!suppliersData) {
          setMainRestaurantId(mainBudget.restaurantId);


          const restaurantsHaveBudgets = formattedData.filter(data => data.products);

          if (restaurantsHaveBudgets.length > 0 && suppliersData.length > 0) {
            const suppliersFormatted = suppliersData.reduce((suppliers, supplier) => ({
              ...suppliers,
              [supplier.id]: supplier,
            }), {});

            const responseProduct = {};
            childBudget.forEach((child) => {
              const res = mainBudget.responses.reduce((allProducts, response) => ({
                ...allProducts,
                ...response.products.reduce((products, product) => ({
                  ...products,
                  [product.id]: [
                    ...(products[product.id] || []),
                    {
                      idSupplier: response.idSupplier,
                      supplier: suppliersFormatted[response.idSupplier],
                      restaurantId: child.restaurantId,
                      priceTotalValue: numberToFloat(product.unitPrice)
                    * numberToFloat(child.products
                      .find(item => product.id === item.productId)?.quantity),
                      response,
                      ...product,
                      priceTotal: (numberToFloat(product.unitPrice)
                    * numberToFloat(child.products
                      .find(item => product.id === item.productId)?.quantity)).toFixed(2),
                    },
                  ],
                }), allProducts),
              }), {});

              responseProduct[child.restaurantId] = {
                ...responseProduct[child.restaurantId],
                ...res,
              };
            });

            const products = childBudget.map((p) => {
              const prod = [];
              const suppliers = {};

              p.products.forEach((product) => {
                if (responseProduct[p.restaurantId][product.productId]) {
                  responseProduct[p.restaurantId][product.productId].forEach((s) => {
                    suppliers[s.idSupplier] = suppliers[s.idSupplier]
                      ? Number((Number(suppliers[s.idSupplier])
                      + (numberToFloat(s.unitPrice) * numberToFloat(product.quantity))).toFixed(2))
                      : (numberToFloat(s.unitPrice) * numberToFloat(product.quantity));

                    if (!prod.find(item => item.name === product.name)) {
                      const selectedSupplier = mainBudget.products
                        .find(i => i.productId === product.productId).selected.idSupplier;

                      prod.push({
                        ...product,
                        quantity: product.quantity,
                        selected: responseProduct[p.restaurantId][product.productId]
                          .find(i => i.idSupplier === selectedSupplier),
                      });
                    }
                  });
                }
              });

              return { products: prod, suppliers, restaurantId: p.restaurantId };
            });

            setBudgetProducts(products);
            return;
          }
        }

        setFranchiseesRestaurant(formattedData);
      } catch {
        setTableLoading(false);
      }
    };

    getInit();
  }, [suppliersData, mainBudget]);

  useEffect(() => {
    try {
      if (!!budgetProducts && suppliersData.length > 0 && !!budgetFormatted) {
        setTableLoading(true);
        const notEnoughBought = {};
        const suppliersDeficit = [];

        budgetProducts.forEach((b) => {
          const totalValues = {};
          b.products.forEach((product) => {
            const selectedSupplier = product?.selected?.supplier;
            if (selectedSupplier) {
              totalValues[selectedSupplier?.id] = (!totalValues[selectedSupplier.id]
                ? {
                  product: [product.name],
                  totalValue: product
                    .selected
                    .priceTotalValue,
                }
                : {
                  ...totalValues[product.selected.supplier.id],
                  totalValue: totalValues[product.selected.supplier.id].totalValue
                  + product
                    .selected
                    .priceTotalValue,
                  product: [...totalValues[product.selected.supplier.id].product, product.name],
                });
            }
          });

          suppliersData.forEach((s) => {
            if (totalValues[s.id]?.totalValue < numberToFloat(s.minimumValue)) {
              notEnoughBought[b.restaurantId] = [
                ...(notEnoughBought[b.restaurantId] || []),
                {
                  name: s.name,
                  product: totalValues[s.id].product,
                  amount: numberToFloat(s.minimumValue) - totalValues[s.id].totalValue,
                  restaurantId: b.restaurantId,
                },
              ];

              suppliersDeficit.push({
                name: s.name,
                product: totalValues[s.id].product,
              });
            }
          });
        });

        setChildDeficitSuppliers(suppliersDeficit);

        setChildHasDeficit(Object.keys(notEnoughBought).length > 0);

        const restaurantsData = budgetFormatted.map((data) => {
          if (notEnoughBought[data.restaurantId]) {
            return {
              ...data,
              name: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip
                    title={notEnoughBought[data.restaurantId].map(item => <p key={item.name}>{t(`Faltam ${formatCurrency(item.amount)} para fechar o pedido mínimo da ${item.name}.`)}</p>)}
                    arrow
                  >
                    <ErrorIcon style={{ marginRight: '5px' }} color="error" />
                  </Tooltip>
                  {data.name}
                </div>
              ),
            };
          }

          return data;
        });

        setFranchiseesRestaurant(restaurantsData);
      }
    } catch {
      setTableLoading(false);
    }
  }, [budgetProducts]);

  useEffect(() => {
    if ((franchiseesRestaurant.length > 0 && params.type === t('editar'))
      || (!!budgetProducts && params.type !== t('editar'))) {
      setTableLoading(false);
    }
  }, [franchiseesRestaurant]);

  const view = async (budget) => {
    history.push({
      pathname: `/${t('orcamento-resposta')}/${budget.id}`,
      state: {
        isParent,
        restaurantId: budget.restaurantId,
        status: budget.status,
        idMainBudget: budget.idMainBudget,
        franchiseesRestaurantView: true,
        type: params?.type,
      },
    });
    history.go(0);
  };

  const edit = async (budget, action) => {
    setLoading(true);

    if (setIsChildBudget) {
      setIsChildBudget(!!budget.idMainBudget);
    }

    history.push({
      pathname: `/${t('orcamentos')}/${t(action)}/${budget.id}`,
      state: {
        status: budget.status,
        idMainBudget: budget.idMainBudget,
        restaurantId: budget.restaurantId,
        isChildBudget: true,
        mainBudgetRestaurantId: mainBudgetRestaurantId || mainRestaurantId,
        type: params?.type,
      },
    });
  };

  const Loading = () => (
    <div className={classes.progressWrapper}>
      <CircularProgress />
    </div>
  );

  const Table = () => (
    <CustomTable
      data={franchiseesRestaurant}
      pagination={false}
      tableCells={tableResponseFranchisees}
      maxHeight="90vh"
      isVertical
      sortData={(order, orderBy, values) => (
        order ? orderData(values, [orderBy], [order]) : values
      )}
      view={data => view(data)}
      edit={data => edit(data, 'editar')}
      numberedItems
    />
  );
  return (
    <div className={classes.root}>
      <CustomForm
        fields={formFranchiseesRestaurant(tableLoading ? Loading : Table)}
        buttonCancel={false}
        buttonOk={false}
      />
    </div>
  );
};

export default FranchiseesRestaurant;
