import React, { memo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Dinero from 'dinero.js';
import Bugsnag from '@bugsnag/js';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { FirebaseDb, BugsnagInstance } from 'admin-base-component-library';
import { LoginProvider } from './context/UserContext';
import config from './config';

// Routes
import Routes from './Routes';

// Browser history
const browserHistory = createBrowserHistory();

Dinero.defaultCurrency = 'BRL';
Dinero.defaultPrecision = 2;
Dinero.globalLocale = 'pt-BR';

FirebaseDb.initDB(config.env.firebaseEnv);

BugsnagInstance.setBugSnag(Bugsnag);

const App = () => (
  <Router history={browserHistory}>
    <LoginProvider history={browserHistory}>
      <Routes />
    </LoginProvider>
  </Router>
);

export default memo(App);
