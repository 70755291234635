import React from 'react';
import { CustomForm, CustomInfo } from 'admin-base-component-library';
import {
  Modal,
  Fade,
  Backdrop,
  Paper,
  IconButton,
} from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';
import { formBrand } from '../form';

const AddBrandModal = ({
  modal,
  classes,
  closeModal,
  product,
  handleSaveProduct,
}) => (
  <Modal
    open={modal}
    className={classes.modalForm}
    onClose={closeModal}
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={modal}>
      <Paper className={classes.modalReport}>
        <div className={classes.modalCloseContaier}>
          <IconButton onClick={closeModal}>
            <IconClose />
          </IconButton>
        </div>
        <CustomInfo
          data={{
            fields: [
              {
                label: 'Produto',
                value: product?.productName ?? product?.name,
                grid: { xs: 12, md: 6 },
              },
              {
                label: 'Quantidade',
                value: product?.unitAndQuantity,
                grid: { xs: 12, md: 6 },
              },
            ],
          }}
        />
        <CustomForm
          fields={formBrand}
          defaultValues={{ brand: product?.brand }}
          buttonCancel={false}
          confirmEdit={values => handleSaveProduct(values, product)}
        />
      </Paper>
    </Fade>
  </Modal>
);

export default AddBrandModal;
