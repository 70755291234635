export default theme => ({
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center',
  },
});
