import { FirebaseService } from 'admin-base-component-library';
import { arrayToCollectionAssociate, updateArrayAssociate } from '../Service';
import { removeUser, saveUser } from '../user';

export const saveSupplier = async ({
  name,
  contactName,
  phone,
  email,
  minimumValue,
  quoteTime,
  orderTime,
  neighborhoods = [],
  segments = [],
}, supplier) => {
  const isAllNeighborhood = (neighborhoods || []).some(({ value }) => value === true);
  const cities = isAllNeighborhood ? [{ city: 'Joinville', state: 'SC' }] : [];

  const allSegments = arrayToCollectionAssociate(segments);

  const data = {
    name,
    contactName,
    email: supplier?.email || email,
    minimumValue,
    quoteTime,
    orderTime,
    cities,
    restaurants: {},
    phone: [{
      main: true,
      title: 'Principal',
      phone,
    }],
    segments: allSegments,
    neighborhoods: isAllNeighborhood ? null : arrayToCollectionAssociate(neighborhoods || []),
    user: supplier.user || null,
  };

  const hasEmail = email && email !== '';

  if (!supplier?.user && hasEmail) {
    data.user = await saveUser({
      name,
      email,
      active: true,
      type: 'Fornecedor',
    });
  }

  if (
    supplier?.user
    && hasEmail
    && (supplier?.name !== name)
  ) {
    await saveUser({
      name,
    }, supplier.user);
  }

  if (supplier?.id) {
    updateArrayAssociate({
      collection: 'segment',
      field: 'suppliers',
      id: supplier.id,
      newValues: allSegments,
      oldValues: supplier.segments,
    });
    return FirebaseService.update('supplier', supplier.id, data);
  }

  const save = await FirebaseService.create('supplier', data);
  updateArrayAssociate({
    collection: 'segment',
    field: 'suppliers',
    id: save.id,
    newValues: allSegments,
  });
  return save;
};

export const getSuppliers = async (filterData) => {
  const filters = [];
  if (filterData?.segment?.value) {
    filters.push({ field: `segments.${filterData.segment.value}`, type: '==', value: true });
  }

  let suppliers = await FirebaseService.getDataList({ collectionName: 'supplier', filters });

  if (filterData?.segments) {
    suppliers = [];
    await Promise.all(filterData.segments.map(async (segment) => {
      const fetchSupplier = await FirebaseService.getDataList({
        collectionName: 'supplier',
        filters: [{ field: `segments.${segment}`, type: '==', value: true }],
      });

      fetchSupplier.forEach((supplier) => {
        if (!suppliers.some(({ id }) => supplier.id === id)) {
          suppliers.push(supplier);
        }
      });
    }));
  }

  if (filterData?.neighborhoods?.value) {
    const suppliersNeighborhoods = await FirebaseService.getDataList({
      collectionName: 'supplier',
      filters: [
        ...filters,
        { field: `neighborhoods.${filterData.neighborhoods.value}`, type: '==', value: true },
      ],
    });
    const suppliersAllNeighborhoods = await FirebaseService.getDataList({
      collectionName: 'supplier',
      filters: [
        ...filters,
        { field: 'cities', type: 'array-contains', value: { city: 'Joinville', state: 'SC' } },
      ],
    });

    suppliers = [
      ...(suppliersNeighborhoods || []),
      ...(suppliersAllNeighborhoods || []),
    ];
  }

  const restaurants = await FirebaseService.getDataList({
    collectionName: 'restaurant',
    // Utilizar este filtro para diminuir a quantidade de restaurantes
    /* filters: [
      {field: 'suppliers', type: 'array-contains-any', value: suppliers.map(({id}) => id)}
    ] */
  });

  const restaurantCount = restaurants.reduce((countRestaurants, restaurant) => {
    const count = { ...countRestaurants };
    restaurant.suppliers.forEach((supplier) => {
      count[supplier] = (count[supplier] || 0) + 1;
    });
    return count;
  }, {});

  return suppliers.map(supplier => ({
    ...supplier,
    phone: supplier.phone[0]?.phone,
    restaurantCount: restaurantCount[supplier.id] || 0,
  }));
};

export const removeSupplier = async (supplier) => {
  if (supplier.user) {
    await removeUser(supplier.user);
  }
  await FirebaseService.remove('supplier', supplier.id);
  updateArrayAssociate({
    collection: 'segment',
    field: 'suppliers',
    id: supplier.id,
    oldValues: supplier.segments,
  });
};

export const hideProducts = async (supplierId, productId, status) => {
  const supplier = await FirebaseService.getInstanceById('supplier', supplierId);
  const products = [...(supplier.hideProducts || [])].filter(product => product !== productId);

  if (status) {
    products.push(productId);
  }

  await FirebaseService.update('supplier', supplierId, {
    hideProducts: products,
  });
};

export const getSupplierById = async supplierId => FirebaseService.getInstanceById('supplier', supplierId);
