import React from 'react';
import {
  DeleteOutline as RemoveIcon,
} from '@material-ui/icons';

export default removeItem => [
  {
    align: 'left',
    name: 'Ativo',
    key: 'active',
    type: 'boolean',
    strings: ['Não', 'Sim'],
    sortable: true,
  },
  {
    align: 'left',
    name: 'Nome do restaurante',
    key: 'name',
    headerStyle: { minWidth: 250 },
    sortable: true,
  },
  {
    align: 'left',
    name: 'Nome do responsável',
    key: 'contact',
    headerStyle: { minWidth: 250 },
    sortable: true,
  },
  {
    align: 'left',
    name: 'Telefone',
    key: 'phone',
    sortable: true,
  },
  {
    align: 'left',
    name: 'Bairro',
    key: 'neighborhood',
    sortable: true,
  },
  {
    align: 'center',
    name: 'Status do pagamento',
    key: 'paymentStatus',
    headerStyle: { minWidth: 200 },
    sortable: true,
  },
  {
    align: 'center',
    name: 'Ações',
    key: 'actions',
    type: 'action',
    buttons: ['view', 'edit'],
    customButtons: [
      {
        icon: <RemoveIcon />,
        action: removeItem,
      },
    ],
  },
];

export const tableSupplier = isView => ([
  {
    align: 'left',
    name: 'Nome da empresa',
    key: 'name',
    sortable: true,
  },
  {
    align: 'left',
    name: 'Nome do representante',
    key: 'contactName',
    sortable: true,
  },
  {
    align: 'left',
    name: 'Telefone',
    key: 'phone',
    sortable: true,
  },
  {
    align: 'center',
    name: 'Ações',
    key: 'actions',
    type: 'action',
    buttons: ['remove'],
    tableHidden: isView,
  },
]);
