export default [
  {
    align: 'center',
    name: '',
    key: 'profileAvatar',
    type: 'avatar',
  },
  {
    align: 'left',
    name: 'Nome',
    key: 'name',
  },
  {
    align: 'left',
    name: 'E-mail',
    key: 'email',
  },
  {
    align: 'left',
    name: 'Ativo',
    key: 'active',
    type: 'boolean',
    strings: ['Não', 'Sim'],
  },
  {
    align: 'center',
    name: 'Ações',
    key: 'actions',
    type: 'action',
    buttons: ['view', 'edit', 'remove'],
  },
];
