export default (t, history) => [
  {
    align: 'left',
    name: 'Nome',
    key: 'name',
    sortable: true,
  },
  {
    align: 'center',
    name: 'Fornecedores',
    key: 'supplierCount',
    type: 'clickable',
    onClickCell: row => history.push({
      pathname: `/${t('fornecedores')}`,
      search: `?segment=${row.id}`,
    }),
    sortable: true,
  },
  {
    align: 'center',
    name: 'Ações',
    key: 'actions',
    type: 'action',
    isRemovable: ({ supplierCount }) => supplierCount === 0,
    buttons: ['view', 'edit', 'remove'],
  },
];
