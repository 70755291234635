export const formResponseDefaultValues = {
  paymentMethod: '',
  paymentTerm: '',
  deadline: '',
  quotatioValidity: '',
  observation: '',
};

export const formFilter = [
  {
    name: 'search',
    label: 'Buscar',
    grid: { xs: 4 },
  },
];

export const formObs = [
  {
    name: 'observation',
    label: 'Observações',
    type: 'textarea',
    grid: { xs: 12 },
  },
];

export const formBrand = [
  {
    name: 'brand',
    label: 'Caso você esteja cotando uma marca/gramatura diferente da solicitada informe-a aqui',
    grid: { xs: 12 },
  },
];

export const formResponse = (info, productTable) => ([
  {
    component: info,
    type: 'component',
    grid: {
      xs: 12,
      md: 12,
    },
  },
  {
    label: 'Cotação',
    type: 'title',
    format: 'subTitle',
    grid: {
      xs: 12,
    },
  },
  {
    name: 'paymentMethod',
    label: 'Forma de pagamento',
    required: true,
    grid: { xs: 12, sm: 6, md: 3 },
  },
  {
    name: 'paymentTerm',
    label: 'Prazo de pagamento',
    required: true,
    grid: { xs: 12, sm: 6, md: 3 },
  },
  {
    name: 'deadline',
    label: 'Prazo de entrega',
    required: true,
    grid: { xs: 12, sm: 6, md: 3 },
  },
  {
    name: 'quotationValidity',
    label: 'Validade da cotação',
    required: true,
    grid: { xs: 12, sm: 6, md: 3 },
  },
  {
    name: 'observation',
    label: 'Observações Gerais',
    type: 'textarea',
    grid: { xs: 12 },
  },
  {
    label: 'Produtos',
    type: 'title',
    format: 'subTitle',
    grid: {
      xs: 12,
    },
  },
  {
    component: productTable,
    type: 'component',
    grid: {
      xs: 12,
      md: 12,
    },
  },
]);
