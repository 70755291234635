import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import orderData from 'lodash.orderby';
import {
  Screen,
  CustomTable,
  CustomForm,
} from 'admin-base-component-library';

import table from './table';
import UserContext from '../../context/UserContext';
import { getBudgets, BUDGET_STATUS, ITEMS_PER_PAGE } from '../../services/budget';

const OrderBudgetResponse = ({ t, history }) => {
  const { user } = useContext(UserContext);
  const fetchData = async ({ page }) => {
    const fetch = await getBudgets({
      page,
      filter: {
        supplierId: user.supplier.id,
        status: BUDGET_STATUS.OPEN,
      },
      restaurant: true,
    });

    const data = fetch?.data?.map(budget => ({
      ...budget,
      endsAt: moment(new Date(budget.endsAt.seconds * 1000)).format('DD/MM/YYYY HH:mm'),
      restaurantName: budget.restaurant.name,
    }));

    return { data, nextPage: fetch.nextDoc };
  };

  const Table = propsTable => (
    <CustomTable
      {...propsTable}
      tableCells={table}
      infiniteScroll
      maxHeight="70vh"
      dense
      isVertical
      numberedItems
      sortData={(order, orderBy, values) => {
        if (order) {
          return orderData(values.data, [orderBy], [order]);
        }
        return values.data;
      }}
    />
  );

  const Form = propsForm => (
    <CustomForm
      {...propsForm}
      fields={[]}
    />
  );

  const getItemData = (budget, action) => {
    history.push(`/${t('orcamentos-fornecedor')}/${t(action)}/${budget.id}`);
  };

  return (
    <Screen
      fetchData={fetchData}
      title="Orçamento"
      removeMessage={`${t('Tem certeza que deseja excluir')} ${t('este orçamento')}?`}
      mainComponent={Table}
      formComponent={Form}
      isAdded={false}
      getItemData={budget => getItemData(budget, 'editar')}
      view={budget => getItemData(budget, 'visualizar')}
      infiniteScroll
      startPage={null}
      infiniteScrollItemsPerPage={ITEMS_PER_PAGE}
    />
  );
};

export default withTranslation()(OrderBudgetResponse);
