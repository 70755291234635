import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { Screen, CustomForm, CustomTable } from 'admin-base-component-library';
import UserCells from './table';
import { UserFields, DefaultValues } from './form';
import { getUsersByType, saveUser, removeUser } from '../../services/user';
import UserContext from '../../context/UserContext';

const Users = ({ t }) => {
  const { user: loggedUser } = useContext(UserContext);

  const fetchData = async () => (
    (await getUsersByType('Administrador')).filter(({ id }) => loggedUser?.id !== id)
  );

  const UsersTable = props => (
    <CustomTable
      tableCells={UserCells}
      maxHeight="70vh"
      dense
      isVertical
      numberedItems
      {...props}
    />
  );

  const UserForm = props => (
    <CustomForm
      {...props}
      defaultValues={DefaultValues}
      fields={UserFields}
    />
  );

  const handleSave = async (values, user) => {
    const userValues = { ...values };
    if (user.id) {
      delete userValues.id;
    }
    await saveUser({
      ...userValues,
      type: 'Administrador',
    }, user.id);
    return fetchData();
  };

  const removeItem = async (user) => {
    await removeUser(user.id);
    return fetchData();
  };

  return (
    <Screen
      fetchData={fetchData}
      title="Usuário"
      removeMessage={`${t('Tem certeza que deseja excluir')} ${t('este usuário')}?`}
      mainComponent={UsersTable}
      formComponent={UserForm}
      handleSave={handleSave}
      removeItem={removeItem}
      pagination={false}
    />
  );
};

export default withTranslation()(Users);
