import React, { useState, useEffect, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { parse } from 'query-string';
import { CircularProgress, withStyles } from '@material-ui/core';
import {
  Screen,
  CustomForm,
  CustomTable,
  Exceptions,
  Alert,
} from 'admin-base-component-library';
import orderData from 'lodash.orderby';
import flow from 'lodash.flow';
import table from './table';
import form, { filterSupplierSegment } from './form';
import { formNeighborhood } from '../Address/form';
import { getSegments } from '../../services/segment';
import { saveNeighborhood, getNeighborhoods } from '../../services/address';
import { saveSupplier, getSuppliers, removeSupplier } from '../../services/supplier';
import { getRestaurantByID } from '../../services/restaurant';
import styles from '../Restaurants/styles';
import { useUserPermission } from './permissions';
import UserContext from '../../context/UserContext';
import { USER_ROLES } from '../../services/user';
import { arrayToCollectionAssociateToOptions } from '../../services/Service';

let neighborhoods = [];
const Suppliers = ({
  t, history, history: { location }, classes,
}) => {
  const userPermission = useUserPermission();
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({});
  const [restaurant, setRestaurant] = useState({});
  const [segments, setSegments] = useState([]);

  useEffect(() => {
    const getInit = async () => {
      const fetchSegments = await getSegments();
      const fetchNeighborhoods = await getNeighborhoods();
      if (user.type === USER_ROLES.restaurant || user.type === USER_ROLES.master) {
        const fetchRestaurant = await getRestaurantByID(user.restaurant.id);
        setRestaurant(fetchRestaurant);
      }

      const params = parse(location.search);
      setSegments(fetchSegments.map((item) => {
        const option = {
          value: item.id,
          label: item.name,
        };

        if (params?.segment === item.id) {
          setFilter({ segment: option });
        }

        return option;
      }));

      neighborhoods = [
        { value: true, label: 'Todos' },
        ...fetchNeighborhoods.map(item => ({
          value: item.id,
          label: item.name,
        })),
      ];
      setLoading(false);
    };
    getInit();
  }, []);

  const fetchData = async () => {
    const suppliers = await getSuppliers(filter);
    return suppliers.reduce((allSuppliers, supplier) => {
      if ((user?.type !== USER_ROLES.restaurant && user?.type !== USER_ROLES.master)
        || restaurant?.suppliers.indexOf(supplier.id) > -1) {
        return [
          ...allSuppliers,
          {
            ...supplier,
            segments: arrayToCollectionAssociateToOptions(supplier.segments, segments),
            neighborhoods: supplier.cities?.length > 0
              ? [{ value: true, label: 'Todos' }]
              : arrayToCollectionAssociateToOptions(supplier.neighborhoods, neighborhoods),
          },
        ];
      }

      return allSuppliers;
    }, []);
  };

  const Table = props => (
    <CustomTable
      {...props}
      maxHeight="70vh"
      dense
      isVertical
      numberedItems
      tableCells={table(t, history, userPermission)}
      sortData={(order, orderBy, values) => {
        if (order) {
          return orderData(values, [orderBy], [order]);
        }
        return values;
      }}
    />
  );

  const FormNeighborhood = (closeModal, setValue, defaultValue) => (
    <CustomForm
      fields={formNeighborhood}
      buttonOkLabel="Salvar"
      confirmEdit={async (values) => {
        try {
          const save = await saveNeighborhood({ name: values.neighborhood });
          setValue({ value: save.id, label: values.neighborhood });
          neighborhoods.push({ value: save.id, label: values.neighborhood });
          closeModal();
        } catch (e) {
          closeModal();
          if (e instanceof Exceptions.RenderException) {
            Alert({
              title: t('Erro'),
              text: e,
              type: 'error',
            });
            return;
          }

          Alert({
            title: t('Erro'),
            text: 'Ocorreu um erro inesperado.',
            type: 'error',
          });
        }
      }}
      defaultValues={{ neighborhood: defaultValue }}
      buttonCancel={false}
    />
  );

  const Form = props => (
    <CustomForm
      {...props}
      fields={form(segments, neighborhoods, FormNeighborhood)}
    />
  );

  const FilterForm = props => (
    <CustomForm
      {...props}
      defaultValues={filter}
      fields={filterSupplierSegment(segments, neighborhoods)}
      buttonOkLabel="Filtrar"
      buttonCancel={false}
      confirmEdit={values => setFilter(values)}
      filterForm
      inlineFilters
    />
  );

  const handleSave = async (values, supplier) => {
    await saveSupplier(values, supplier);
    return fetchData();
  };

  const removeItem = async (supplier) => {
    await removeSupplier(supplier);
    return fetchData();
  };

  const getItemData = supplier => supplier;

  if (loading || !user?.type) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Screen
      fetchData={fetchData}
      title="Fornecedor"
      removeMessage={`${t('Tem certeza que deseja excluir')} ${t('este fornecedor')}?`}
      mainComponent={Table}
      formComponent={Form}
      filtersComponent={FilterForm}
      handleSave={handleSave}
      removeItem={removeItem}
      pagination={false}
      getItemData={getItemData}
      isAdded={userPermission('general', 'newSupplier')}
      inlineFilters
    />
  );
};

export default flow(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(Suppliers);
