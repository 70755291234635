import React, { useState, useEffect, useContext } from 'react';
import { Alert } from 'admin-base-component-library';
import {
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import flow from 'lodash.flow';
import { withTranslation } from 'react-i18next';
import UserContext from '../../context/UserContext';

import {
  getBudgetById, addBudgetResponse, updateBudgetResponse, BUDGET_STATUS, getChildBudget,
} from '../../services/budget';
import { numberToCurrency, numberToFloat } from '../../services/Service';

import ResponseForm from './ResponseForm';

import styles from './styles';
import { getSupplierById } from '../../services/supplier';

const OrderBudgetResponseEdit = ({
  t,
  match: { params },
  classes,
  history,
}) => {
  const { user } = useContext(UserContext);
  const [budget, setBudget] = useState();
  const [quotation, setQuotation] = useState({
    paymentMethod: '',
    paymentTerm: '',
    deadline: '',
    quotationValidity: '',
    observation: '',
  });
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [budgetChilds, setBudgetChilds] = useState([]);

  useEffect(() => {
    const init = async () => {
      const fetchBudget = await getBudgetById({
        budgetId: params.id, fetchRestaurant: true, supplierId: user.supplier.id,
      });
      const fetchSupplier = await getSupplierById(user.supplier.id);
      const dataForm = fetchBudget.response;
      const includesUserSupplier = fetchBudget.suppliers.indexOf(user.supplier.id) !== -1;

      if (dataForm?.status === BUDGET_STATUS.FINALIZED || !includesUserSupplier) {
        history.push(`/${t('orcamentos-fornecedor')}`);
      }

      const childBudget = await getChildBudget({
        filters: [
          { field: 'idMainBudget', type: '==', value: params.id },
          { field: 'status', type: '==', value: BUDGET_STATUS.SENT },
        ],
      });
      setBudgetChilds(childBudget);

      const formattedProducts = childBudget.reduce((acc, current) => {
        current.products.forEach((item) => {
          const findProductIndex = acc.findIndex(product => product.productId === item.productId);

          if (findProductIndex > -1) {
            acc[findProductIndex].observation.push(item?.observation);
            acc[findProductIndex] = {
              ...acc[findProductIndex],
              quantity: numberToCurrency(
                numberToFloat(acc[findProductIndex].quantity) + numberToFloat(item.quantity),
              ),
            };
            return;
          }
          acc.push({
            ...item,
            observation: [item.observation],
          });
        });
        return [
          ...acc,
        ];
      }, []);

      if (dataForm?.products) {
        setEdit(true);
      }

      setQuotation({
        paymentMethod: dataForm?.paymentMethod || '',
        paymentTerm: dataForm?.paymentTerm || '',
        deadline: dataForm?.deadline || '',
        quotationValidity: dataForm?.quotationValidity || '',
        observation: dataForm?.observation || '',
        products: fetchBudget.products.reduce((products, fields) => {
          const supplierIsDisabled = fields?.disabledSuppliers?.indexOf(user.supplier.id) > -1;

          const hasSegment = fields?.segments.some(
            item => Object.keys(fetchSupplier.segments || {}).indexOf(item) >= 0,
          );

          if (supplierIsDisabled || !hasSegment) {
            return products;
          }

          const hasHide = (productId) => {
            if (!fetchSupplier.hideProducts) {
              return false;
            }

            return fetchSupplier.hideProducts.indexOf(productId) > -1;
          };

          const budgetProduct = dataForm?.products?.find(
            product => product.id === fields.productId,
          );
          const childBudgets = formattedProducts.find(
            product => product.productId === fields.productId,
          );

          return [
            ...products,
            {
              ...fields,
              quantity: childBudgets?.quantity || fields.quantity,
              hide: hasHide(fields.productId),
              brand: budgetProduct?.brand || '',
              observation: budgetProduct?.observation || '',
              unitPrice: budgetProduct?.unitPrice || '',
              priceTotal: budgetProduct?.priceTotal || '',
              observationRequest: childBudgets?.observation.filter(item => item !== '').join(', ') || fields.observation,
              unitAndQuantity: childBudgets
                ? `${childBudgets?.quantity} (${childBudgets?.unit ? childBudgets?.unit : 'un'})`
                : `${fields.quantity} (${fields.unit ? fields.unit : 'un'})`,
            },
          ];
        }, []),
      });

      setBudget(fetchBudget);
      setLoading(false);
    };

    init();
  }, []);

  const setProducts = (values) => {
    setQuotation({
      ...quotation,
      products: values,
    });
  };

  const handleCancel = () => history.push(`/${t('orcamentos-fornecedor')}`);

  const handleSave = async () => {
    const quotedProducts = quotation.products.filter(item => item.unitPrice !== '');
    const unquotedProducts = quotation.products.filter(item => item.unitPrice === '').map(item => item.productName);

    const formData = {
      idBudget: budget.id,
      idSupplier: user.supplier.id,
      ...quotation,
      products: quotedProducts.reduce((allProducts, {
        productId,
        observation,
        brand,
        priceTotal,
        unitPrice,
        hide,
      }) => (
        !hide ? [...allProducts, {
          id: productId,
          observation: observation || '',
          brand: brand || '',
          priceTotal,
          unitPrice,
        }] : allProducts
      ), []),
    };

    if (formData.products.length === 0) {
      Alert({
        title: t('Cotação sem produtos'),
        text: t('A cotação não contém nenhum produto.'),
        type: 'error',
      });
      return;
    }

    Alert({
      title: t('Cuidado!'),
      text: unquotedProducts.length === 0
        ? `${t('Você tem certeza que finalizou a cotação e gostaria de enviar ao restaurante?')}`
        : `${t('Os itens {{unquotedProducts}} não foram cotados. Você tem certeza que deseja enviar mesmo assim?', { unquotedProducts: unquotedProducts.toString() })}`,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: t('Prosseguir'),
      cancelButtonText: t('Cancelar'),
    }).then(async (result) => {
      if (!result.value) {
        return;
      }
      if (edit) {
        try {
          await updateBudgetResponse(formData);

          Alert({
            title: t('Cotação Enviada'),
            text: t('Cotação salva com sucesso.'),
            type: 'success',
          });

          history.push(`/${t('orcamentos-fornecedor')}`);
        } catch (e) {
          Alert({
            title: t('Erro'),
            text: t('Erro ao salvar cotação.'),
            type: 'error',
          });
        }

        return;
      }

      try {
        await addBudgetResponse(formData);

        Alert({
          title: t('Cotação Enviada'),
          text: t('Cotação salva com sucesso.'),
          type: 'success',
        });

        history.push(`/${t('orcamentos-fornecedor')}`);
      } catch (e) {
        Alert({
          title: t('Erro'),
          text: t('Erro ao salvar cotação.'),
          type: 'error',
        });
      }
    });
  };

  if (loading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return !user ? null : (
    <ResponseForm
      t={t}
      params={params}
      classes={classes}
      history={history}
      handleCancel={handleCancel}
      handleSave={handleSave}
      user={user}
      budget={budget}
      setProducts={setProducts}
      quotation={quotation}
      setQuotation={setQuotation}
      view={params.type === t('visualizar')}
      budgetChilds={budgetChilds}
    />
  );
};

export default flow(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(OrderBudgetResponseEdit);
