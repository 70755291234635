import React from 'react';
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import flow from 'lodash.flow';
import {
  CustomForm,
  CustomTable,
} from 'admin-base-component-library';
import orderData from 'lodash.orderby';

import { formSupliers } from './form';
import styles from './styles';
import { tableResponseSuppliers } from './table';

const OrderBudgetReportSupliers = ({
  classes,
  suppliers,
  responses = 0,
  params,
  restaurantName,
}) => {
  const Table = () => (
    <CustomTable
      data={suppliers}
      pagination={false}
      tableCells={tableResponseSuppliers({
        name: restaurantName,
        id: params.id,
      })}
      sortData={(order, orderBy, values) => {
        if (order) {
          return orderData(values, [orderBy], [order]);
        }
        return values;
      }}
      maxHeight="70vh"
      isVertical
      numberedItems
    />
  );

  return (
    <div className={classes.root}>
      <CustomForm
        fields={formSupliers(Table, responses, suppliers?.length ?? 0)}
        buttonCancel={false}
        buttonOk={false}
      />
    </div>
  );
};

export default flow(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(OrderBudgetReportSupliers);
