import React from 'react';

import {
  AssignmentInd as AssignmentIndIcon,
  AccountTree as AccountTreeIcon,
  Restaurant as RestaurantIcon,
  BusinessCenter as BusinessCenterIcon,
  ShoppingBasket as ProductIcon,
  Assignment as AssignmentIcon,
} from '@material-ui/icons';

import { hasNoPermissionForPath } from './permissions';

const menu = userType => ([
  {
    name: 'Franqueados',
    icon: <RestaurantIcon />,
    path: '/franqueados',
    hidden: hasNoPermissionForPath(userType, 'franchisee'),
  },
  {
    name: 'Usuários',
    icon: <AssignmentIndIcon />,
    path: '/usuarios',
    hidden: hasNoPermissionForPath(userType, 'users'),
  },
  {
    name: 'Segmentos',
    icon: <AccountTreeIcon />,
    path: '/segmentos',
    hidden: hasNoPermissionForPath(userType, 'segments'),
  },
  {
    name: 'Restaurantes',
    icon: <RestaurantIcon />,
    path: '/restaurantes',
    hidden: hasNoPermissionForPath(userType, 'restaurants'),
  },
  {
    name: 'Fornecedores',
    icon: <BusinessCenterIcon />,
    path: '/fornecedores',
    hidden: hasNoPermissionForPath(userType, 'suppliers'),
  },
  {
    name: 'Produtos',
    icon: <ProductIcon />,
    path: '/produtos',
    hidden: hasNoPermissionForPath(userType, 'products'),
  },
  {
    name: 'Orçamentos',
    icon: <AssignmentIcon />,
    path: '/orcamentos',
    hidden: hasNoPermissionForPath(userType, 'budget'),
  },
  {
    name: 'Orçamentos',
    icon: <AssignmentIcon />,
    path: '/orcamentos-fornecedor',
    hidden: hasNoPermissionForPath(userType, 'budgetSupplier'),
  },
]);

export default menu;
