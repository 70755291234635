import { useContext } from 'react';
import { getFieldActionsPermission } from 'admin-base-component-library';
import UserContext from '../context/UserContext';

export const usePermission = (permissions) => {
  const { user } = useContext(UserContext);

  return (field, action) => (
    getFieldActionsPermission(permissions, user?.type, field, action)
  );
};

export const useNotPermission = (permissions) => {
  const { user } = useContext(UserContext);

  return (field, action) => (
    !getFieldActionsPermission(permissions, user?.type, field, action)
  );
};
