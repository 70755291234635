const DefaultValues = {
  name: '',
  email: '',
  active: true,
};

const UserFields = [
  {
    name: 'name',
    label: 'Nome',
    required: true,
    grid: {
      xs: 12, md: 5,
    },
  },
  {
    name: 'email',
    label: 'E-mail',
    format: 'email',
    required: true,
    notEditable: true,
    grid: {
      xs: 12, md: 5,
    },
  },
  {
    name: 'active',
    label: 'Ativo',
    options: [
      { value: true, label: 'Sim' },
      { value: false, label: 'Não' },
    ],
    type: 'select',
    required: true,
    grid: {
      xs: 12, md: 2,
    },
  },
];

export {
  UserFields,
  DefaultValues,
};
