export default (t, history, userPermission) => [
  {
    align: 'left',
    name: 'Nome da empresa',
    key: 'name',
    sortable: true,
  },
  {
    align: 'left',
    name: 'Nome do representante',
    key: 'contactName',
    sortable: true,
  },
  {
    align: 'left',
    name: 'Pedido mínimo',
    key: 'minimumValue',
    sortable: true,
  },
  {
    align: 'left',
    name: 'Telefone',
    key: 'phone',
    sortable: true,
  },
  {
    align: 'center',
    name: 'Restaurantes',
    key: 'restaurantCount',
    sortable: true,
    tableHidden: userPermission('restaurantCount', 'tableHidden'),
    type: 'clickable',
    onClickCell: row => history.push({
      pathname: `/${t('restaurantes')}`,
      search: `?supplier=${row.id}&name=${row.name}&contactName=${row.contactName}`,
    }),
  },
  {
    align: 'center',
    name: 'Ações',
    key: 'actions',
    type: 'action',
    isRemovable: ({ restaurantCount }) => restaurantCount === 0,
    buttons: userPermission('general', 'tableActions'),
  },
];
