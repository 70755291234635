import React, { useState, useEffect } from 'react';
import { Tabs, Alert } from 'admin-base-component-library';
import flow from 'lodash.flow';
import { withTranslation } from 'react-i18next';
import { withStyles, CircularProgress } from '@material-ui/core';

import General from './Tabs/General.js';
import Supplier from './Tabs/Supplier';

import styles from './styles';
import { getProductById } from '../../services/product';
import { getSegments } from '../../services/segment';
import { getRestaurantByID } from '../../services/restaurant';
import { useUserPermission } from './permissions.js';

const ProductEdit = ({
  t,
  match: { params },
  classes,
  history,
}) => {
  const userPermission = useUserPermission();
  const budgetId = localStorage.getItem('budgetId');
  const parsedBudgetId = JSON.parse(budgetId);

  const [product, setProduct] = useState([]);
  const [restaurant, setRestaurant] = useState(null);
  const [segments, setSegments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      if (!params?.id) {
        return;
      }

      const fetchProduct = await getProductById(params.id);
      const fetchRestaurant = await getRestaurantByID(fetchProduct.restaurantId);

      if (!fetchProduct || !fetchRestaurant) {
        Alert({
          title: t('Erro'),
          text: t('Erro ao carregar produto!'),
          type: 'error',
        });
      }

      setRestaurant(fetchRestaurant);

      const fetchSegments = await getSegments();

      const segmentsData = fetchSegments.map(item => ({
        value: item.id,
        label: item.name,
      }));

      setSegments(segmentsData);

      setProduct({
        ...fetchProduct,
        segments: fetchProduct.segments.map(segment => (
          segmentsData.find(({ value }) => segment === value,
          ))),
      });
      setLoading(false);
    };

    init();
  }, [params]);

  const actionType = params.type === t('editar')
  && userPermission('general', 'edit')
    ? 'edit' : 'view';

  const baseUrl = `/${t('produtos')}/${params.type}/${params.id}`;

  const TabComponent = ({ component: Component }) => (
    <Component
      t={t}
      params={params}
      classes={classes}
      actionType={actionType}
      history={history}
      product={product}
      segments={segments}
      restaurant={restaurant}
      setProduct={setProduct}
      userPermission={userPermission}
    />
  );

  const tabs = [
    {
      name: 'Geral',
      route: baseUrl,
      screen: <TabComponent component={General} />,
      btnCloseShow: false,
    },
    {
      name: 'Fornecedores',
      route: `${baseUrl}/${t('fornecedores')}`,
      screen: <TabComponent
        component={Supplier}
      />,
      btnCloseLink: parsedBudgetId ? `/${t('orcamentos')}/${t('editar')}/${parsedBudgetId}/${t('produtos')}` : `/${t('produtos')}`,
    },
  ];

  if (loading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return segments ? (
    <Tabs
      components={tabs}
      btnCloseLink={`/${t('produtos')}`}
      btnCloseLabel={'Fechar'}
    />
  ) : null;
};

export default flow(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(ProductEdit);
