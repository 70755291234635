export default theme => ({
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  modalForm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    margin: 10,
    width: '90%',
    maxWidth: 480,
    paddingBottom: 30,
    textAlign: 'center',
  },
  modalReport: {
    padding: 30,
    width: '100%',
    maxWidth: 620,
  },
  '@media(max-width: 760px)': {
    modalReport: {
      height: '100%',
      overflowY: 'auto',
    },
  },
  modalCloseContaier: {
    textAlign: 'right',
  },
  modalTitle: {
    paddingBottom: 30,
  },
  errorTitle: {
    textAlign: 'center',
    paddingBottom: 15,
    paddingTop: 15,
    color: theme.palette.danger.main,
  },
  successTitle: {
    textAlign: 'center',
    paddingBottom: 15,
    color: theme.palette.success.main,
  },
  modalProgressWrapper: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  alertDanger: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.common.white,
  },
  alertSuccess: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
});
