const SignInForm = [
  {
    name: 'email',
    label: 'E-mail',
    required: true,
    format: 'email',
    grid: {
      xs: 12,
    },
  },
  {
    name: 'password',
    label: 'Senha',
    required: true,
    textType: 'password',
    grid: {
      xs: 12,
    },
  },
];

export default SignInForm;
