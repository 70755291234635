import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { translate } from 'admin-base-component-library';

// the translations
const resources = {
  en: {
    translation: {
      // Admin-Lib translations
      ...translate.en,

      // Data Types
      Restaurantes: 'Restaurants',
      Franqueados: 'Franchisees',
      Administrativo: 'Administrative',
      Restaurante: 'Restaurant',
      Franqueado: 'Franchisee',
      Segmento: 'Segment',
      Produto: 'Product',
      Segmentos: 'Segments',
      Fornecedores: 'Suppliers',
      Fornecedor: 'Supplier',
      Usuário: 'User',
      Perfil: 'Profile',
      Orçamento: 'Budget',
      Orçamentos: 'Budgets',
      'este segmento': 'this segment',
      'este usuário': 'this user',
      'este restaurante': 'this restaurant',
      'este fornecedor': 'this supplier',
      'este produto': 'this product',
      'este orçamento': 'this budget',

      // Paths
      restaurantes: 'restaurants',
      franqueados: 'franchisees',
      produtos: 'products',
      segmentos: 'segments',
      fornecedores: 'suppliers',
      editar: 'edit',
      visualizar: 'view',
      editarPerfil: 'editProfile',
      novo: 'new',
      orcamentos: 'budgets',
      quotacao: 'quotation',
      'orcamentos-fornecedor': 'budget-supplier',
      'orcamento-resposta': 'budget-response',

      // Table & headers
      Bloquear: 'Block',
      Cotação: 'Quotation',
      Quantidade: 'Quantity',
      Imagem: 'Image',
      'Nome/Marca(s)/Gramatura': 'Name/Brand(s)/Weight',
      'Tipo de unidade': 'Unit type',
      'Enviar para o cliente': 'Send to client',
      Nome: 'Name',
      Título: 'Title',
      Descrição: 'Description',
      Endereço: 'Address',
      Complemento: 'Complement',
      'Horário de entrega': 'Delivery Time',
      'Prazo de fechamento': 'Closing deadline',
      Cidade: 'City',
      Estado: 'State',
      Bairro: 'Neighborhood',
      Número: 'Number',
      'Editar perfil': 'Edit profile',
      perfil: 'profile',
      Telefone: 'Phone',
      'Restaurante Matriz': 'Master Restaurant',
      Tipo: 'Type',
      Ativo: 'Active',
      Produtos: 'Products',
      Atualizado: 'Updated at',
      'Pesquisar cidade': 'Search city',
      'Filtrar por': 'Filter by',
      'Buscar por': 'Search for',
      Contato: 'Contact',
      País: 'Country',
      Moeda: 'Currency',
      Rua: 'Street',
      'Status do pagamento': 'Payment status',
      'Aguardando pagamento': 'Awaiting payment',
      Observação: 'Observation',
      Observações: 'Observations',
      Valor: 'Value',
      'Senha atual': 'Current password',
      'Nova senha': 'New password',
      'Senha de confirmação': 'Confirmation password',
      'Nome do responsável': 'Contact Name',
      'Nome do restaurante': 'Restaurant Name',
      'Pedido mínimo (R$)': 'Minimum order (R$)',
      'Nome da empresa': 'Company name',
      'Nome do representante': 'Representative name',
      'Atende o bairro': 'Serves neighborhood',
      'Bairros que atende': 'Served neighborhoods',
      'Data de criação': 'Created at',
      Geral: 'General',
      'Data limite': 'Limit date',
      Modelo: 'Model',
      Aberto: 'Open',
      'Observações Gerais': 'General Observation',
      'Prazo de entrega': 'Delivery time',
      'Prazo de pagamento': 'Payment term',
      'Validade da cotação': 'Quotation validity',
      'Forma de pagamento': 'Payment method',
      Quotar: 'Quote',
      'Preço total': 'Total price',
      'Data da resposta': 'Response date',
      'Fornecedores Participantes': 'Suppliers Participating',
      'Valor unitário': 'Unit value',

      // Field options
      'Em andamento': 'In Progress',
      Cancelado: 'Cancelled',
      Concluído: 'Completed',
      Finalizado: 'Done',
      Salvo: 'Saved',
      Cobrar: 'Charge',
      Atrasado: 'Late',
      Pago: 'Paid',
      Aprovado: 'Approved',
      Aguardando: 'Waiting',
      'A pagar': 'Payable',
      'Todos os bairros': 'All neighborhoods',
      'Horário para cotação': 'Quote time',
      'Horário para pedido': 'Order time',
      horas: 'hours',
      Copiar: 'Copy',
      Preço: 'Price',
      'Menor preço': 'Lowest price',
      'Quantidade de pedidos': 'Number of orders',
      'Total do pedido': 'Order total',
      'Total diferença': 'Total difference',
      'Total mais caro': 'Total most expensive',
      'Total mais barato': 'Total least expensive',
      'Total economizado': 'Total saved',
      'Enviar E-mail': 'Send E-mail',
      'Diferença de preço': 'Price difference',
      'Resumo do pedido': 'Order summary',
      'Gerar Pedido': 'Generate Order',
      'Finalizar cotação': 'Finish quotation',
      'Finalizar!': 'Finish!',
      'Sem resposta': 'No response',
      'Salvar & Registar novo': 'Save & Register New',

      // Buttons
      Enviar: 'Send',
      Prosseguir: 'Continue',
      'Adicionar Produtos': 'Add Products',

      // Alert Success and Error Messages
      'Perfil salvo com sucesso!': 'Profile successfully saved!',
      Indisponível: 'Unavailable',
      'Erro de validação': 'Validation error',

      // Messages
      'O campo Cidade / Estado tem um país diferente do selecionado!': 'This City / State is not valid for the selected country.',
      'Selecione um país!': 'Select a country!',
      'Preencha no mínimo 3 caracteres para filtrar': 'Write at least 3 letters to filter',
      'Usuário não existe ou está desativado.': 'User does not exist or is inactive.',
      'Senha inválida!': 'Invalid password!',
      'Já existe um segmento com este nome.': 'Already a segment with this name.',
      'Já existe um bairro com este nome.': 'Already a neighborhood with this name.',
      'Ocorreu um erro inesperado.': 'Unexpected error',
      'O bairro não pode ter o nome todos': "Neighborhood can't have name All",
      'Erro ao carregar restaurante': 'Error loading restaurant',
      'Fornecedor vinculado com sucesso.': 'Supplier successfully linked.',
      'Fornecedor desvinculado com sucesso.': 'Supplier successfully unlinked.',
      'Restaurante salvo com sucesso.': 'Restaurant successfully saved.',
      'Restaurante Atualizado': 'Restaurant Updated',
      'Produto salvo com sucesso.': 'Product successfully saved.',
      'Erro ao carregar produto': 'Error loading product',
      'Produto Atualizado': 'Product Updated',
      'Produto Adicionado': 'Product Added',
      'Segmento Atualizado': 'Segment Updated',
      'Segmento Adicionado': 'Segment Added',
      'Segmento salvo com sucesso.': 'Segment successfully saved.',
      'Orçamento Adicionado': 'Budget Added',
      'Orçamento salvo com sucesso.': 'Budget successfully saved.',
      'Orçamento Editado': 'Budget Edited',
      'Orçamento Enviado': 'Budget Sent',
      'Orçamento editado com sucesso.': 'Budget successfully edited.',
      'Orçamento enviado com sucesso.': 'Budget successfully sent.',
      'Você tem certeza que finalizou o orçamento e gostaria de enviar aos fornecedores?':
        'Are you sure you finished the budget and would like to send it to the suppliers?',
      'Você tem certeza que finalizou a cotação e gostaria de enviar ao supermercado?':
        'Are you sure you finished the quotation and would like to send it to the restaurant?',
      'O campo': 'Field',
      'é obrigatório': 'is required',
      'Adicione produtos ao orçamento.': 'Add products to the budget.',
      'Erros de validação': 'Validation errors',
      'Digite para iniciar a busca': 'Type to start the search',
      'Desabilitar todos fornecedores': 'Disable all suppliers',
      'Habilitar todos fornecedores': 'Enbale all suppliers',
      'Cotação Enviada': 'Sended Quotation',
      'Cotação salva com sucesso.': 'Quotation successfully saved.',
      'Erro ao salvar cotação.': 'Error saving quotation.',
      'Erro ao carregar orçamento': 'Error loading budget',
      'Tem certeza que deseja selecionar este fornecedor?': 'Are you sure you want to select this supplier?',
      'Tem certeza que deseja finalizar este orçamento? Os fornecedores não poderão mais responder ao orçamento caso ele seja finalizado.': 'Are you sure you want to set this budget as finished? The suppliers will no longer be able to respond if the budget is finalized.',
      'Você tem certeza que finalizou a cotação e gostaria de enviar ao restaurante?': 'Are you sure you finished the quotation and would like to send it to the restaurant?',
      'Finalizar Orçamento': 'Finish Budget',
      'Alterar Fornecedor': 'Update supplier',
      'A alteração de fornecedores só pode ocorrer em cotações finalizadas.': 'You can only change suppliers on finalized quotations.',
      'Usuários administrativos não podem alterar o fornecedor selecionado.': 'Admin users cannot change the selected supplier',
      'Erro ao finalizar orçamento': 'Error when finishing budget',
      'Orçamento finalizado': 'Budget finished',
      'Orçamento finalizado com sucesso': 'Budget successfully finished',
      'Clique no produto para alterar o fornecedor': 'Click on the product to change the supplier',
      'Cotação sem produtos': 'Quote without products',
      'A cotação não contém nenhum produto.': 'Quote does not contain products.',
      'Preencha as quantidades de todos os produtos do orçamento.': 'Please fill the quantity field for all products in the quote.',
      'Produto não encontrado ou sem segmentos vinculados.': 'Product not found or does not contain segments.',
      'Produto Duplicado': 'Duplicate Product',
      'O produto que você está tentando adicionar já está na lista.': 'The product you are trying to add is already on the list.',
      'Você tem certeza que finalizou o orçamento e gostaria de salvá-lo?': 'Are you sure you finished the quotation and would like save it?',
      'Os itens {{unquotedProducts}} não foram cotados. Você tem certeza que deseja enviar mesmo assim?': 'The items {{unquotedProducts}} were not quoted.Are you sure you want to send anyway?',
      Atenção: 'Warning',
      'O seu pedido de orçamento foi enviado com sucesso! Obs - O produto {{productName}} não tem fornecedores vinculados.': 'Your request was successfully sent to suppliers! Obs - The product {{productName}} does not have any suppliers',
      'O seu pedido de orçamento foi enviado com sucesso! Obs - Os produtos {{productName}} não tem fornecedores vinculados.': 'Your request was successfully sent to suppliers! Obs - The products {{productName}} does not have any suppliers',
      'Alguns campos do cabeçalho são obrigatórios. Revise e tente novamente.': 'Some header fields are required. Please review and try again.',
      'Tem certeza que deseja inativar este restaurante?': 'Are you sure you want to deactivate this restaurant?',
      'Restaurante Inativado': 'Restaurant Inactivated',
      'Restaurante inativado com sucesso.': 'Restaurant successfully deactivated.',
      'Tem certeza que deseja desvincular este restaurante?': 'Are you sure you want to unlink this restaurant?',
      'Restaurante Desvinculado': 'Restaurant Unlinked',
      'Restaurante desvinculado com sucesso': 'Restaurant successfully unlinked.',
      'Novo produto': 'New product',
      'Solicitar Fornecedor': 'Request Supplier',
      'Data inválida': 'Invalid date',
      'Data limite inválida': 'Invalid deadline',
      'Não são permitidas datas anteriores a de hoje.': 'Dates earlier than today are not allowed.',
      'Não são permitidas datas posteriores a 48 horas.': 'Dates later than 48 hours are not allowed.',
      'Você tem certeza que finalizou a solicitação de produtos e gostaria de enviar aos restaurantes franqueados?': 'Are you sure you finished the product request form and would like to send it to the franchises?',
      'Solicitação Editada': 'Request Updated',
      'Solicitação editada com sucesso.': 'Request successfully updated.',
      'Solicitação Adicionada': 'Request Added',
      'Solicitação de produtos salvo com sucesso.': 'Product request successfully saved.',
      'Solicitar Produtos': 'Request Products',
      'Não é possível criar uma nova solicitação': 'Unable to create a new request',
      'Você já possui um orçamento aberto.': 'You already have an open request.',
      'Você tem certeza que finalizou o orçamento e gostaria de enviá-lo?': 'Are you sure you finished the quotation and would like send it',
      'Você tem certeza que deseja bloquear o item?': 'Are you sure you want to block the item?',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'pt',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
