import { FirebaseService } from 'admin-base-component-library';

export const ITEMS_PER_PAGE = 50;

const getFilters = (filters) => {
  const filter = [];

  if (filters?.name) {
    filter.push({ field: 'name', type: '>=', value: filters.name });
    filter.push({ field: 'name', type: '<=', value: `${filters.name}\uf8ff` });
  }

  if (filters?.restaurant?.value) {
    filter.push({
      field: 'restaurantId',
      type: '==',
      value: filters.restaurant.value,
    });
  }

  if (filters?.segment?.value) {
    filter.push({
      field: 'segments',
      type: 'array-contains',
      value: filters.segment.value,
    });
  }

  return filter;
};

export const getProductsByCode = async (codes) => {
  const allProducts = await Promise.all(Array.from({ length: Math.ceil(codes.length / 10) }).map(
    (_, index) => {
      const start = index * 10;
      const end = start + 10;
      const slicedCodes = codes.slice(start, end);
      return FirebaseService.getDataList({
        collectionName: 'product',
        filters: [
          {
            field: 'code',
            type: 'in',
            value: slicedCodes,
          },
        ],
      });
    },
  ));

  return allProducts.flat();
};

export const getAllProducts = async ({ page, filters, ordering }) => (
  FirebaseService.getDataList({
    collectionName: 'product',
    order: ordering?.orderBy || null,
    orderType: ordering?.orderType || 'asc',
    limit: ITEMS_PER_PAGE,
    paginate: true,
    startAfterDoc: page,
    filters: [
      ...getFilters(filters),
    ],
  })
);

export const getRestaurantProducts = async ({
  restaurantId,
  page = null,
  filters,
  ordering,
}) => FirebaseService.getDataList({
  collectionName: 'product',
  limit: ITEMS_PER_PAGE,
  paginate: true,
  startAfterDoc: page,
  filters: [
    { field: 'restaurantId', type: '==', value: restaurantId },
    ...getFilters(filters),
  ],
  order: ordering?.orderBy || 'name',
  orderType: ordering?.orderType || 'asc',
});

export const getAllRestaurantProducts = async restaurantId => FirebaseService.getDataList({
  collectionName: 'product',
  paginate: false,
  filters: [
    { field: 'restaurantId', type: '==', value: restaurantId },
  ],
  order: 'name',
  orderType: 'asc',
});

export const saveProduct = async ({
  name,
  restaurantId,
  unit,
  segments,
  disabledSuppliers = [],
}, product) => {
  const productSegments = segments || [];

  const data = {
    name,
    restaurantId,
    unit,
    segments: !!productSegments[0]?.value
      ? productSegments.map(({ value }) => value)
      : productSegments,
    disabledSuppliers: disabledSuppliers || [],
  };

  if (!product?.id) {
    return FirebaseService.create('product', data, 'code');
  }

  await FirebaseService.update('product', product.id, data);

  return { id: product.id };
};


export const removeProduct = async (id) => {
  await FirebaseService.remove('product', id);
};

export const getProductById = async id => (
  FirebaseService.getInstanceById('product', id)
);
