import debounce from 'awesome-debounce-promise';
import moment from 'moment';
import { getRestaurantProducts } from '../../services/product';
import { BUDGET_STATUS, getStatusLabel } from '../../services/budget';

const getProducts = debounce(
  async (restaurantId, value, products, productsList) => {
    const { data } = await getRestaurantProducts({ restaurantId, filters: { name: value } });

    if (data.length === 0) {
      return productsList;
    }

    return data.reduce((accProducts, {
      id, name, unit, segments,
    }) => {
      if (products.find(currentProduct => currentProduct.productId === id)) {
        return accProducts;
      }
      return [
        ...accProducts,
        {
          value: id, label: name, unit, segments,
        },
      ];
    }, []);
  },
  500,
);

export const defaultValues = {
  name: '',
  deadline: '',
  deliveryTime: '',
  observation: '',
  status: BUDGET_STATUS.OPEN,
};

export const productsForm = (
  restaurantId,
  products,
  productsList,
  setSelectedProduct,
  CreateButton,
  isFranchise,
  isChildBudget,
) => [
  {
    name: 'product',
    label: 'Produto',
    type: 'autocomplete',
    required: true,
    textSearch: 'Digite para iniciar a busca',
    options: productsList,
    handleFilter: async value => getProducts(
      restaurantId, value, products, productsList,
    ),
    onSelect: (input) => {
      input.setFieldValue('unit', input.value?.unit);
      setSelectedProduct(input.value);
      if (input.value?.unit) {
        setTimeout(() => {
          document.querySelector('#quantity').focus();
        }, 300);
      }
    },
    grid: isFranchise || isChildBudget ? { xs: 12, md: 5 } : { xs: 8, md: 4 },
  },
  {
    type: 'component',
    component: CreateButton,
    align: 'center',
    hidden: isFranchise || isChildBudget,
    grid: { xs: 4, md: 1 },
  },
  {
    name: 'quantity',
    label: 'Quantidade',
    format: 'currency',
    required: true,
    grid: { xs: 6, md: 4 },
  },
  {
    name: 'unit',
    label: 'Tipo de unidade',
    type: 'select',
    options: [
      { value: 'kg', label: 'Kg' },
      { value: 'un', label: 'Un' },
    ],
    readOnly: isFranchise,
    grid: { xs: 6, md: 3 },
  },
  {
    name: 'observation',
    label: 'Observação',
    grid: { xs: 12 },
  },
];

export const productsTableForm = table => [
  {
    component: table,
    type: 'component',
    grid: { xs: 12 },
  },
];

export const filterFields = (restaurants, userPermission) => [
  {
    name: 'restaurant',
    type: 'autocomplete',
    options: restaurants,
    label: 'Restaurante',
    hidden: userPermission('restaurant', 'hidden'),
    grid: { xs: 6, sm: 3 },
    size: 'small',
  },
  {
    name: 'status',
    label: 'Status',
    type: 'select',
    grid: { xs: 6, sm: 3 },
    size: 'small',
    options: [
      {
        value: false,
        label: 'Todos',
      },
      {
        value: BUDGET_STATUS.OPEN,
        label: getStatusLabel(BUDGET_STATUS.OPEN),
      },
      {
        value: BUDGET_STATUS.FINALIZED,
        label: getStatusLabel(BUDGET_STATUS.FINALIZED),
      },
      {
        value: BUDGET_STATUS.CANCELLED,
        label: getStatusLabel(BUDGET_STATUS.CANCELLED),
      },
      {
        value: BUDGET_STATUS.SAVED,
        label: getStatusLabel(BUDGET_STATUS.SAVED),
      },
    ],
  },
];

export default (
  t,
  isParent,
  isFranchise,
  productsTable,
  isNew,
  isChildBudget,
  isMasterAndRequested,
  isDisabled,
) => [
  {
    name: 'name',
    label: 'Nome',
    required: true,
    readOnly: isMasterAndRequested ? false : isFranchise || isChildBudget,
    grid: { xs: 12, md: 6 },
  },
  {
    name: 'endsAt',
    label: 'Data limite',
    type: 'dateTime',
    minDate: !(isDisabled
      || (isMasterAndRequested ? false : !!isFranchise
      || isChildBudget)) && new Date(),
    maxDate: moment().add(2, 'days'),
    minDateMessage: t('Não são permitidas datas anteriores a de hoje.'),
    maxDateMessage: t('Não são permitidas datas posteriores a 48 horas.'),
    required: true,
    readOnly: isMasterAndRequested ? false : isFranchise || isChildBudget,
    grid: { xs: 12, md: 3 },
  },
  {
    name: 'deliveryTime',
    label: 'Horário de entrega',
    hidden: isParent && !isChildBudget,
    grid: { xs: 12, md: 3 },
  },
  {
    name: 'observation',
    label: 'Observação',
    type: 'textarea',
    readOnly: isMasterAndRequested ? false : isFranchise || isChildBudget,
    grid: { xs: 12 },
  },
  {
    component: productsTable,
    type: 'component',
    hidden: !isParent || isNew || isChildBudget,
    grid: { xs: 12 },
  },
];

export const formSelectSupplier = (suppliers, handleState) => [
  {
    name: 'supplier',
    label: 'Fornecedores',
    placeholder: 'Todos os Fornecedores',
    type: 'autocomplete',
    options: suppliers,
    grid: { xs: 12 },
    onSelect: ({ value }) => handleState({ supplier: value, order: '' }),
  },
];

export const formReporter = (
  info,
  summary,
  alertChangeSupplier,
  alertMinOrderSupplier,
  alertSupplierBrand,
  productsTable,
) => [
  {
    component: info,
    type: 'component',
    grid: { xs: 12 },
  },
  {
    label: 'Resumo do pedido',
    type: 'title',
    format: 'subTitle',
    grid: { xs: 12 },
  },
  {
    component: summary,
    type: 'component',
    grid: { xs: 12 },
  },
  {
    label: 'Produtos',
    type: 'title',
    format: 'subTitle',
    grid: { xs: 12 },
  },
  {
    component: alertChangeSupplier,
    type: 'component',
    grid: { xs: 12 },
  },
  {
    component: alertSupplierBrand,
    type: 'component',
    grid: { xs: 12 },
  },
  {
    component: alertMinOrderSupplier,
    type: 'component',
    grid: { xs: 12 },
  },
  {
    component: productsTable,
    type: 'component',
    grid: { xs: 12 },
  },
];

export const formChildBudgetResponse = (
  info,
  productsTable,
) => [
  {
    component: info,
    type: 'component',
    grid: { xs: 12 },
  },
  {
    label: 'Produtos',
    type: 'title',
    format: 'subTitle',
    grid: { xs: 12 },
  },
  {
    component: productsTable,
    type: 'component',
    grid: { xs: 12 },
  },
];

export const formSupliers = (Table, responses, suppliers) => [
  {
    label: `Fornecedores Participantes (${responses}/${suppliers} respostas)`,
    type: 'title',
    format: 'subTitle',
    grid: { xs: 12 },
  },
  {
    component: Table,
    type: 'component',
    grid: { xs: 12 },
  },
];

export const formFranchiseesRestaurant = Table => [
  {
    label: 'Restaurantes Associados Participantes',
    type: 'title',
    format: 'subTitle',
    grid: { xs: 12 },
  },
  {
    component: Table,
    type: 'component',
    grid: { xs: 12 },
  },
];

export const formChangeSupplier = (infoField, editableField) => [
  {
    name: 'product',
    label: 'Produto',
    component: infoField,
    type: 'component',
    grid: { xs: 12, md: 6 },
  },
  {
    name: 'quantity',
    label: 'Quantidade',
    component: editableField,
    type: 'component',
    grid: { xs: 12, md: 6 },
  },
  {
    name: 'observation',
    label: 'Observação',
    component: infoField,
    type: 'component',
    grid: { xs: 12 },
  },
];
