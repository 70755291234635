export default [
  {
    name: 'avatar',
    type: 'avatar',
    grid: { xs: 12 },
  },
  {
    name: 'name',
    label: 'Nome',
    readOnly: true,
    grid: { md: 6, xs: 12 },
  },
  {
    name: 'email',
    label: 'E-mail',
    format: 'email',
    readOnly: true,
    grid: { md: 6, xs: 12 },
  },
  {
    name: 'password',
    required: true,
    label: 'Senha atual',
    textType: 'password',
    grid: { md: 4, xs: 12 },
  },
  {
    name: 'newPassword',
    label: 'Nova senha',
    textType: 'password',
    grid: { md: 4, xs: 12 },
  },
  {
    name: 'confirmationPassord',
    label: 'Senha de confirmação',
    format: 'passwordMatch',
    textType: 'password',
    reference: 'newPassword',
    grid: { md: 4, xs: 12 },
  },
];
