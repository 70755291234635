import { parsePhoneNumber } from 'libphonenumber-js';

export const formatCurrency = number => new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
}).format(number);

export const formatPhoneToInternational = (phone) => {
  const { countryCallingCode, nationalNumber } = parsePhoneNumber(phone, 'BR');

  return countryCallingCode + nationalNumber;
};
