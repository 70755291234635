import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Screen, CustomForm, CustomTable, Alert,
} from 'admin-base-component-library';
import table from './table';
import form from './form';
import {
  saveSegment, getPaginatedSegments, removeSegment, ITEMS_PER_PAGE,
} from '../../services/segment';

const Segments = ({ t, history }) => {
  const fetchData = async params => getPaginatedSegments(params);

  const Table = props => (
    <CustomTable
      {...props}
      tableCells={table(t, history)}
      infiniteScroll
      maxHeight="70vh"
      dense
      isVertical
      numberedItems
    />
  );

  const Form = ({
    item,
    edit,
    remove,
    cancelEdit,
    confirmEdit,
  }) => (
    <CustomForm
      item={item}
      edit={edit}
      remove={remove}
      cancelEdit={cancelEdit}
      confirmEdit={confirmEdit}
      fields={form}
    />
  );

  const handleSave = async (values, segment) => {
    await saveSegment(values.name, segment?.id);

    Alert({
      title: segment?.id ? t('Segmento Atualizado') : t('Segmento Adicionado'),
      text: t('Segmento salvo com sucesso.'),
      type: 'success',
    });

    return fetchData({ page: null, ordering: 'name' });
  };

  const removeItem = async (segment) => {
    await removeSegment(segment?.id);
    return fetchData({ page: null, ordering: 'name' });
  };

  return (
    <Screen
      fetchData={fetchData}
      title="Segmento"
      removeMessage={`${t('Tem certeza que deseja excluir')} ${t('este segmento')}?`}
      mainComponent={Table}
      formComponent={Form}
      handleSave={handleSave}
      removeItem={removeItem}
      infiniteScroll
      startPage={null}
      infiniteScrollItemsPerPage={ITEMS_PER_PAGE}
    />
  );
};

export default withTranslation()(Segments);
