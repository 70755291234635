import React, { useState } from 'react';
import {
  withStyles,
  CircularProgress,
} from '@material-ui/core';

import { withTranslation } from 'react-i18next';
import flow from 'lodash.flow';
import {
  CustomTable,
  CustomForm,
  CustomInfo,
} from 'admin-base-component-library';
import orderData from 'lodash.orderby';
import {
  tableChildBudgetResponseProducts,
} from './table';
import { formChildBudgetResponse } from './form';
import styles from './styles';
import OrderBudgetReportCreateOrderModal from './components/OrderBudgetReportCreateOrderModal';
import { numberToFloat } from '../../services/Service';

const OrderChildBudgetReportGeneral = ({
  t,
  classes,
  infoData,
  productData,
  budget,
  restaurant,
  loading,
}) => {
  const [createOrder, setCreateOrder] = useState(false);

  if (loading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }
  const Info = () => <CustomInfo data={infoData} />;

  const ProductsTable = () => (
    <CustomTable
      tableCells={tableChildBudgetResponseProducts}
      data={productData}
      sortData={(order, orderBy, values) => {
        if (order) {
          const formattedValues = values.map(value => ({
            ...value,
            diffPriceValue: numberToFloat(value.diffPrice),
          }));

          const fields = {
            supplier: 'supplierValue',
            price: 'priceTotalValue',
            diffPrice: 'diffPriceValue',
          };

          return orderData(formattedValues, [fields[orderBy] || orderBy], [order]);
        }
        return values;
      }}
      pagination={false}
      isVertical
      numberedItems
    />
  );

  return (
    <div className={classes.root}>
      <CustomForm
        buttonOkLabel="Enviar"
        fields={formChildBudgetResponse(
          Info,
          ProductsTable,
        )}
        buttonCancel={false}
        buttonOk={false}
      />
      <OrderBudgetReportCreateOrderModal
        modal={createOrder}
        budget={budget}
        closeModal={() => setCreateOrder(false)}
        classes={classes}
        t={t}
        restaurant={restaurant}
      />
    </div>
  );
};

export default flow(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(OrderChildBudgetReportGeneral);
