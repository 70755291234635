import React, { useState } from 'react';
import moment from 'moment';
import { Button } from '@material-ui/core';
import {
  CommentRounded,
  AddComment,
  CreateOutlined,
} from '@material-ui/icons';
import {
  CustomForm,
  CustomTable,
  CustomInfo,
  Alert,
} from 'admin-base-component-library';
import {
  numberToFloat,
  numberToCurrency,
} from '../../services/Service';
import AddObservationModal from './components/AddObservationModal';
import AddBrandModal from './components/AddBrandModal';
import { formResponse } from './form';
import { product as tableProduct } from './table';
import { hideProducts } from '../../services/supplier';

const ResponseForm = ({
  t,
  user,
  budget,
  classes,
  view,
  quotation,
  setQuotation,
  setProducts,
  handleCancel,
  handleSave,
  budgetChilds,
}) => {
  const [showHideProducts, setShowHideProducts] = useState(false);
  const [productAddObs, setProductAddObs] = useState(null);
  const [productAddBrand, setProductAddBrand] = useState(null);

  const {
    deliveryTime,
    observation,
    endsAt,
    restaurant: {
      phone: restaurantPhone,
      contact: restaurantContact,
      name: restaurantName,
      address: restaurantAddress,
      cnpj: restauantCnpj,
      email: restaurantEmail,
    },
  } = budget;

  const getRestaurantAddress = () => {
    if (restaurantAddress?.zipCode) {
      const complement = restaurantAddress.complement ? `${restaurantAddress.complement}, ` : '';
      return `
        ${restaurantAddress.street},
        ${complement}
        ${restaurantAddress.number} -
        ${restaurantAddress.neighborhood},
        ${restaurantAddress.city} / ${restaurantAddress.state} - ${restaurantAddress.zipCode}
      `;
    }

    return null;
  };

  const obsIcon = item => (item?.observation ? <CommentRounded /> : <AddComment />);

  const obsAction = item => setProductAddObs(item);

  const brandContent = item => (
    !item?.brand ? (
      <span className={classes.brandContent}>
        {t('Cotar outra marca/gramatura')}
      </span>
    ) : (
      <div className={classes.brandContentWrapper}>
        <span className={classes.brandContent}>
          {item.brand}
        </span>
        <CreateOutlined />
      </div>
    )
  );

  const brandAction = item => setProductAddBrand(item);

  const infoFields = [
    {
      label: 'Nome do representante',
      value: restaurantContact,
      grid: { xs: 12, md: 8 },
    },
    {
      label: 'E-mail',
      value: restaurantEmail,
      grid: { xs: 12, md: 4 },
    },
    {
      label: 'CNPJ',
      value: restauantCnpj,
      grid: { xs: 12, md: 8 },
    },
    {
      label: 'Telefone',
      value: restaurantPhone,
      grid: { xs: 12, md: 4 },
    },
    {
      label: 'Endereço',
      value: getRestaurantAddress(),
    },
    {
      label: 'Horário de entrega',
      value: deliveryTime,
      hidden: budgetChilds.length > 0,
      grid: { xs: 12, md: 8 },
    },
    {
      label: 'Data limite',
      value: moment(endsAt.toDate()).format('DD/MM/YYYY HH:mm'),
      grid: { xs: 12, md: 4 },
    },
    {
      label: 'Observação',
      value: observation,
    },
  ];

  const Info = () => (
    <CustomInfo
      data={{
        title: restaurantName,
        fields: infoFields,
      }}
    />
  );

  const ProductsTable = () => {
    const handleSaveProductObs = (values, item) => {
      setProductAddObs(null);
      update(values.observation, item, 'observation');
    };

    const handleSaveProductBrand = (values, item) => {
      setProductAddBrand(null);
      update(values.brand, item, 'brand');
    };

    const update = async (value, item, field, fieldToUpdate) => {
      if (value === true) {
        const alert = await Alert({
          title: t('Cuidado!'),
          text: t('Você tem certeza que deseja bloquear o item?'),
          type: 'warning',
          confirmButtonText: t('Sim'),
          cancelButtonText: t('Não'),
        });

        if (alert.value) {
          setProducts(quotation?.products.map((product) => {
            if (product.productId === item.productId) {
              if (fieldToUpdate === 'priceTotal') {
                return {
                  ...product,
                  [field]: value,
                  [fieldToUpdate]:
                    numberToCurrency(numberToFloat(value) * numberToFloat(product.quantity)),
                };
              }
              return { ...product, [field]: value };
            }
            return product;
          }));

          if (field === 'hide') {
            await hideProducts(user.supplier.id, item.productId, value);
          }
        }
        return;
      }

      setProducts(quotation?.products.map((product) => {
        if (product.productId === item.productId) {
          if (fieldToUpdate === 'priceTotal') {
            return {
              ...product,
              [field]: value,
              [fieldToUpdate]:
                numberToCurrency(numberToFloat(value) * numberToFloat(product.quantity)),
            };
          }
          return { ...product, [field]: value };
        }
        return product;
      }));

      if (field === 'hide') {
        await hideProducts(user.supplier.id, item.productId, value);
      }
    };

    return (
      <>
        <Button
          color="primary"
          size="small"
          variant="outlined"
          onClick={() => setShowHideProducts(!showHideProducts)}
          className={classes.buttonShowProducts}
        >
          { showHideProducts ? 'Ocultar produtos bloqueados' : 'Exibir produtos bloqueados'}
        </Button>
        <CustomTable
          tableCells={tableProduct(
            update,
            obsIcon,
            obsAction,
            brandContent,
            brandAction,
          )}
          data={quotation?.products.filter(({ hide }) => !hide || showHideProducts)}
          pagination={false}
          dense
          isVertical
          numberedItems
        />
        <AddObservationModal
          modal={!!productAddObs}
          product={productAddObs}
          closeModal={() => setProductAddObs(null)}
          classes={classes}
          t={t}
          handleSaveProduct={handleSaveProductObs}
        />
        <AddBrandModal
          t={t}
          classes={classes}
          modal={!!productAddBrand}
          product={productAddBrand}
          closeModal={() => setProductAddBrand(null)}
          handleSaveProduct={handleSaveProductBrand}
        />
      </>
    );
  };

  return (
    <div className={classes.root}>

      <CustomForm
        buttonOkLabel="Enviar"
        fields={formResponse(Info, ProductsTable)}
        defaultValues={quotation}
        handleChanges={values => setQuotation({
          ...values,
          products: quotation.products,
        })}
        disabled={view}
        cancelEdit={handleCancel}
        confirmEdit={handleSave}
        showErrorAlert={() => {
          Alert({
            title: t('Erro'),
            text: t('Alguns campos do cabeçalho são obrigatórios. Revise e tente novamente.'),
            type: 'error',
          });
        }}
      />
    </div>
  );
};

export default ResponseForm;
