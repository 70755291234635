const defaultValues = {
  name: '',
  email: '',
  active: true,
};

const fields = (restaurants, segments, userPermission, edit = false) => [
  {
    name: 'restaurant',
    type: edit ? 'default' : 'autocomplete',
    options: restaurants,
    label: 'Restaurante',
    readOnly: edit,
    hidden: userPermission('restaurant', 'hidden'),
    required: !userPermission('restaurant', 'hidden'),
    grid: { xs: 12, sm: 6 },
  },
  {
    name: 'name',
    label: 'Nome/Marca(s)/Gramatura',
    required: true,
    grid: {
      xs: 12,
      sm: userPermission('restaurant', 'hidden') ? 12 : 6,
    },
  },
  {
    name: 'segments',
    type: 'tags',
    options: segments,
    label: 'Segmento',
    grid: { xs: 12, sm: 6 },
  },
  {
    name: 'unit',
    label: 'Tipo de unidade',
    type: 'select',
    required: true,
    options: [
      { value: 'kg', label: 'Kg' },
      { value: 'un', label: 'Un' },
    ],
    grid: { xs: 12, sm: 6 },
  },
];

const filterFields = (segments, restaurants, userPermission) => [
  {
    name: 'restaurant',
    type: 'autocomplete',
    options: restaurants,
    label: 'Restaurante',
    hidden: userPermission('restaurant', 'hidden'),
    grid: {
      xs: 12,
      sm: 5,
      md: 3,
    },
    size: 'small',
  },
  {
    name: 'name',
    label: 'Nome/Marca(s)/Gramatura',
    grid: {
      xs: 12,
      sm: 5,
      md: 3,
    },
    size: 'small',
  },
  {
    name: 'segment',
    type: 'autocomplete',
    options: segments,
    label: 'Segmento',
    grid: {
      xs: 12,
      sm: 5,
      md: 3,
    },
    size: 'small',
  },
];

const formImport = restaurants => [
  {
    name: 'restaurant',
    type: 'autocomplete',
    options: restaurants,
    label: 'Restaurante',
    required: true,
    grid: { xs: 12, sm: 6 },
  },
  {
    name: 'file',
    type: 'upload',
    format: '.csv',
    label: 'Arquivo CSV',
    required: true,
    multiple: false,
    grid: { xs: 12, sm: 6 },
  },
];

export {
  fields,
  defaultValues,
  filterFields,
  formImport,
};
