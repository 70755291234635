import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Material helpers
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import {
  Layout, NotFound, SignIn, Theme,
} from 'admin-base-component-library';

// Configurations
import config from './config';

// Contexts
import UserContext from './context/UserContext';

// Theme
import palette from './theme/palette';

// Data
import signInForm from './data/signInForm';
import menu from './data/menu';
import topBarButtons from './data/topBarButtons';
import { hasNoPermissionForPath } from './data/permissions';
import logoLight from './assets/images/fullLogoLight.png';
import logo from './assets/images/fullLogo.png';
import adminImage from './assets/images/admin.svg';

// Views
import Users from './views/Users';
import Profile from './views/Profile';
import Restaurants, { RestaurantEdit } from './views/Restaurants';
import Segments from './views/Segments';
import Suppliers from './views/Suppliers';
import OrderBudgetResponse, { OrderBudgetResponseEdit } from './views/OrderBudgetResponse';
import OrderBudget, { OrderBudgetCreate, OrderBudgetReport } from './views/OrderBudget';
import Products, { ProductEdit, ProductCreate } from './views/Products';
import Franchisee from './views/Franchisee/Franchisee';

import { USER_ROLES } from './services/user';

const SignInScreen = props => (
  <SignIn
    {...props}
    signInForm={signInForm}
    loginTypes={config.login}
    userContext={UserContext}
    logo={logo}
    leftImage={adminImage}
  />
);

const LayoutScreen = (props, screen, title, noFooter = true) => (
  <Layout
    title={title}
    userContext={UserContext}
    searchGlobalContext={UserContext}
    menu={menu}
    logo={logoLight}
    avatarLink="perfil"
    topBarButtons={topBarButtons}
    noFooter={noFooter}
  >
    {React.createElement(screen, props)}
  </Layout>
);

const PublicRoute = props => (
  <ThemeProvider theme={Theme(palette)}>
    <CssBaseline />
    <Route {...props} />
  </ThemeProvider>
);

const PrivateRoute = (props) => {
  const { logged, user, authUser } = useContext(UserContext);
  const { path, redirect, location } = props;

  if (logged !== null && !logged) {
    return (
      <Redirect to={{
        pathname: '/login',
        state: { referrer: location.pathname },
      }}
      />
    );
  }

  if (authUser && path === '/' && redirect) {
    return <Redirect to={redirect} />;
  }

  const { permissionKey } = props;
  if (hasNoPermissionForPath(user?.type, permissionKey)) {
    return <Redirect to="/not-found" />;
  }

  return !authUser ? null : (
    <ThemeProvider theme={Theme(palette)}>
      <CssBaseline />
      <Route {...props} />
    </ThemeProvider>
  );
};

const redirect = t => ({
  [USER_ROLES.admin]: `/${t('restaurantes')}`,
  [USER_ROLES.restaurant]: `/${t('orcamentos')}`,
  [USER_ROLES.franchise]: `/${t('orcamentos')}`,
  [USER_ROLES.supplier]: `/${t('orcamentos-fornecedor')}`,
  [USER_ROLES.master]: `/${t('orcamentos')}`,
});

const Routes = (props) => {
  const { logged, user } = useContext(UserContext);
  const { t } = props;
  window.translateAlert = t;
  return logged === null ? null : (
    <Switch>
      <PublicRoute path="/login" render={SignInScreen} />
      <PrivateRoute
        exact
        path="/"
        redirect={redirect(t)[user?.type]}
      />
      <PrivateRoute
        exact
        path={`/${t('perfil')}`}
        render={p => LayoutScreen(p, Profile, 'Perfil')}
      />
      <PrivateRoute
        exact
        path={`/${t('usuarios')}`}
        render={p => LayoutScreen(p, Users, 'Usuários')}
        permissionKey="users"
      />
      <PrivateRoute
        exact
        path={`/${t('segmentos')}`}
        render={p => LayoutScreen(p, Segments, 'Segmentos')}
        permissionKey="segments"
      />
      <PrivateRoute
        exact
        path={`/${t('restaurantes')}`}
        render={p => LayoutScreen(p, Restaurants, 'Restaurantes')}
        permissionKey="restaurants"
      />
      <PrivateRoute
        path={`/${t('restaurantes')}/:type/:id`}
        render={p => LayoutScreen(p, RestaurantEdit, 'Restaurantes')}
        permissionKey="restaurants"
      />
      <PrivateRoute
        path={`/${t('produtos')}/${t('novo')}`}
        render={p => LayoutScreen(p, ProductCreate, 'Produtos')}
        permissionKey="products"
      />
      <PrivateRoute
        path={`/${t('produtos')}/:type/:id`}
        render={p => LayoutScreen(p, ProductEdit, 'Produtos')}
        permissionKey="products"
      />
      <PrivateRoute
        exact
        path={`/${t('produtos')}`}
        render={p => LayoutScreen(p, Products, 'Produtos')}
        permissionKey="products"
      />
      <PrivateRoute
        exact
        path={`/${t('fornecedores')}`}
        render={p => LayoutScreen(p, Suppliers, 'Fornecedores')}
        permissionKey="suppliers"
      />
      <PrivateRoute
        exact
        path={`/${t('franqueados')}`}
        render={p => LayoutScreen(p, Franchisee, 'Franqueados')}
        permissionKey="franchisee"
      />
      <PrivateRoute
        exact
        path={`/${t('orcamentos')}`}
        render={p => LayoutScreen(p, OrderBudget, 'Orçamentos')}
        permissionKey="budget"
      />
      <PrivateRoute
        path={`/${t('orcamentos')}/:type/:idDuplicate?`}
        render={p => LayoutScreen(p, OrderBudgetCreate, 'Orçamentos')}
        permissionKey="budget"
      />
      <PrivateRoute
        exact
        path={`/${t('orcamentos-fornecedor')}`}
        render={p => LayoutScreen(p, OrderBudgetResponse, 'Orçamentos')}
        permissionKey="budgetSupplier"
      />
      <PrivateRoute
        path={`/${t('orcamentos-fornecedor')}/:type/:id`}
        render={p => LayoutScreen(p, OrderBudgetResponseEdit, 'Orçamentos')}
        permissionKey="budgetSupplier"
      />
      <PrivateRoute
        path={`/${t('orcamento-resposta')}/:id/:restaurantId?`}
        render={p => LayoutScreen(p, OrderBudgetReport, 'Orçamentos', false)}
        permissionKey="budget"
      />
      <PrivateRoute
        path={`/${t('orcamento-resposta')}/:id/${t('fornecedores')}`}
        render={p => LayoutScreen(p, OrderBudgetReport, 'Orçamentos', false)}
        permissionKey="budget"
      />
      <PublicRoute component={NotFound} exact path="/not-found" />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default withTranslation()(Routes);
