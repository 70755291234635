import { USER_ROLES } from '../../services/user';
import { usePermission } from '../../hooks/useUserPermission';

const permissions = {
  [USER_ROLES.admin]: {
    restaurant: { tableHidden: false, hidden: false },
    general: {
      getAllProducts: true,
      edit: false,
    },
  },
  [USER_ROLES.restaurant]: {
    restaurant: { tableHidden: true, hidden: true },
    general: {
      getAllProducts: false,
      edit: true,
    },
  },
  [USER_ROLES.franchise]: {
    restaurant: { tableHidden: true, hidden: true },
    general: {
      getAllProducts: false,
      edit: true,
    },
  },
  [USER_ROLES.master]: {
    restaurant: { tableHidden: true, hidden: true },
    general: {
      getAllProducts: false,
      edit: true,
    },
  },
};

const useUserPermission = () => usePermission(permissions);

export {
  useUserPermission,
};
