export default [
  {
    name: 'name',
    label: 'Nome',
    required: true,
    grid: {
      xs: 12,
      sm: 12,
    },
  },
];
