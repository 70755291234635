import React, { useState, useEffect } from 'react';
import {
  CustomForm,
  CustomTable,
  Alert,
} from 'admin-base-component-library';
import {
  Modal,
  Fade,
  Backdrop,
  Paper,
  CircularProgress,
  Button,
} from '@material-ui/core';
import { CreateOutlined as CreateIcon } from '@material-ui/icons';

import { productsForm, productsTableForm } from '../form';
import { product as tableProduct } from '../table';
import Supplier from '../../Products/Tabs/Supplier';
import { getProductById, getAllRestaurantProducts } from '../../../services/product';
import { BUDGET_STATUS } from '../../../services/budget';

const Products = ({
  t,
  params,
  classes,
  restaurant,
  products,
  addProduct,
  removeProduct,
  updateProduct,
  user,
  handleSave,
  handleCancel,
  handleSend,
  handleProducts,
  setSelectedProduct,
  isFranchise,
  isChildBudget,
  parentRestaurant,
  isParent,
  history,
  statusBudget,
}) => {
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [productEdit, setProductEdit] = useState(null);
  const [loadedProducts, setloadedProducts] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [key, setKey] = useState(1);
  const [status, setStatus] = useState('');

  useEffect(() => {
    const addedProducts = products.map(item => item.productId);
    setListProduct([
      ...loadedProducts.filter(item => !addedProducts.includes(item.value)),
    ]);
    setKey(Math.random());
  }, [products, loadedProducts]);

  useEffect(() => {
    if (statusBudget || history.location?.state?.status) {
      setStatus(statusBudget || history.location?.state?.status);
    }

    const getProducts = async () => {
      const productsList = await getAllRestaurantProducts(
        isFranchise ? user.restaurant.parent : user.restaurant.id,
      );
      const filteredProductsList = productsList.reduce((accProducts, {
        id, name, unit,
      }) => [
        ...accProducts,
        {
          value: id, label: name, unit,
        },
      ], []);
      setloadedProducts(filteredProductsList);
      setLoading(false);
    };
    getProducts();
  }, []);

  const openModal = async (open, product) => {
    setProductEdit(null);
    setModal(open);

    if (open && product) {
      const fetchProduct = await getProductById(product.productId);
      if (fetchProduct.error || !fetchProduct.segments) {
        setModal(false);
        Alert({
          title: t('Erro'),
          text: t('Produto não encontrado ou sem segmentos vinculados.'),
          type: 'error',
        });
        return;
      }
      setProductEdit({
        ...fetchProduct,
        segments: fetchProduct.segments.map(segment => ({
          value: segment,
        })),
      });
    }
  };

  const CreateButton = () => (
    <Button
      color="primary"
      size="large"
      variant="contained"
      className={classes.createButton}
      onClick={() => handleProducts()}
    >
      <CreateIcon />
    </Button>
  );
  const isEditQuantity = (status === BUDGET_STATUS.OPEN || status === BUDGET_STATUS.FINALIZED)
    || isParent;
  const isMasterAndRequested = (status === BUDGET_STATUS.REQUESTED && isParent);

  return loading ? null : (
    <div className={classes.root}>
      {(!isEditQuantity || isMasterAndRequested) && (
        <CustomForm
          fields={productsForm(
            user.restaurant.id,
            products,
            listProduct,
            setSelectedProduct,
            CreateButton,
            isFranchise,
            isChildBudget,
          )}
          disabled={!(params.type === t('novo') || params.type === t('editar'))}
          buttonOkLabel="Adicionar"
          buttonCancel={false}
          buttonOkAlignRight
          confirmEdit={async (values) => {
            setLoading(true);
            const added = await addProduct(values);
            if (added) {
              setTimeout(() => document.querySelector('#product')?.focus(), 500);
            }
            setLoading(false);
          }}
          key={key}
        />
      )}

      <CustomForm
        buttonOk={!isChildBudget && !isEditQuantity}
        confirmEdit={handleSend}
        buttonOkLabel={t('Enviar')}
        cancelEdit={handleCancel}
        customButtons={[
          {
            label: 'Salvar',
            action: () => {
              handleSave();
            },
          },
        ]}
        fields={productsTableForm(() => (
          <CustomTable
            tableCells={tableProduct({
              removeProduct,
              updateProduct,
              editProduct: row => openModal(true, row),
              isFranchise,
              isEditQuantity: !(!isEditQuantity || isMasterAndRequested),
            })}
            data={products}
            pagination={false}
            remove={removeProduct}
            edit={row => openModal(true, row)}
            isVertical
            numberedItems
          />
        ),
        )}
      />

      <Modal
        open={modal}
        className={classes.modalForm}
        onClose={() => openModal(false)}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <Paper className={classes.modalContainer}>

            {!productEdit && (
              <div className={classes.progressWrapper}>
                <CircularProgress />
              </div>
            )}

            { productEdit && (
              <>
                <Supplier
                  t={t}
                  classes={classes}
                  product={productEdit}
                  setProduct={setProductEdit}
                  params={{
                    ...params,
                    type: t('editar'),
                  }}
                  restaurant={parentRestaurant || restaurant}
                />
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={() => openModal(false)}
                >
                  {t('Fechar')}
                </Button>

              </>
            )}
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
};

export default Products;
