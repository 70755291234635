import React, { useContext, useState } from 'react';
import { withStyles, CircularProgress } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { CustomForm, Alert, Exceptions } from 'admin-base-component-library';
import flow from 'lodash.flow';

import UserContext from '../../context/UserContext';

import form from './form';
import styles from './styles';
import { updateProfile } from '../../services/user';

const Profile = ({ classes, t }) => {
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useContext(UserContext);

  const handleSave = async (values) => {
    try {
      setLoading(true);

      const { profileAvatar } = await updateProfile({
        ...values,
        id: user.id,
        loginEmail: user.email,
        active: user.active,
        type: user.type,
      });

      const { name, email } = values;
      setUser({
        ...user,
        avatar: profileAvatar,
        profileAvatar,
        name,
        email,
      });

      Alert({
        title: t('Sucesso'),
        text: 'Perfil salvo com sucesso!',
        type: 'success',
      });
    } catch (e) {
      Alert({
        title: t('Erro'),
        text: e instanceof Exceptions.RenderException
          ? e.message : t('Ocorreu algum erro inesperado. Tente novamente!'),
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return user ? (
    <div className={classes.root}>
      <CustomForm
        fields={form}
        confirmEdit={handleSave}
        defaultValues={{ ...user, avatar: { preview: user.profileAvatar } }}
        buttonCancel={false}
      />
    </div>
  ) : null;
};

export default flow(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(Profile);
