import React, { useState } from 'react';
import {
  Modal,
  Fade,
  Backdrop,
  Paper,
  Typography, CircularProgress, Button,
} from '@material-ui/core';
import { CustomForm, CustomTable, FirebaseDb } from 'admin-base-component-library';
import { useTranslation } from 'react-i18next';
import csv2json from 'csvjson-csv2json';
import { Alert as AlertMUI } from '@material-ui/lab';
import { formImport } from '../form';
import { tableImport } from '../table';

const ImportProductModal = ({
  modal,
  classes,
  closeModal,
  restaurants,
  segments,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [importError, setImportError] = useState(false);
  const [importUnexpectedError, setImportUnexpectedError] = useState(false);
  const [importSuccess, setImportSuccess] = useState(false);
  const handleImport = async (values) => {
    setLoading(true);

    const file = values.file[0].upload;
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async (event) => {
      const records = csv2json(event.target.result, { parseNumbers: true });

      const errors = [];

      records.forEach((record, index) => {
        const currentError = [];

        const name = record['Nome/Marca(s)/Gramatura'] || '';
        const segment = record.Segmentos ? record.Segmentos.split(';') : [];
        const unit = record['Tipo de unidade'] ? record['Tipo de unidade'].toLowerCase() : '';

        if (!name || !segment || !unit) {
          currentError.push('Preencha os campos: Nome/Marca(s)/Gramatura, Segmentos e Tipo de unidade');
        }

        if (unit && !['un', 'kg'].includes(unit)) {
          currentError.push('Tipo de unidade invalido, tipos suportados: Un, Kg');
        }

        segment.forEach((item) => {
          const find = Object.entries(segments)
            .find(current => current[1].toLowerCase() === item.toLowerCase());
          if (!find) {
            currentError.push(`Segmento não encontado: ${item}`);
          }
        });

        if (currentError.length > 0) {
          errors.push({
            line: index + 2,
            error: currentError.join('\n'),
          });
        }
      });

      if (errors.length > 0) {
        setImportError(errors);
        setLoading(false);
        return;
      }

      try {
        const batch = FirebaseDb.getDB().batch();

        await Promise.all(records.map(async (record) => {
          const name = record['Nome/Marca(s)/Gramatura'];
          const segment = record.Segmentos.split(';');
          const unit = record['Tipo de unidade'].toLowerCase();

          const productRef = await FirebaseDb.getDB().collection('product');
          const productDoc = productRef.doc();
          batch.set(productDoc, {
            code: productDoc.id,
            restaurantId: values.restaurant.value,
            segments: segment.map((item) => {
              const find = Object.entries(segments)
                .find(current => current[1].toLowerCase() === item.toLowerCase());
              return find[0];
            }),
            name,
            unit,
            createdAt: new Date(),
            updatedAt: new Date(),
            disabledSuppliers: [],
          });
        }));

        await batch.commit();
        setImportSuccess(true);
      } catch (e) {
        setImportUnexpectedError(true);
      } finally {
        setLoading(false);
      }
    };
  };

  const handleClose = () => {
    if (!loading) {
      closeModal();
      setTimeout(() => {
        setImportUnexpectedError(false);
        setImportError(false);
        setImportSuccess(false);
        setImportSuccess(loading);
      }, 500);
    }
  };

  return (
    <Modal
      open={modal}
      className={classes.modalForm}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modal}>
        <Paper className={classes.modalReport}>

          <Typography variant="h2" className={classes.modalTitle}>{t('Importar Produtos')}</Typography>

          {loading && (
            <div className={classes.modalProgressWrapper}>
              <CircularProgress />
            </div>
          )}

          {!importSuccess && !loading && (
          <div id="form-import-product">
            <CustomForm
              isLoading={loading}
              fields={formImport(restaurants)}
              confirmEdit={values => handleImport(values)}
              buttonOkLabel="Importar"
              buttonCancelLabel="Cancelar"
              inlineFilters
              buttonCancelAction={handleClose}
            />
          </div>
          )}

          {importError && (
            <div>
              <AlertMUI
                className={classes.alertDanger}
                icon={false}
                variant="filled"
                severity="warning"
              >
                {t('Arquivo inválido, corrija os seguintes erros para importar os produtos do arquivo')}
              </AlertMUI>
              <CustomTable
                tableCells={tableImport}
                data={importError}
                pagination={false}
              />
            </div>
          )}

          {importUnexpectedError && (
            <div>
              <AlertMUI
                className={classes.alertDanger}
                icon={false}
                variant="filled"
                severity="warning"
              >
                {t('Ocorreu um erro inesperado.')}
              </AlertMUI>
            </div>
          )}

          {importSuccess && (
            <div>
              <AlertMUI
                className={classes.alertSuccess}
                icon={false}
                variant="filled"
                severity="success"
              >
                {t('Produtos importados com sucesso')}
              </AlertMUI>

              <div className={classes.modalCloseContaier}>
                <Button
                  variant="contained"
                  onClick={handleClose}
                >
                  {t('Fechar')}
                </Button>
              </div>
            </div>
          )}


        </Paper>
      </Fade>
    </Modal>
  );
};

export default ImportProductModal;
