import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

export const generatePDF = (content) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const docDefinitions = {
    pageSize: 'A4',
    pageMargins: [15, 30, 15, 20],
    content,
  };

  pdfMake.createPdf(docDefinitions).open();
};
