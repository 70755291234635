import React, { useState, useEffect } from 'react';
import { Tabs, Alert } from 'admin-base-component-library';
import flow from 'lodash.flow';
import { withTranslation } from 'react-i18next';
import { withStyles, CircularProgress } from '@material-ui/core';

import General from './Tabs/General';
import Supplier from './Tabs/Supplier';

import styles from './styles';
import {
  getRestaurantByID,
  getRestaurantParentOptions,
  verifyChild,
} from '../../services/restaurant';
import { getSuppliers } from '../../services/supplier';

const RestaurantEdit = ({
  t,
  match: { params },
  classes,
  history,
}) => {
  const [suppliers, setSuppliers] = useState([]);
  const [restaurant, setRestaurant] = useState(null);
  const [loading, setLoading] = useState(true);
  const [restaurantsOptions, setRestaurantsOptions] = useState([]);
  const [hasChild, setHasChild] = useState(false);

  useEffect(() => {
    const init = async () => {
      if (!params?.id) {
        return;
      }
      const fetchRestaurant = await getRestaurantByID(params.id);
      const fetchSupplier = await getSuppliers();
      const fetchRestaurantsOptions = await getRestaurantParentOptions(params.id);
      const fetchHasChild = await verifyChild(params.id);

      setHasChild(fetchHasChild);

      let parent = { value: null, label: 'Nenhum' };

      setRestaurantsOptions(fetchRestaurantsOptions);

      if (fetchRestaurant.parent) {
        const restaurantParent = await getRestaurantByID(fetchRestaurant.parent);
        parent = { value: restaurantParent.id, label: restaurantParent.name };
      }

      setRestaurant({ ...fetchRestaurant, parent });
      setSuppliers(fetchSupplier);
      setLoading(false);

      if (!fetchRestaurant) {
        Alert({
          title: t('Erro'),
          text: t('Erro ao carregar restaurante!'),
          type: 'error',
        });
      }
    };

    init();
  }, [params]);

  const actionType = params.type === t('editar') ? 'edit' : 'view';
  const baseUrl = `/${t('restaurantes')}/${params.type}/${params.id}`;

  const TabComponent = ({ component: Component }) => (
    <Component
      t={t}
      params={params}
      classes={classes}
      actionType={actionType}
      history={history}
      restaurant={restaurant}
      suppliers={suppliers}
      hasChild={hasChild}
      setRestaurant={setRestaurant}
      restaurantsOptions={restaurantsOptions}
    />
  );

  const tabs = [
    {
      name: 'Geral',
      route: baseUrl,
      screen: <TabComponent component={General} />,
      btnCloseShow: false,
    },
    {
      name: 'Fornecedores',
      route: `${baseUrl}/${t('fornecedores')}`,
      hidden: restaurant?.parent.label !== 'Nenhum',
      screen: <TabComponent component={Supplier} />,
    },
  ];

  if (loading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return restaurant ? (
    <Tabs
      components={tabs}
      btnCloseLink={`/${t('restaurantes')}`}
    />
  ) : null;
};

export default flow(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(RestaurantEdit);
