import React, { useState, useEffect } from 'react';
import { Screen, CustomTable } from 'admin-base-component-library';
import {
  CircularProgress,
  colors,
} from '@material-ui/core';
import {
  Check as IconCheck,
  Close as IconClose,
} from '@material-ui/icons';
import { saveProduct } from '../../../services/product';
import { fieldsSupplier } from '../table';
import { getSuppliers } from '../../../services/supplier';

const Supplier = ({
  classes,
  product,
  setProduct,
  params,
  restaurant,
  t,
}) => {
  const [loadData, setLoadData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [suppliers, setSuppliers] = useState([]);
  const actionType = params.type === t('editar') ? 'edit' : 'view';

  useEffect(() => {
    if (loadData) {
      getData();
    }
  }, [loadData]);

  const getData = async () => {
    const fetchSupplier = await getSuppliers({
      segments: product.segments.map(({ value }) => value),
    });

    const formatSuppliers = fetchSupplier
      .reduce((allSuppliers, { id, name, contactName }) => {
        if (restaurant.suppliers.indexOf(id) > -1) {
          return [
            ...allSuppliers,
            {
              id, name, contactName, status: status(id),
            },
          ];
        }

        return allSuppliers;
      }, []);

    setSuppliers(formatSuppliers);
    setLoadData(false);
    setLoading(false);
  };

  const fetchData = async () => suppliers;

  const status = (id) => {
    if (actionType === 'edit') {
      return product.disabledSuppliers.indexOf(id) === -1;
    }

    return product.disabledSuppliers.indexOf(id) === -1
      ? <IconCheck style={{ color: colors.green[500] }} />
      : <IconClose style={{ color: colors.red[500] }} />;
  };

  const handleSave = async (values) => {
    setLoading(true);
    setProduct(values);
    await saveProduct(values, product);
    setLoadData(true);
  };

  const buttons = () => {
    if (actionType === 'view') {
      return null;
    }

    const actionButtons = [];
    const suppliersStatus = suppliers.reduce((statusSuppliers, supplier) => ({
      ...statusSuppliers,
      [supplier.status ? 'active' : 'inative']: true,
    }), { active: false, inative: false });

    if (suppliersStatus.active) {
      actionButtons.push({
        label: `${t('Desabilitar todos fornecedores')}`,
        action: () => {
          handleSave({
            ...product,
            disabledSuppliers: suppliers.map(({ id }) => id),
          });
        },
      });
    }

    if (suppliersStatus.inative) {
      actionButtons.push({
        label: `${t('Habilitar todos fornecedores')}`,
        action: () => {
          handleSave({
            ...product,
            disabledSuppliers: [],
          });
        },
      });
    }

    return actionButtons;
  };

  const changeStatus = (row) => {
    if (row.status) {
      handleSave({
        ...product,
        disabledSuppliers: [
          ...product.disabledSuppliers,
          row.id,
        ],
      });
      return;
    }

    handleSave({
      ...product,
      disabledSuppliers: product.disabledSuppliers.filter(supplier => supplier !== row.id),
    });
  };

  const Table = props => (
    <CustomTable
      {...props}
      tableCells={fieldsSupplier(actionType === 'edit', changeStatus)}
      maxHeight="65vh"
      dense
      isVertical
      numberedItems
    />
  );

  if (loading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Screen
      fetchData={fetchData}
      title="Fornecedores"
      mainComponent={Table}
      pagination={false}
      isAdded={false}
      buttons={buttons()}
    />
  );
};

export default Supplier;
