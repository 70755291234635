import React, { useState } from 'react';
import {
  withStyles,
  CircularProgress,
  IconButton,
  Button,
  Collapse,
} from '@material-ui/core';
import { Alert as AlertMUI } from '@material-ui/lab';
import { Close as CloseIcon } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import flow from 'lodash.flow';
import {
  Alert,
  CustomTable,
  CustomForm,
  CustomInfo,
} from 'admin-base-component-library';
import orderData from 'lodash.orderby';
import {
  tableBudgetResponseTotal,
  tableBudgetResponseProducts,
} from './table';
import { formReporter } from './form';
import styles from './styles';
import ChangeSupplierModal from './components/ChangeSupplierModal';
import OrderBudgetReportCreateOrderModal from './components/OrderBudgetReportCreateOrderModal';
import {
  BUDGET_STATUS,
  finishBudget,
  updateBudgetProducts,
  updateBudgetResponse,
} from '../../services/budget';
import { USER_ROLES } from '../../services/user';
import { useUserPermission } from './permissions';
import { numberToFloat, numberToCurrency } from '../../services/Service';
import AlertMinOrderSupplier from './components/AlertMinOrderSupplier';

const OrderBudgetReportGeneral = ({
  t,
  classes,
  history,
  user,
  infoData,
  productData,
  loadData,
  onQuantityChangeFinish,
  budget,
  setBudget,
  mainBudgetId,
  mainBudgetStatus,
  total,
  restaurant,
  alertClickChangeSupplier,
  setAlertClickChangeSupplier,
  loading,
  setLoading,
  childBudgets,
  minOrder,
  childHasDeficit,
  calculateProducts,
}) => {
  const [selected, setSelected] = useState(false);
  const [createOrder, setCreateOrder] = useState(false);
  const userPermission = useUserPermission();

  const handleSelectProduct = (selectedProduct, product) => {
    if (user.type === USER_ROLES.admin || budget.status !== BUDGET_STATUS.FINALIZED) {
      return;
    }

    setSelected(null);

    Alert({
      title: t('Alterar Fornecedor'),
      text: `${t('Tem certeza que deseja selecionar este fornecedor?')}`,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: t('Prosseguir'),
      cancelButtonText: t('Cancelar'),
    }).then((result) => {
      if (result.value) {
        setBudget({
          ...budget,
          products: budget.products.map((currentProduct) => {
            if (currentProduct.productId === selectedProduct.id) {
              return {
                ...currentProduct,
                selected: selectedProduct,
              };
            }
            return currentProduct;
          }),
        });
        return;
      }
      setSelected(product);
    });
  };

  const handleUpdateBudgetProducts = async (showAlert = true) => {
    try {
      const productsToUpdate = budget.products.map((product) => {
        const cleanSelected = product.selected;
        delete cleanSelected?.response?.products;
        return ({
          disabledSuppliers: product.disabledSuppliers,
          observation: product.observation,
          productId: product.productId,
          productName: product.productName,
          quantity: product.quantity,
          segments: product.segments,
          unit: product.unit,
          selected: cleanSelected,
        });
      });
      await updateBudgetProducts({
        budgetId: budget.id,
        products: productsToUpdate,
        isChildBudget: user.type === USER_ROLES.franchise,
      });
      if (showAlert) {
        Alert({
          title: t('Fornecedores salvos'),
          text: t('Fornecedores salvos com sucesso.'),
          type: 'success',
        });
      }
    } catch {
      if (showAlert) {
        Alert({
          title: t('Erro'),
          text: t('Erro ao salvar fornecedores.'),
          type: 'error',
        });
      }
    }
  };

  const handleFinish = () => {
    Alert({
      title: t('Finalizar Orçamento'),
      text: `${t('Tem certeza que deseja finalizar este orçamento? Os fornecedores não poderão mais responder ao orçamento caso ele seja finalizado.')}`,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: t('Prosseguir'),
      cancelButtonText: t('Cancelar'),
    }).then(async (result) => {
      if (result.value) {
        try {
          await finishBudget(budget);
          setBudget({
            ...budget,
            status: BUDGET_STATUS.FINALIZED,
          });

          Alert({
            title: t('Orçamento finalizado'),
            text: t('Orçamento finalizado com sucesso'),
            type: 'success',
          });
        } catch (e) {
          Alert({
            title: t('Erro'),
            text: t('Erro ao finalizar orçamento'),
            type: 'error',
          });
        }
      }
    });
  };

  const handleCloseAlert = async () => {
    await localStorage.setItem('alertClickChangeSupplier', true);
    setAlertClickChangeSupplier(false);
    history.push(`/${t('orcamentos')}`);
  };

  const handleEdit = async () => {
    if (budget.status === BUDGET_STATUS.FINALIZED) await handleUpdateBudgetProducts(false);
    const isFranchise = user.type === USER_ROLES.franchise;
    history.push({
      pathname: `/${t('orcamentos')}/${t('editar')}/${budget.id}/${t('produtos')}`,
      state: {
        isEditQuantity: true,
        idMainBudget: mainBudgetId,
        mainBudgetRestaurantId: budget.restaurantId,
        idChildBudget: isFranchise && budget.id,
        status: budget.status,
        isChildBudget: isFranchise,
      },
    });
  };

  const handleQuantityChange = async (quantity) => {
    if (!selected || selected?.ref?.quantity === quantity) return;
    try {
      setLoading(true);

      const products = await calculateProducts({
        ...budget,
        products: budget.products.map(product => ({
          ...product,
          quantity: selected.id === product.productId ? quantity : product.quantity,
        })),
      });

      const productsToUpdate = products.map(product => ({
        disabledSuppliers: product.disabledSuppliers,
        observation: product.observation,
        productId: product.productId,
        productName: product.productName,
        quantity: product.quantity,
        segments: product.segments,
        unit: product.unit,
        selected: product.selected,
      }));

      const budgetResponses = budget.responses.map(r => ({
        ...r,
        products: r.products.map(p => ({
          ...p,
          priceTotal: selected.id === p.id
            ? numberToCurrency(numberToFloat(quantity) * numberToFloat(p.unitPrice))
            : p.priceTotal,
        })),
      }));

      await Promise.all([
        updateBudgetProducts({
          budgetId: budget.id,
          products: productsToUpdate,
          isChildBudget: user.type === USER_ROLES.franchise,
        }),
        ...budgetResponses.map(async r => updateBudgetResponse({ ...r })),
      ]);

      await loadData();

      Alert({
        title: t('Quantidade alterada'),
        text: t('Quantidade do produto alterada com sucesso.'),
        type: 'success',
      });
    } finally {
      setLoading(false);
      if (onQuantityChangeFinish) onQuantityChangeFinish();
    }
  };

  if (loading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  const Info = () => <CustomInfo data={infoData} />;

  const alertMinOrderSupplier = () => (
    <AlertMinOrderSupplier
      t={t}
      classes={classes}
      minOrder={minOrder}
      user={user}
      childHasDeficit={childHasDeficit}
    />
  );

  const AlertSupplierBrand = () => (
    <>
      {productData?.some(product => !!product?.quotation?.brand) && (
        <AlertMUI
          className={classes.alertWarning}
          icon={false}
          variant="filled"
          severity="warning"
        >
          {t('Alguns fornecedores cotaram produtos de marcas/gramaturas diferentes das solicitadas.')}
        </AlertMUI>
      )}
    </>
  );

  const AlertChangeSupplier = () => (
    <Collapse in={alertClickChangeSupplier}>
      <AlertMUI
        className={classes.alertMui}
        icon={false}
        variant="filled"
        severity="info"
        action={(
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleCloseAlert}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
      )}
      >
        {t('Clique no produto para alterar o fornecedor')}
      </AlertMUI>
    </Collapse>
  );

  const ProductsTable = () => (
    <CustomTable
      tableCells={tableBudgetResponseProducts}
      data={productData}
      sortData={(order, orderBy, values) => {
        if (order) {
          const formattedValues = values.map(value => ({
            ...value,
            diffPriceValue: numberToFloat(value.diffPrice),
          }));

          const fields = {
            supplier: 'supplierValue',
            price: 'priceTotalValue',
            diffPrice: 'diffPriceValue',
          };

          return orderData(formattedValues, [fields[orderBy] || orderBy], [order]);
        }
        return values;
      }}
      pagination={false}
      onClickRow={item => (item.quotation ? setSelected(item) : null)}
      isVertical
      numberedItems
    />
  );

  const Summary = () => (
    <CustomTable
      tableCells={tableBudgetResponseTotal}
      data={[total]}
      pagination={false}
      isVertical
    />
  );

  return (
    <div className={classes.root}>
      <CustomForm
        buttonOkLabel="Enviar"
        fields={formReporter(
          Info,
          Summary,
          AlertChangeSupplier,
          alertMinOrderSupplier,
          AlertSupplierBrand,
          ProductsTable,
        )}
        buttonCancel={false}
        buttonOk={false}
      />
      {user.type !== USER_ROLES.admin && (
        <div className={classes.buttonContainer}>
          {(budget.status === BUDGET_STATUS.FINALIZED
            || mainBudgetStatus === BUDGET_STATUS.FINALIZED)
            && (
              <Button
                color="secondary"
                variant="contained"
                className={classes.button}
                onClick={() => {
                  setCreateOrder(true);
                }}
              >
                {t('Gerar Pedido')}
              </Button>
            )}

          {(budget.status === BUDGET_STATUS.FINALIZED
            || mainBudgetStatus === BUDGET_STATUS.FINALIZED)
            && (
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={() => handleUpdateBudgetProducts()}
              >
                {t('Salvar')}
              </Button>
            )}

          {(budget.status === BUDGET_STATUS.OPEN
            || budget.status === BUDGET_STATUS.FINALIZED
            || mainBudgetStatus === BUDGET_STATUS.FINALIZED)
            && user.type !== USER_ROLES.master
            && (
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={handleEdit}
              >
                {t('Editar')}
              </Button>
            )}

          {budget.status === BUDGET_STATUS.OPEN && (
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={handleFinish}
            >
              {t('Finalizar cotação')}
            </Button>
          )}
        </div>
      )}

      <ChangeSupplierModal
        t={t}
        classes={classes}
        modal={!!selected}
        product={selected}
        closeModal={() => setSelected(false)}
        handleSelectProduct={handleSelectProduct}
        userPermission={userPermission}
        budgetStatus={budget.status}
        user={user}
        handleQuantityChange={handleQuantityChange}
        minOrder={minOrder?.reduce(
          (acc, supplier) => ({
            ...acc,
            [supplier.id]: supplier.amount,
          }),
          {},
        )}
      />

      <OrderBudgetReportCreateOrderModal
        t={t}
        classes={classes}
        modal={createOrder}
        budget={budget}
        childBudgets={childBudgets}
        closeModal={() => setCreateOrder(false)}
        restaurant={restaurant}
      />
    </div>
  );
};

export default flow(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(OrderBudgetReportGeneral);
