import { FirebaseService } from 'admin-base-component-library';
import { saveUser } from '../user';

export const PAYMENT_STATUS = [
  'Aguardando',
  'Pago',
  'Atrasado',
  'Cancelado',
];

export const saveRestaurant = async ({
  name,
  contact,
  cnpj,
  email,
  paymentStatus = PAYMENT_STATUS[0],
  active,
  parent,
  phone,
  address,
}, restaurant) => {
  const data = {
    name,
    contact,
    cnpj,
    email: restaurant?.email || email,
    paymentStatus,
    parent: parent?.value || null,
    suppliers: restaurant?.suppliers || [],
    phone: [{
      main: true,
      title: 'Principal',
      phone,
    }],
    address: [{
      main: true,
      title: 'Principal',
      ...address,
    }],
    user: restaurant.user || null,
    active,
  };

  const hasEmail = email && email !== '';

  if (!restaurant?.user && hasEmail) {
    data.user = await saveUser({
      name,
      email,
      active: true,
      type: 'Restaurante',
    });
  }

  if (
    restaurant?.user
    && hasEmail
    && (restaurant?.name !== name)) {
    await saveUser({
      name,
    }, restaurant.user);
  }

  if (!restaurant?.id) {
    return FirebaseService.create('restaurant', data);
  }

  return FirebaseService.update('restaurant', restaurant.id, data);
};

export const formatRestaurant = restaurant => ({
  ...restaurant,
  name: restaurant.name,
  phone: restaurant?.phone ? restaurant.phone[0]?.phone : '',
  address: restaurant.address ? restaurant.address[0] : {},
  parent: restaurant?.parent || null,
});

export const getRestaurants = async (filterData) => {
  const filters = [];

  if (filterData?.supplier) {
    filters.push({ field: 'suppliers', type: 'array-contains', value: filterData.supplier });
  }

  const restaurants = await FirebaseService.getDataList({
    collectionName: 'restaurant',
    order: 'name',
    orderType: 'asc',
    filters,
  });

  return restaurants.map(restaurant => formatRestaurant(restaurant));
};

export const getFranchisees = async (restaurantId) => {
  const restaurants = await FirebaseService.getDataList({
    collectionName: 'restaurant',
    order: 'name',
    orderType: 'asc',
    filters: [
      { field: 'parent', type: '==', value: restaurantId },
      { field: 'active', type: '==', value: true },
    ],
  });

  return restaurants.map(restaurant => formatRestaurant(restaurant));
};

export const removeRestaurant = async (restaurant) => {
  if (restaurant.user) {
    await saveUser({
      active: false,
    }, restaurant.user);
  }

  return FirebaseService.update('restaurant', restaurant.id, {
    active: false,
    suppliers: [],
  });
};

export const getRestaurantByID = async (id) => {
  const restaurant = await FirebaseService.getInstanceById('restaurant', id);
  if (!restaurant) {
    return null;
  }

  return formatRestaurant(restaurant);
};

export const verifyChild = async (id) => {
  const restaurants = await getRestaurants();

  const hasChild = restaurants.filter((item) => {
    if (item.parent) {
      return item.parent === id;
    }
    return null;
  });

  return hasChild.length >= 1;
};

export const getRestaurantOptions = async (restaurantId = null) => {
  const restaurants = await getRestaurants();

  return restaurants.reduce((options, restaurant) => {
    if (restaurant.active && restaurant.id !== restaurantId) {
      return [...options, { value: restaurant.id, label: restaurant.name }];
    }

    return options;
  }, [{ value: null, label: 'Nenhum' }]);
};

export const getRestaurantParentOptions = async (restaurantId = null) => {
  const restaurants = await getRestaurants();

  return restaurants.reduce((options, restaurant) => {
    if (restaurant.active
      && !restaurant.parent
      && restaurant.id !== restaurantId) {
      return [...options, { value: restaurant.id, label: restaurant.name }];
    }

    return options;
  }, [{ value: null, label: 'Nenhum' }]);
};

export const restaurantLinkSupplier = async (restaurant, supplierId) => FirebaseService.update('restaurant', restaurant.id, {
  suppliers: [
    ...restaurant.suppliers,
    supplierId,
  ],
});

export const restaurantUnLinkSupplier = async (restaurant, supplierId) => FirebaseService.update('restaurant', restaurant.id, {
  suppliers: restaurant.suppliers.filter(id => id !== supplierId),
});
