import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import './styles/index.css';

const CLIENT_ENV = require('./config/env');

const bugSnagDefaultConfig = {
  apiKey: '59cbe0fbafebddc8319f75f758d126a4',
  plugins: [new BugsnagPluginReact()],
  onError: (event) => {
    event.addMetadata('project', {
      name: 'Orca Cotação Digital',
      env: CLIENT_ENV,
      version: '1.0.65',
    });
  },
};

Bugsnag.start(bugSnagDefaultConfig);

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById('root'),
);

serviceWorker.register();
