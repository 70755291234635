import React, { useState, useEffect, useContext } from 'react';
import {
  Tabs,
  Alert,
  Mask,
} from 'admin-base-component-library';
import {
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import flow from 'lodash.flow';
import { withTranslation } from 'react-i18next';

import UserContext from '../../context/UserContext';
import useValidation from '../../hooks/useValidation';

import {
  BUDGET_STATUS,
  createBudget,
  getBudgetById,
  getBudgetByIdWithResponse,
  getChildBudget,
  updateBudget,
  updateBudgetResponse,
  updateChildBudget,
} from '../../services/budget';
import { getRestaurantByID } from '../../services/restaurant';
import { getProductsByCode } from '../../services/product';
import { numberToCurrency, numberToFloat, TimestampToDateWithFormatUS } from '../../services/Service';

import General from './Tabs/General.js';
import Products from './Tabs/Products.js';
import FranchiseesRestaurant from './Tabs/FranchiseesRestaurant.js';

import styles from './styles';

import validation, { saveValidation, saveQuantityValidation, sendBudget } from './validation';
import { USER_ROLES } from '../../services/user';

const ProductEdit = ({
  t,
  match: { params },
  classes,
  history,
}) => {
  const { user } = useContext(UserContext);
  const [restaurant, setRestaurant] = useState();
  const { validate } = useValidation();
  const [products, setProducts] = useState([]);
  const [general, setGeneral] = useState({});
  const [loading, setLoading] = useState(true);
  const [changeRoute, setChangeRoute] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [isParent, setIsParent] = useState(false);
  const [parentRestaurant, setParentRestaurant] = useState();
  const [statusBudget, setStatusBudget] = useState(null);
  const [requestBudgetId, setRequestBudgetId] = useState('');
  const [isChildBudget, setIsChildBudget] = useState(!!history.location?.state?.isChildBudget);
  const [childBudgetId, setChildBudgetId] = useState('');
  const [isEditQuantity, setIsEditQuantity] = useState(false);
  const [isBudgetDuplicate, setIsBudgetDuplicate] = useState(false);
  const [franchiseeRestaurantId, setFranchiseeRestaurantId] = useState('');
  const [productIdsArr, setProductIdsArr] = useState([]);
  const [budgetData, setBudgetData] = useState([]);
  const [mainBudgetId, setMainBudgetId] = useState(null);
  const [mainBudgetRestaurantId, setMainBudgetRestaurantId] = useState(null);
  const [pageType, setPageType] = useState(null);

  const isFranchise = user?.type === USER_ROLES.franchise;

  useEffect(() => {
    if (isBudgetDuplicate && !loading) {
      Alert({
        title: t('Duplicar orçamento'),
        text: t('Você está duplicando este orçamento. Edite as informações desejadas e, ao salvar ou enviar, será gerada uma nova cópia. As alterações não afetam o orçamento original.'),
        type: 'info',
      });
    }
  }, [isBudgetDuplicate, loading]);

  useEffect(() => {
    if (changeRoute === true) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setChangeRoute(false);
      }, 300);
    }
  }, [changeRoute]);

  useEffect(() => {
    const init = async () => {
      try {
        if (params.idDuplicate === 'produtos') {
          return;
        }
        await localStorage.removeItem('budgetId');
        const budgetStatus = history?.location?.state?.status;
        setRequestBudgetId(history?.location?.state?.requestBudgetId);
        setFranchiseeRestaurantId(history?.location?.state?.restaurantId);
        setRestaurant(await getRestaurantByID(user.restaurant.id));
        setChildBudgetId(history?.location?.state?.idChildBudget);
        if (user.restaurant.parent) {
          setParentRestaurant(await getRestaurantByID(user.restaurant.parent));
        }

        if (history?.location?.state?.isEditQuantity) {
          setIsEditQuantity(true);
        }

        if (!!history?.location?.state?.isDuplicate) {
          setIsBudgetDuplicate(true);
        }

        if (budgetStatus) {
          setStatusBudget(budgetStatus);
        }

        if (history.location?.state?.type) {
          setPageType(history.location?.state?.type);
        }

        if (history.location?.state?.idMainBudget
          && history.location?.state?.mainBudgetRestaurantId) {
          const fetchBudgetById = await getBudgetByIdWithResponse(
              history.location?.state?.idMainBudget,
              history.location?.state?.mainBudgetRestaurantId,
              isFranchise,
          );

          const productIds = [];
          fetchBudgetById.responses.forEach(i => i.products.forEach(p => productIds
            .push(
              {
                productId: p.id,
                unitPrice: p.unitPrice,
                observation: p.observation,
                idSupplier: i.idSupplier,
              },
            )));

          if (!isFranchise) {
            setStatusBudget(fetchBudgetById.budget.status);
          }

          setMainBudgetId(history.location?.state?.idMainBudget);
          setMainBudgetRestaurantId(history.location?.state?.mainBudgetRestaurantId);
          setProductIdsArr(productIds);
          setBudgetData(fetchBudgetById);
        }

        setIsParent(user?.type === USER_ROLES.master);

        if (params.idDuplicate) {
          setLoading(true);
          const {
            name,
            deadline,
            endsAt,
            deliveryTime,
            observation,
            products: currentProducts,
          } = await getBudgetById({
            budgetId: params.idDuplicate,
            isFranchise: user?.type === USER_ROLES.franchise,
            budgetStatus,
            isParent: user?.type === USER_ROLES.master,
            isChildBudget: isChildBudget || history?.location?.state?.isChildBudget,
          });

          if (isFranchise && !budgetStatus) {
            const {
              name: parentBudgetName,
              deadline: parentBudgetDeadline,
              endsAt: parentBudgetEndsAt,
              deliveryTime: parentBudgetDeliveryTime,
              observation: parentBudgetObservation,
            } = await getBudgetById({
              budgetId: history?.location?.state?.requestBudgetId,
              budgetStatus,
            });
            setGeneral({
              name: parentBudgetName,
              deadline: parentBudgetDeadline,
              endsAt: parentBudgetEndsAt ? TimestampToDateWithFormatUS(parentBudgetEndsAt) : null,
              deliveryTime: parentBudgetDeliveryTime,
              observation: parentBudgetObservation,
            });
          } else {
            setGeneral({
              name,
              deadline,
              endsAt: endsAt ? TimestampToDateWithFormatUS(endsAt) : null,
              deliveryTime,
              observation,
            });
          }

          const fetchProducts = currentProducts ? await getProductsByCode(
            currentProducts?.map(({ productId }) => productId),
          ) : [];

          const formatProducts = fetchProducts.reduce((allProducts, product) => ({
            ...allProducts,
            [product.id]: product,
          }), {});

          if (currentProducts) {
            setProducts(currentProducts.map(current => ({
              ...current,
              name: formatProducts[current.productId]?.name || current.name,
              segments: formatProducts[current.productId]?.segments || current.segments,
              unit: formatProducts[current.productId]?.unit || current.unit,
            })));
          }
        }
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };

    init();
  }, [params.idDuplicate]);

  const baseUrl = params.idDuplicate && params.idDuplicate !== t('produtos')
    ? `/${t('orcamentos')}/${t(params.type)}/${params.idDuplicate}`
    : `/${t('orcamentos')}/${t(params.type)}`;

  const addProduct = (currentProduct) => {
    const checkProducts = products.filter(product => (
      product.productId === currentProduct.product.value
    ));
    if (checkProducts.length > 0) {
      Alert({
        title: t('Produto Duplicado'),
        text: t('O produto que você está tentando adicionar já está na lista.'),
        type: 'error',
      });
      return false;
    }
    setSelectedProduct();
    setProducts([
      ...products,
      {
        productId: currentProduct.product.value,
        productName: currentProduct.product.label,
        quantity: currentProduct.quantity,
        unit: currentProduct.unit || currentProduct.product.unit,
        observation: currentProduct.observation,
        segments: currentProduct.product.segments || [],
      },
    ]);
    return true;
  };

  const updateProduct = (value, row, field) => {
    const newProducts = [...products];
    const newRow = { ...row };
    newRow[field] = field !== 'quantity' ? value : Mask.maskCurrencyField(value);
    const index = newProducts.indexOf(row);
    newProducts[index] = newRow;
    setProducts(newProducts);
  };

  const handleCancel = async () => {
    if (history.action === 'POP' || !isChildBudget) {
      const statusGoBackToBudget = statusBudget === BUDGET_STATUS.OPEN
        || statusBudget === BUDGET_STATUS.FINALIZED;

      return history.push(
        budgetData.budget?.id && params.type === t('editar') && statusGoBackToBudget && user.type !== USER_ROLES.franchise
          ? `/${t('orcamento-resposta')}/${budgetData.budget.id}`
          : `/${t('orcamentos')}`,
      );
    }

    setIsChildBudget(false);

    if (pageType === t('editar') && (!isChildBudget || budgetData.budget.status === BUDGET_STATUS.REQUESTED)) {
      return history.push(`/${t('orcamentos')}/${t('editar')}/${budgetData.budget.id}/${t('restaurantes-associados')}`);
    }

    if (!!childBudgetId && !isParent) {
      return history.push({
        pathname: `/${t('orcamento-resposta')}/${childBudgetId}`,
        state: {
          isParent,
          restaurantId: user.restaurant.id,
          status: budgetData.budget.status,
          idMainBudget: mainBudgetId || history.location?.state?.idMainBudget,
          franchiseesRestaurantView: true,
          type: params?.type,
        },
      });
    }

    return history.push(`/${t('orcamento-resposta')}/${budgetData.budget.id}/${t('restaurantes-associados')}`);
  };

  const removeProduct = item => Alert({
    title: t('Cuidado!'),
    text: `
      ${t('Esta ação não pode ser desfeita.')}
      ${t('Tem certeza que deseja excluir')}
      ${t('este produto')}?
      `,
    type: 'warning',
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonText: t('Excluir'),
    cancelButtonText: t('Cancelar'),
  }).then((result) => {
    if (result.value) {
      setProducts(products.filter(({ productId }) => item.productId !== productId));
    }
  });

  const handleSend = async ({ childProducts }) => {
    let values = {
      restaurantId: user?.restaurant?.id,
      restaurantSuppliers: user?.restaurant?.suppliers,
      ...general,
      products: childProducts || products,
    };

    if (isFranchise) {
      values = {
        restaurantId: user?.restaurant?.id,
        restaurantSuppliers: parentRestaurant?.suppliers,
        idMainBudget: requestBudgetId || params?.idDuplicate,
        ...general,
        products,
      };
    }

    validate(childProducts ? sendBudget() : validation(), values, () => {
      Alert({
        title: t('Cuidado!'),
        text: isFranchise
          ? `${t('Você tem certeza que finalizou o orçamento e gostaria de enviá-lo?')}`
          : `${t('Você tem certeza que finalizou o orçamento e gostaria de enviar aos fornecedores?')}`,
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: t('Prosseguir'),
        cancelButtonText: t('Cancelar'),
      }).then(async (result) => {
        let title = t('Orçamento Enviado');
        let text = t('Orçamento enviado com sucesso.');
        let type = 'success';

        if (!result.value) {
          return;
        }

        if (params.type === t('editar') && !isFranchise) {
          const { noSuppliers } = await updateBudget({
            ...values,
            status: BUDGET_STATUS.OPEN,
            budgetId: params.idDuplicate,
          }, statusBudget === BUDGET_STATUS.SAVED);

          if (noSuppliers.length > 0) {
            title = t('Atenção');
            text = noSuppliers.length === 1
              ? t('O seu pedido de orçamento foi enviado com sucesso! Obs - O produto {{productName}} não tem fornecedores vinculados.', { productName: noSuppliers[0] })
              : t('O seu pedido de orçamento foi enviado com sucesso! Obs - Os produtos {{productName}} não tem fornecedores vinculados.', { productName: noSuppliers.join(', ') });
            type = 'warning';
          }

          Alert({ title, text, type });

          history.push(`/${t('orcamentos')}`);
          return;
        }

        if (params.type === t('editar') && isFranchise && statusBudget !== BUDGET_STATUS.REQUESTED) {
          await updateChildBudget({
            ...values,
            status: BUDGET_STATUS.SENT,
            budgetId: params.idDuplicate,
            sentAt: new Date(),
          });

          Alert({ title, text, type });

          history.push(`/${t('orcamentos')}`);
          return;
        }

        const { noSuppliers } = await createBudget({
          ...values,
          status: isFranchise ? BUDGET_STATUS.SENT : BUDGET_STATUS.OPEN,
        });

        if (noSuppliers.length > 0 && !isFranchise) {
          title = t('Atenção');
          text = noSuppliers.length === 1
            ? t('O seu pedido de orçamento foi enviado com sucesso! Obs - O produto {{productName}} não tem fornecedores vinculados.', { productName: noSuppliers[0] })
            : t('O seu pedido de orçamento foi enviado com sucesso! Obs - Os produtos {{productName}} não tem fornecedores vinculados.', { productName: noSuppliers.join(', ') });
          type = 'warning';
        }

        Alert({ title, text, type });

        history.push(`/${t('orcamentos')}`);
      });
    });
  };

  const handleSave = async () => {
    let values = {
      restaurantId: franchiseeRestaurantId || user.restaurant.id,
      restaurantSuppliers: user.restaurant.suppliers,
      ...general,
      products,
    };

    if (isFranchise) {
      values = {
        restaurantId: user.restaurant.id,
        restaurantSuppliers: parentRestaurant.suppliers,
        idMainBudget: requestBudgetId || params.idDuplicate,
        ...general,
        products,
      };
    }

    if (
      (params.type === t('editar') && isFranchise && statusBudget !== BUDGET_STATUS.REQUESTED)
      || (isParent && isChildBudget)
      || ((params.type === t('editar') && isEditQuantity))
    ) {
      for (let i = 0; i < products?.length; i += 1) {
        if (products[i].quantity === '') {
          Alert({
            title: t('Erro!'),
            text: 'É necessário adicionar a quantidade para todos os produtos',
            type: 'error',
            showCloseButton: true,
          });
          return;
        }
        values = {
          restaurantId: franchiseeRestaurantId || user.restaurant.id,
          restaurantSuppliers: isFranchise ? parentRestaurant.suppliers : user.restaurant.suppliers,
          ...general,
          endsAt: new Date(),
          products,
        };
      }
    }

    validate(isEditQuantity ? saveQuantityValidation() : saveValidation(), values, () => {
      Alert({
        title: t('Cuidado!'),
        text: isParent && !isChildBudget
          ? `${t('Você tem certeza que finalizou a solicitação de produtos e gostaria de enviar aos restaurantes franqueados?')}`
          : `${t('Você tem certeza que finalizou o orçamento e gostaria de salvá-lo?')}`,
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: t('Prosseguir'),
        cancelButtonText: t('Cancelar'),
      }).then(async (result) => {
        if (!result.value) {
          return;
        }

        if (
          (params.type === t('editar') && isFranchise && statusBudget !== BUDGET_STATUS.REQUESTED) || (isParent && isChildBudget)
        ) {
          await updateChildBudget({
            ...values,
            status: isChildBudget ? BUDGET_STATUS.SENT : BUDGET_STATUS.SAVED,
            budgetId: params.idDuplicate,
          });

          const childBudget = await getChildBudget({
            filters: [
              { field: 'idMainBudget', type: '==', value: mainBudgetId },
            ],
          });

          const formattedProducts = childBudget.reduce((acc, current) => {
            current.products.forEach((item) => {
              const findProductIndex = acc
                .findIndex(product => product.productId === item.productId);

              if (findProductIndex > -1) {
                acc[findProductIndex] = {
                  ...acc[findProductIndex],
                  quantity: numberToCurrency(
                    numberToFloat(acc[findProductIndex].quantity) + numberToFloat(item.quantity),
                  ),
                };
                return;
              }
              acc.push({ ...item });
            });
            return [
              ...acc,
            ];
          }, []);

          const prices = [];
          formattedProducts.forEach((i) => {
            productIdsArr.forEach((p) => {
              if (i.productId === p.productId) {
                prices.push({
                  id: i.productId,
                  observation: p.observation,
                  unitPrice: p.unitPrice,
                  priceTotal: numberToCurrency(
                    numberToFloat(i.quantity) * numberToFloat(p.unitPrice)),
                  idSupplier: p.idSupplier,
                });
              }
            });
          });

          const budgetResponses = budgetData.responses.map(r => ({
            ...r,
            products: r.products
              .map(p => prices.reduce((acc, i) => ((r.idSupplier
              === i.idSupplier && i.id === p.id)
                ? { ...p, priceTotal: i.priceTotal } : acc)),
              ),
          }));

          budgetResponses.forEach(async (r) => {
            await updateBudgetResponse({ ...r });
          });

          const title = isParent ? t('Solicitação Editada') : t('Orçamento Editado');
          const text = isParent ? t('Solicitação editada com sucesso.') : t('Orçamento editado com sucesso.');
          const type = 'success';

          Alert({ title, text, type });

          setIsChildBudget(false);

          if (history.action === 'POP') {
            history.push(`/${t('orcamentos')}`);
            return;
          }

          if (pageType === t('editar')
            && (!isChildBudget || budgetData.budget.status === BUDGET_STATUS.REQUESTED)) {
            history.push(`/${t('orcamentos')}/${t('editar')}/${budgetData.budget.id}/${t('restaurantes-associados')}`);
            return;
          }

          if (isChildBudget && childBudgetId) {
            history.push({
              pathname: `/${t('orcamento-resposta')}/${childBudgetId}`,
              state: {
                isParent,
                restaurantId: user.restaurant.id,
                status: budgetData.status,
                idMainBudget: history.location?.state?.idMainBudget,
                franchiseesRestaurantView: true,
                type: params?.type,
              },
            });
            return;
          }

          if (isFranchise) {
            history.push(`/${t('orcamentos')}`);
            return;
          }

          history.push(`/${t('orcamento-resposta')}/${budgetData.budget.id}/${t('restaurantes-associados')}`);
          return;
        }

        if (params.type === t('editar')
          && isEditQuantity
          && user.type === USER_ROLES.restaurant
        ) {
          await updateBudget({
            ...values,
            status: statusBudget,
            budgetId: params.idDuplicate,
          });

          const formattedProducts = [];
          values.products.forEach((item) => {
            const findProductIndex = formattedProducts
              .findIndex(product => product.productId === item.productId);

            if (findProductIndex > -1) {
              formattedProducts[findProductIndex] = {
                ...formattedProducts[findProductIndex],
                quantity: numberToCurrency(
                  numberToFloat(formattedProducts[findProductIndex].quantity)
                    + numberToFloat(item.quantity),
                ),
              };
              return;
            }
            formattedProducts.push({ ...item });
          });

          const prices = [];
          formattedProducts.forEach((i) => {
            productIdsArr.forEach((p) => {
              if (i.productId === p.productId) {
                prices.push({
                  id: i.productId,
                  observation: p.observation,
                  unitPrice: p.unitPrice,
                  priceTotal: numberToCurrency(
                    numberToFloat(i.quantity) * numberToFloat(p.unitPrice)),
                  idSupplier: p.idSupplier,
                });
              }
            });
          });

          const budgetResponses = budgetData.responses.map(r => ({
            ...r,
            products: r.products
              .map(p => prices.reduce((acc, i) => ((r.idSupplier
              === i.idSupplier && i.id === p.id)
                ? { ...p, priceTotal: i.priceTotal } : acc)),
              ),
          }));

          budgetResponses.forEach(async (r) => {
            await updateBudgetResponse({ ...r });
          });

          const title = isParent ? t('Solicitação Editada') : t('Orçamento Editado');
          const text = isParent ? t('Solicitação editada com sucesso.') : t('Orçamento editado com sucesso.');
          const type = 'success';

          Alert({ title, text, type });

          setIsChildBudget(false);

          if (history.action === 'POP') {
            history.push(`/${t('orcamentos')}`);
            return;
          }
          history.push({
            pathname: `/${t('orcamento-resposta')}/${budgetData.budget.id}`,
            state: {
              ...history.location.state,
            },
          });
          return;
        }

        if (params.type === t('editar') && !isFranchise) {
          await updateBudget({
            ...values,
            status: isParent ? BUDGET_STATUS.REQUESTED : BUDGET_STATUS.SAVED,
            budgetId: params.idDuplicate,
          });

          const title = isParent ? t('Solicitação Editada') : t('Orçamento Editado');
          const text = isParent ? t('Solicitação editada com sucesso.') : t('Orçamento editado com sucesso.');
          const type = 'success';

          Alert({ title, text, type });

          history.push(`/${t('orcamentos')}`);
          return;
        }

        await createBudget({
          ...values,
          status: isParent ? BUDGET_STATUS.REQUESTED : BUDGET_STATUS.SAVED,
        });

        const title = isParent ? t('Solicitação Adicionada') : t('Orçamento Adicionado');
        const text = isParent ? t('Solicitação de produtos salvo com sucesso.') : t('Orçamento salvo com sucesso.');
        const type = 'success';

        Alert({ title, text, type });

        history.push(`/${t('orcamentos')}`);
      });
    });
  };

  const handleProducts = async () => {
    const values = {
      restaurantId: user.restaurant.id,
      restaurantSuppliers: user.restaurant.suppliers,
      ...general,
      products,
    };
    validate(saveValidation(), values, async () => {
      if (params.type === t('editar')) {
        const { budgetId } = await updateBudget({
          ...values,
          status: BUDGET_STATUS.SAVED,
          budgetId: params.idDuplicate,
        });

        await localStorage.setItem('budgetId', JSON.stringify(budgetId));

        if (selectedProduct) {
          history.push(`/${t('produtos')}/${t('editar')}/${selectedProduct?.value}`);
          return;
        }

        history.push(`/${t('produtos')}/${t('novo')}`);
        return;
      }

      const { budgetId } = await createBudget({ ...values, status: BUDGET_STATUS.SAVED });

      await localStorage.setItem('budgetId', JSON.stringify(budgetId));

      if (selectedProduct) {
        history.push(`/${t('produtos')}/${t('editar')}/${selectedProduct?.value}`);
        return;
      }

      history.push(`/${t('produtos')}/${t('novo')}`);
    });
  };

  const gotoProductsTab = async () => {
    if (isParent && !isChildBudget) {
      await handleSave();
      return;
    }
    setChangeRoute(true);
    history.push(`${baseUrl}/${t('produtos')}`);
  };

  if (loading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return !user ? null : (
    <Tabs
      components={[
        {
          name: 'Geral',
          route: baseUrl,
          screen: (
            <General
              t={t}
              params={params}
              classes={classes}
              history={history}
              handleCancel={handleCancel}
              general={general}
              setGeneral={setGeneral}
              user={user}
              restaurant={restaurant}
              handleSave={gotoProductsTab}
              isParent={isParent}
              isFranchise={user.type === USER_ROLES.franchise}
              isChildBudget={isChildBudget}
              handleSend={handleSend}
              isEditQuantity={isEditQuantity}
              statusBudget={statusBudget}
            />
          ),
        },
        (params.type === 'novo' || !isParent) || (isParent && isChildBudget) ? {
          name: 'Produtos',
          route: `${baseUrl}/${t('produtos')}`,
          hidden: isParent && !isChildBudget,
          screen: (
            <Products
              user={user}
              t={t}
              params={params}
              classes={classes}
              history={history}
              products={products}
              addProduct={addProduct}
              removeProduct={removeProduct}
              updateProduct={updateProduct}
              handleCancel={handleCancel}
              handleSave={handleSave}
              handleSend={handleSend}
              restaurant={restaurant}
              handleProducts={handleProducts}
              setSelectedProduct={setSelectedProduct}
              isFranchise={user.type === USER_ROLES.franchise}
              isParent={isParent}
              isChildBudget={isChildBudget}
              parentRestaurant={parentRestaurant}
              isEditQuantity={isEditQuantity}
              statusBudget={statusBudget}
            />
          ),
        } : {
          name: 'Restaurantes Associados',
          route: `${baseUrl}/${t('restaurantes-associados')}`,
          hidden: !(!!isParent),
          screen: (
            <FranchiseesRestaurant
              t={t}
              params={params}
              classes={classes}
              history={history}
              isParent={isParent}
              setIsChildBudget={setIsChildBudget}
              setLoading={setLoading}
              user={user}
              mainBudgetRestaurantId={mainBudgetRestaurantId}
            />
          ),
        },
      ]}
    />
  );
};

export default flow(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(ProductEdit);
