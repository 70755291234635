import CLIENT_ENV from './env';

const envs = {
  development: {
    baseURL: 'http://localhost:3000/',
    timeout: 3200,
    mail: {
      email: 'contato@coderockr.com',
      name: 'Orcamentos Restaurante',
      sendGridToken: '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',
      templateId: 'd-287ad3022ea34645b62088b8ae11ac2c',
    },
    firebaseEnv: {
      apiKey: 'AIzaSyB964VWZPykWZxYZeLeFDjURI3-tN5HkUY',
      authDomain: 'restaurantes-admin-homolog.firebaseapp.com',
      databaseURL: 'https://restaurantes-admin-homolog.firebaseio.com',
      projectId: 'restaurantes-admin-homolog',
      storageBucket: 'restaurantes-admin-homolog.appspot.com',
      messagingSenderId: '256662683659',
      appId: '1:256662683659:web:93abab976be8f3a1a5c70e',
    },
  },
  homolog: {
    baseURL: 'https://restaurantes-admin-homolog.web.app/',
    timeout: 3200,
    mail: {
      email: 'contato@coderockr.com',
      name: 'Orcamentos Restaurante',
      sendGridToken: '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',
      templateId: 'd-287ad3022ea34645b62088b8ae11ac2c',
    },
    firebaseEnv: {
      apiKey: 'AIzaSyB964VWZPykWZxYZeLeFDjURI3-tN5HkUY',
      authDomain: 'restaurantes-admin-homolog.firebaseapp.com',
      databaseURL: 'https://restaurantes-admin-homolog.firebaseio.com',
      projectId: 'restaurantes-admin-homolog',
      storageBucket: 'restaurantes-admin-homolog.appspot.com',
      messagingSenderId: '256662683659',
      appId: '1:256662683659:web:93abab976be8f3a1a5c70e',
    },
  },
  production: {
    baseURL: 'https://app.orcadigital.com.br/',
    timeout: 3200,
    mail: {
      email: 'contato@orcadigital.com.br',
      name: 'Orcamentos Restaurante',
      sendGridToken: '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',
      templateId: 'd-287ad3022ea34645b62088b8ae11ac2c',
    },
    firebaseEnv: {
      apiKey: 'AIzaSyBpYDTP2t-CEEfHTIab4N0VW794csjHtmk',
      authDomain: 'restaurantes-admin.firebaseapp.com',
      databaseURL: 'https://restaurantes-admin.firebaseio.com',
      projectId: 'restaurantes-admin',
      storageBucket: 'restaurantes-admin.appspot.com',
      messagingSenderId: '837706645595',
      appId: '1:837706645595:web:574665b340a37cc029bf7e',
      measurementId: 'G-KB2Z4LHL02',
    },
  },
};

export default {
  env: envs[CLIENT_ENV],
  project: {
    name: 'Orçamento de restaurante',
  },
  login: {
    userAndPassword: true,
    socialMedia: {},
  },
};
