import { USER_ROLES } from '../../services/user';
import { usePermission } from '../../hooks/useUserPermission';

const permissions = {
  [USER_ROLES.admin]: {
    restaurantCount: { tableHidden: false },
    general: {
      tableActions: ['view', 'edit', 'remove'],
      getAllSuppliers: true,
      newSupplier: true,
    },
  },
  [USER_ROLES.restaurant]: {
    restaurantCount: { tableHidden: true },
    general: {
      tableActions: ['view'],
      newSupplier: false,
      getAllSuppliers: false,
    },
  },
  [USER_ROLES.franchise]: {
    restaurantCount: { tableHidden: true },
    general: {
      tableActions: ['view'],
      newSupplier: false,
      getAllSuppliers: false,
    },
  },
  [USER_ROLES.master]: {
    restaurantCount: { tableHidden: true },
    general: {
      tableActions: ['view'],
      newSupplier: false,
      getAllSuppliers: false,
    },
  },
};

const useUserPermission = () => usePermission(permissions);

export {
  useUserPermission,
};
