import React from 'react';
import { CustomForm, Alert } from 'admin-base-component-library';
import { saveRestaurant } from '../../../services/restaurant';
import { defaultValues, form } from '../form';
import { getAllRestaurantProducts, removeProduct, saveProduct } from '../../../services/product';

const General = ({
  t,
  params,
  history,
  classes,
  restaurant,
  hasChild,
  setRestaurant,
  restaurantsOptions,
}) => {
  const updateRestaurant = async (values) => {
    await saveRestaurant(values, restaurant);
    setRestaurant({
      ...restaurant,
      ...values,
    });
  };

  const handleSave = async (values) => {
    const doExcludeProductsList = values.excludeProductsList?.includes(true);

    if (values.productsFromRestaurant?.value) {
      Alert({
        title: t('Importar produtos'),
        text: `${t(`A lista atual de produtos será ${doExcludeProductsList ? 'excluída' : 'mantida'} e serão copiados todos os produtos do restaurante ${values.productsFromRestaurant.label}. Deseja continuar?`)}`,
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: t('Prosseguir'),
        cancelButtonText: t('Cancelar'),
      }).then(async (result) => {
        if (result.value) {
          try {
            await updateRestaurant(values);

            const currentRestaurantProducts = await getAllRestaurantProducts(
              restaurant.id,
            );

            const productsToCopy = await getAllRestaurantProducts(
              values.productsFromRestaurant.value,
            );

            const productsToExclude = doExcludeProductsList
              ? currentRestaurantProducts.map(product => removeProduct(product.id))
              : [];

            const uniqueProducts = doExcludeProductsList
              ? productsToCopy
              : productsToCopy.filter(
                product => !currentRestaurantProducts.some(
                  existentProduct => existentProduct.name === product.name,
                ));

            const products = uniqueProducts.map(product => saveProduct({
              name: product.name,
              unit: product.unit || '',
              segments: product.segments,
              restaurantId: restaurant.id,
            }, {}));

            Promise.all([...productsToExclude, ...products]);

            Alert({
              title: t('Restaurante Atualizado'),
              text: t('Restaurante e produtos salvos com sucesso.'),
              type: 'success',
            });
            history.push(`/${t('restaurantes')}`);
          } catch (e) {
            Alert({
              title: t('Erro'),
              text: t('Erro ao salvar restaurante e produtos.'),
              type: 'error',
            });
          }
        }
      });
      return;
    }

    if (doExcludeProductsList) {
      Alert({
        title: t('Excluir lista de produtos'),
        text: `${t('A lista de produtos desse restaurante será excluída. Deseja continuar?')}`,
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: t('Prosseguir'),
        cancelButtonText: t('Cancelar'),
      }).then(async (result) => {
        if (result.value) {
          try {
            await updateRestaurant(values);

            const currentRestaurantProducts = await getAllRestaurantProducts(
              restaurant.id,
            );

            const productsToExclude = currentRestaurantProducts.map(
              product => removeProduct(product.id),
            );

            Promise.all(productsToExclude);

            Alert({
              title: t('Restaurante Atualizado'),
              text: t('Lista de produtos excluída com sucesso.'),
              type: 'success',
            });
            history.push(`/${t('restaurantes')}`);
          } catch (e) {
            Alert({
              title: t('Erro'),
              text: t('Erro ao salvar restaurante e excluir produtos.'),
              type: 'error',
            });
          }
        }
      });
      return;
    }

    await updateRestaurant(values);
    Alert({
      title: t('Restaurante Atualizado'),
      text: t('Restaurante salvo com sucesso.'),
      type: 'success',
    });
    history.push(`/${t('restaurantes')}`);
  };

  const handleCancel = () => {
    history.push(`/${t('restaurantes')}`);
  };

  return (
    <div className={classes.root}>
      <CustomForm
        fields={form(restaurantsOptions, params.type, false, hasChild, restaurant.parent.value)}
        disabled={params.type !== t('editar')}
        confirmEdit={handleSave}
        cancelEdit={handleCancel}
        defaultValues={{
          ...defaultValues,
          ...restaurant,
        }}
      />
    </div>
  );
};

export default General;
