import React, { useState } from 'react';
import orderData from 'lodash.orderby';
import { withTranslation } from 'react-i18next';
import {
  Screen,
  CustomTable,
  CustomForm,
} from 'admin-base-component-library';
import { parse } from 'query-string/index';
import { table } from './table';
import { form } from './form';

import {
  getFranchisees,
} from '../../services/restaurant';


const Franchisee = ({ t, history: { location } }) => {
  const params = parse(location.search);
  const [data, setData] = useState(null);

  const fetchData = async (reloading = false) => {
    const userLogged = await JSON.parse(localStorage.getItem('userAdmin'));
    let franchisees = data;
    if (!data || reloading === true) {
      franchisees = await getFranchisees(userLogged?.restaurant.id);
      setData(franchisees);
    }
    return franchisees || [];
  };

  const Form = props => (
    <CustomForm
      {...props}
      fields={form}
      disabled={params.type === t('visualizar')}
    />
  );

  const Table = props => (
    <CustomTable
      {...props}
      sortData={(order, orderBy, values) => {
        if (order) {
          return orderData(values, [orderBy], [order]);
        }
        return values;
      }}
      fixedColumn="end"
      tableCells={table(params.type === t('visualizar'))}
      maxHeight="80vh"
      dense
      isVertical
      numberedItems
    />
  );

  return (
    <Screen
      isAdded={false}
      title="Franqueado"
      formComponent={Form}
      mainComponent={Table}
      fetchData={fetchData}
      pagination={false}
    />
  );
};

export default withTranslation()(Franchisee);
