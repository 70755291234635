import React, { useState, useEffect, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FirebaseService, Alert } from 'admin-base-component-library';
import * as firebase from 'firebase';
import isEqual from 'lodash.isequal';
import avatar from '../assets/images/avatar.png';
import { getFranchisees } from '../services/restaurant';

const AppContext = createContext({
  logged: false,
  user: null,
  authUser: null,
  setUser: () => null,
  login: () => null,
  logout: () => null,
  recoverPassword: () => null,
  getProfile: () => null,
});

export const LoginProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authUser, setAuthUser] = useState(firebase.auth().currentUser);
  const history = useHistory();

  const profile = async (force) => {
    const userLogged = await localStorage.getItem('userAdmin');
    if (userLogged || force) {
      setUser(JSON.parse(userLogged));
      return;
    }

    setUser(false);
  };

  const getUser = async (userId) => {
    const userDoc = await FirebaseService.getInstanceById('user', userId);
    if (userDoc.error || !userDoc.active) {
      const title = 'Não foi possível logar';
      const text = 'Usuário não existe ou está desativado.';
      return { ...userDoc, error: { title, text } };
    }
    return userDoc;
  };

  const loginDone = async (data, callback) => {
    if (data.error) {
      const errors = [
        'auth/user-mismatch',
        'auth/user-not-found',
        'auth/invalid-credential',
        'auth/invalid-email',
        'auth/wrong-password',
      ];
      const title = 'Não foi possível logar';
      let text = 'Ocorreu algum erro inesperado. Tente novamente!';
      if (errors.includes(data.code)) {
        text = 'Verifique suas credenciais e tente novamente!';
      }
      if (data.code === 'auth/too-many-requests') {
        text = 'Houveram muitas tentativas incorretas. Tente novamente mais tarde!';
      }
      callback({ error: { title, text } });
      return;
    }
    const userData = await getUser(data.user.uid);
    if (userData.error) {
      const { title, text } = userData.error;
      callback({ error: { title, text } });
      return;
    }

    const collectionsExtraData = [
      { type: 'Restaurante', collection: 'restaurant' },
      { type: 'Fornecedor', collection: 'supplier' },
    ];

    const extraDataCollectionName = collectionsExtraData
      .find(collection => collection.type === userData.type);

    let extraDataUser = {};
    if (extraDataCollectionName) {
      const fetch = await FirebaseService.getDataList({
        collectionName: extraDataCollectionName.collection,
        filters: [
          { field: 'user', type: '==', value: userData.id },
        ],
      });
      extraDataUser = {
        [extraDataCollectionName.collection]: fetch[0],
      };
    }

    let dataLogin = {
      ...userData,
      ...extraDataUser,
      avatar: userData.profileAvatar || avatar,
    };

    if (!!dataLogin?.restaurant?.parent) {
      dataLogin = {
        ...dataLogin,
        type: 'Franquia',
      };
    }

    if (dataLogin.type === 'Restaurante') {
      const franchisees = await getFranchisees(dataLogin.restaurant.id);

      if (franchisees.length > 0) {
        dataLogin = {
          ...dataLogin,
          type: 'Master',
        };
      }
    }

    await localStorage.setItem('userAdmin', JSON.stringify(dataLogin));
    setUser(dataLogin);

    if (history.location.state?.referrer) {
      history.push(history.location.state?.referrer);
      return;
    }

    history.push('/');
  };

  const recoverPasswordDone = async (data, callback) => {
    const title = 'Recuperação de senha';
    let text = 'Ocorreu algum erro inesperado. Tente novamente!';
    if (data.error) {
      const errors = [
        'auth/user-mismatch',
        'auth/user-not-found',
        'auth/invalid-credential',
        'auth/invalid-email',
        'auth/wrong-password',
      ];
      if (errors.includes(data.code)) {
        text = 'Não foi possível recuperar a senha. Verifique se o email informado está correto e tente novamente!';
      }
      callback({ error: { title, text } });
      return;
    }
    text = 'Você receberá um email com um link para poder recuperar a sua senha.';
    callback({ title, text });
  };

  const state = {
    logged: user,
    authUser,
    user,
    setUser: async (userData) => {
      setUser(userData);
      await localStorage.setItem('userAdmin', JSON.stringify(userData));
    },
    login: async (type, values, callback) => {
      FirebaseService.login(type, values, data => loginDone(data, callback));
    },
    logout: () => {
      setUser(false);
      FirebaseService.logout();
      localStorage.removeItem('userAdmin');
    },
    recoverPassword: async (values, callback) => {
      FirebaseService.recoverPassword(values, data => recoverPasswordDone(data, callback));
    },
  };

  useEffect(() => {
    profile();

    const unsubscribe = firebase.auth().onIdTokenChanged((currentUser) => {
      setAuthUser(currentUser);

      if (!currentUser && history.location.pathname !== '/login') {
        Alert({
          title: 'Falha na Operação',
          text: 'Sessão expirada. Por favor, faça login novamente para continuar.',
          type: 'error',
        }).then(() => history.push('/login'));
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user?.restaurant?.id) {
      firebase.firestore()
        .collection('restaurant')
        .doc(user?.restaurant?.id)
        .onSnapshot(async (snapshot) => {
          const docData = snapshot.data();

          let dataLogin = {
            ...user,
            restaurant: {
              ...docData,
              id: snapshot.id,
            },
            type: 'Restaurante',
          };

          if (docData.parent) {
            dataLogin = {
              ...user,
              restaurant: {
                ...docData,
                id: snapshot.id,
              },
              type: 'Franquia',
            };
          }

          if (dataLogin.type === 'Restaurante') {
            const franchisees = await getFranchisees(dataLogin.restaurant.id);

            if (franchisees.length > 0) {
              dataLogin = {
                ...dataLogin,
                type: 'Master',
              };
            }
          }

          const storageData = JSON.parse(localStorage.getItem('userAdmin'));

          if (isEqual({ ...dataLogin.restaurant, createdAt: '', updatedAt: '' }, { ...storageData.restaurant, createdAt: '', updatedAt: '' })) {
            return;
          }

          setUser(dataLogin);
          localStorage.setItem('userAdmin', JSON.stringify(dataLogin));
          history.push('/');
        });
    }
  }, [user]);

  return (
    <AppContext.Provider value={state}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
