import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Screen, CustomForm, CustomTable, Alert,
} from 'admin-base-component-library';
import { CircularProgress, withStyles } from '@material-ui/core';
import flow from 'lodash.flow';
import cells from './table';
import {
  filterFields, fields, defaultValues,
} from './form';

import {
  getRestaurantProducts,
  saveProduct,
  getAllProducts,
  removeProduct,
  ITEMS_PER_PAGE,
} from '../../services/product';
import { getSegments } from '../../services/segment';
import { getSuppliers } from '../../services/supplier';
import { getRestaurants, getRestaurantByID } from '../../services/restaurant';
import { USER_ROLES } from '../../services/user';

import styles from './styles';
import UserContext from '../../context/UserContext';
import { useUserPermission } from './permissions';
import ImportProductModal from './components/ImportProductModal';

const Products = ({ t, classes, history }) => {
  const { user } = useContext(UserContext);
  const userPermission = useUserPermission();

  const [segmentsOptions, setSegmentsOptions] = useState([]);
  const [restaurantsOptions, setRestaurantsOptions] = useState([]);
  const [restaurants, setRestaurants] = useState(null);
  const [segments, setSegments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalImportProduct, setModalImportProduct] = useState(false);

  const getInactiveName = name => `(Inativo) - ${name}`;

  useEffect(() => {
    const getInit = async () => {
      await localStorage.removeItem('budgetId');
      const fetchSegments = await getSegments();
      const fetchSuppliers = await getSuppliers();
      const fetchRestaurants = userPermission('general', 'getAllProducts')
        ? await getRestaurants() : [(await getRestaurantByID(user.restaurant.id))];

      const segmentsFormat = {};

      setSegmentsOptions(fetchSegments.map((item) => {
        const option = {
          value: item.id,
          label: item.name,
        };
        segmentsFormat[item.id] = item.name;
        return option;
      }));

      const suppliers = fetchSuppliers.reduce((allSuppliers, current) => ({
        ...allSuppliers,
        [current.id]: current,
      }), {});

      const optionsRestaurants = [];
      setRestaurants(fetchRestaurants.reduce((allRestaurants, current) => {
        const label = current.active === false ? getInactiveName(current.name) : current.name;
        optionsRestaurants.push({ value: current.id, label });
        return {
          ...allRestaurants,
          [current.id]: {
            ...current,
            suppliers: (current.suppliers || []).map(supplier => ({
              ...suppliers[supplier],
              segments: Object.keys(suppliers[supplier].segments || {}),
            })),
          },
        };
      }, {}));
      setRestaurantsOptions(optionsRestaurants);

      setSegments(segmentsFormat);
      setLoading(false);
    };

    if (user?.type) {
      getInit();
    }
  }, [user]);

  const countSuppliers = product => (
    restaurants[product.restaurantId]
      .suppliers
      .filter(currentSupplier => (
        currentSupplier.segments
          .some(segment => product.segments.indexOf(segment) > -1)
        && product.disabledSuppliers.indexOf(currentSupplier.id) === -1
      )).length
  );

  const fetchData = async ({ page, filters, ordering }) => {
    const { data, nextDoc: nextPage } = await (
      userPermission('general', 'getAllProducts')
        ? getAllProducts({ page, filters, ordering })
        : getRestaurantProducts({
          restaurantId: user.restaurant.id, page, filters, ordering,
        }));

    return {
      data: data.map((product) => {
        const productSegments = product.segments.map(segment => segments[segment]);
        const segmentsSummary = [...productSegments].slice(0, 3).join(', ');
        const restaurantActive = restaurants[product.restaurantId].active;
        const restaurantName = restaurants[product.restaurantId].name;

        return {
          ...product,
          restaurant: restaurantActive === false ? getInactiveName(restaurantName) : restaurantName,
          segmentsSummary: productSegments.length <= 3
            ? segmentsSummary : `${segmentsSummary} (+${productSegments.length - 3})`,
          segmentsTooltip: productSegments.slice(3, productSegments.length).join(', '),
          suppliersCount: countSuppliers(product),
        };
      },
      ),
      nextPage,
    };
  };

  const Table = props => (
    <CustomTable
      tableCells={cells(history, t, userPermission)}
      infiniteScroll
      maxHeight="70vh"
      dense
      isVertical
      numberedItems
      {...props}
    />
  );

  const Form = props => (
    <CustomForm
      {...props}
      customButtons={[{
        action: ({ resetForm, values }) => {
          const { name, unit, restaurant } = values;
          const hasRestaurant = userPermission('restaurant', 'hidden') || !!restaurant;
          if (!!name && !!unit && hasRestaurant) {
            handleSave(values, null, false);
            resetForm();
          }
        },
        disable: true,
        label: 'Salvar & Registar novo',
      }]}
      defaultValues={defaultValues}
      fields={fields(restaurantsOptions, segmentsOptions, userPermission)}
    />
  );

  const FormFilter = props => (
    <CustomForm
      {...props}
      fields={filterFields(segmentsOptions, restaurantsOptions, userPermission)}
      buttonOkLabel="Filtrar"
      buttonCancel={false}
      filterForm
      inlineFilters
    />
  );

  const handleSave = async (values, product, newProduct = true) => {
    const restaurantId = values.restaurant.value;
    const save = await saveProduct({
      restaurantId: restaurantId || user.restaurant.id,
      ...values,
    }, product);

    Alert({
      title: t('Produto Adicionado'),
      text: t('Produto salvo com sucesso.'),
      type: 'success',
    });

    if (!newProduct) {
      return [];
    }

    history.push(`/${t('produtos')}/${t('editar')}/${save.id}`);
    return [];
  };

  const removeItem = async (product, items) => {
    await removeProduct(product.id);
    const products = items?.data || items;
    return products.filter(item => item.id !== product.id);
  };

  if (loading || segments === null || restaurants === null) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  const getItemData = (product, action) => {
    history.push(`/${t('produtos')}/${t(action)}/${product.id}`);
  };

  return !user ? null : (
    <div>
      <ImportProductModal
        modal={modalImportProduct}
        closeModal={() => setModalImportProduct(false)}
        classes={classes}
        restaurants={restaurantsOptions}
        segments={segments}
      />
      <Screen
        fetchData={fetchData}
        title="Produto"
        removeMessage={`${t('Tem certeza que deseja excluir')} ${t('este produto')}?`}
        mainComponent={Table}
        formComponent={Form}
        filtersComponent={FormFilter}
        handleSave={handleSave}
        removeItem={removeItem}
        getItemData={product => getItemData(product, 'editar')}
        view={product => getItemData(product, 'visualizar')}
        infiniteScroll
        startPage={null}
        infiniteScrollItemsPerPage={ITEMS_PER_PAGE}
        buttons={user?.type === USER_ROLES.admin ? [
          {
            label: `${t('Importar Produtos')}`,
            action: () => {
              setModalImportProduct(true);
            },
          },
        ] : null}
      />
    </div>
  );
};

export default flow(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(Products);
