import React, { useState, useEffect } from 'react';
import orderData from 'lodash.orderby';
import { withTranslation } from 'react-i18next';
import {
  Screen,
  CustomForm,
  CustomTable,
  Collection,
  Alert,
} from 'admin-base-component-library';
import { parse } from 'query-string/index';
import table from './table';
import {
  form, formFilter, defaultValues, formFilterDefaultValues,
} from './form';

import {
  getRestaurants,
  removeRestaurant,
  saveRestaurant,
  restaurantUnLinkSupplier,
  getRestaurantParentOptions,
} from '../../services/restaurant';
import { getAllRestaurantProducts, saveProduct } from '../../services/product';

const Restaurants = ({ t, history, history: { location } }) => {
  const params = parse(location.search);
  const [data, setData] = useState(null);
  const [restaurantsOptions, setRestaurantsOptions] = useState([]);
  const supplierFilterValue = params.name ? `${params.name} (${params.contactName})` : params.contactName;
  const [filter, setFilter] = useState(formFilterDefaultValues(supplierFilterValue));

  useEffect(() => {
    const getInit = async () => {
      setRestaurantsOptions(await getRestaurantParentOptions());
    };
    getInit();
  }, []);

  const fetchData = async (reloading = false) => {
    let restaurants = data;
    if (!data || reloading === true) {
      const allRestaurants = await getRestaurants(params);
      restaurants = allRestaurants.map(restaurant => ({
        ...restaurant,
        neighborhood: restaurant.address.neighborhood,
      }));
      setData(restaurants);
    }

    if (filter?.search !== '') {
      return Collection.findByValueInFields(restaurants, filter.search, filter.field);
    }

    return restaurants || [];
  };

  const confirmEdit = async (values, handleConfirm) => {
    if (values.productsFromRestaurant?.value) {
      Alert({
        title: t('Importar produtos'),
        text: `${t(`Serão copiados todos os produtos do restaurante ${values.productsFromRestaurant.label}. Deseja continuar?`)}`,
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: t('Prosseguir'),
        cancelButtonText: t('Cancelar'),
      }).then(async (result) => {
        if (result.value) {
          handleConfirm(values);
        }
      });
      return;
    }

    if (!values.parent?.value) {
      Alert({
        title: t('Produtos'),
        text: `${t('A lista de produtos desse restaurante começará vazia. Deseja continuar?')}`,
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: t('Prosseguir'),
        cancelButtonText: t('Cancelar'),
      }).then(async (result) => {
        if (result.value) {
          handleConfirm(values);
        }
      });
      return;
    }

    handleConfirm(values);
  };

  const RestaurantForm = ({ confirmEdit: handleConfirm, ...props }) => (
    <CustomForm
      {...props}
      confirmEdit={values => confirmEdit(values, handleConfirm)}
      fields={form(restaurantsOptions, params.type, true)}
      defaultValues={defaultValues}
    />
  );

  const RestaurantFormFilter = props => (
    <CustomForm
      {...props}
      fields={formFilter(params.contactName)}
      defaultValues={filter}
      confirmEdit={values => setFilter(values)}
      buttonOkLabel="Filtrar"
      buttonCancel={false}
      filterForm
      inlineFilters
    />
  );

  const RestaurantTable = props => (
    <CustomTable
      {...props}
      sortData={(order, orderBy, values) => {
        if (order) {
          return orderData(values, [orderBy], [order]);
        }
        return values;
      }}
      fixedColumn="end"
      tableCells={table(removeItem)}
      maxHeight="70vh"
      dense
      isVertical
      numberedItems
    />
  );

  const removeItem = async (restaurant) => {
    if (Object.keys(params).length === 0) {
      const alert = await Alert({
        title: t('Cuidado!'),
        text: t('Tem certeza que deseja inativar este restaurante?'),
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: t('Prosseguir'),
        cancelButtonText: t('Cancelar'),
      });
      if (alert.value) {
        await removeRestaurant(restaurant);

        Alert({
          title: t('Restaurante Inativado'),
          text: t('Restaurante inativado com sucesso.'),
          type: 'success',
        });
        fetchData(true);
      }
      return;
    }

    const alert = await Alert({
      title: t('Cuidado!'),
      text: t('Tem certeza que deseja desvincular este restaurante?'),
      type: 'warning',
      confirmButtonText: t('Prosseguir'),
      cancelButtonText: t('Cancelar'),
    });

    if (alert.value) {
      await restaurantUnLinkSupplier(restaurant, params.supplier);

      Alert({
        title: t('Restaurante Desvinculado'),
        text: t('Restaurante desvinculado com sucesso.'),
        type: 'success',
      });
      fetchData(true);
    }
  };

  const getItemData = (restaurant, action) => {
    history.push(`/${t('restaurantes')}/${t(action)}/${restaurant.id}`);
  };

  const handleSave = async (values, restaurant) => {
    const createdRestaurant = await saveRestaurant(values, restaurant);

    if (values.productsFromRestaurant) {
      const productsToCopy = await getAllRestaurantProducts(
        values.productsFromRestaurant.value,
      );

      const products = productsToCopy.map(product => saveProduct({
        name: product.name,
        unit: product.unit || '',
        segments: product.segments,
        restaurantId: createdRestaurant.id,
      }, {}));

      Promise.all(products);
    }

    setRestaurantsOptions(await getRestaurantParentOptions());

    Alert({
      title: t('Novo Restaurante'),
      text: t('Restaurante criado com sucesso.'),
      type: 'success',
    });
    return fetchData(true);
  };

  return (
    <Screen
      getItemData={restaurant => getItemData(restaurant, 'editar')}
      view={restaurant => getItemData(restaurant, 'visualizar')}
      title="Restaurante"
      removeMessage={`${t('Tem certeza que deseja excluir')} ${t('este restaurante')}?`}
      mainComponent={RestaurantTable}
      formComponent={RestaurantForm}
      fetchData={fetchData}
      filtersComponent={RestaurantFormFilter}
      removeItem={removeItem}
      pagination={false}
      handleSave={handleSave}
      inlineFilters
    />
  );
};

export default withTranslation()(Restaurants);
