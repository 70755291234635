import * as firebase from 'firebase';
import { FirebaseService, Exceptions } from 'admin-base-component-library';

export const USER_ROLES = {
  admin: 'Administrador',
  restaurant: 'Restaurante',
  franchise: 'Franquia',
  supplier: 'Fornecedor',
  master: 'Master',
};

export const getUsers = async page => FirebaseService.getDataList({
  collectionName: 'user',
  orderType: 'desc',
  limit: 20,
  paginate: true,
  startAfterDoc: page,
});

export const getUsersByType = async type => (
  FirebaseService.getDataList({
    collectionName: 'user',
    filters: [
      { field: 'type', type: '==', value: type },
    ],
  },
  ));

export const updateProfile = async ({
  id,
  avatar,
  email,
  loginEmail,
  password,
  newPassword,
  confirmationPassord,
  type,
}) => {
  const user = firebase.auth().currentUser;

  const updateEmailCollection = [
    { type: 'Restaurante', collection: 'restaurant' },
    { type: 'Fornecedor', collection: 'supplier' },
  ];

  if (newPassword && newPassword !== confirmationPassord) {
    throw new Exceptions.RenderException('Senhas não conferem!');
  }

  try {
    await firebase.auth().signInWithEmailAndPassword(loginEmail, password);
  } catch (e) {
    throw new Exceptions.RenderException('Senha inválida!');
  }

  const collectionUpdate = updateEmailCollection.find(collection => collection.type === type);
  if (collectionUpdate) {
    const collection = await FirebaseService.getDataList({
      collectionName: collectionUpdate.collection,
      filters: [
        { field: 'user', type: '==', value: id },
      ],
    });

    await FirebaseService.update(collectionUpdate.collection, collection[0].id, { email });
  }


  let profileAvatar = avatar?.preview;
  if (avatar.upload) {
    const extension = avatar.upload.name.split('.').pop();

    const ref = firebase.storage().ref(`users/profile-${id}.${extension}`);
    await ref.put(avatar.upload);
    profileAvatar = await ref.getDownloadURL();
  }

  await FirebaseService.update('user', id, {
    profileAvatar: profileAvatar || null,
    email,
  });

  await user.updateEmail(email);

  if (newPassword) {
    await user.updatePassword(newPassword);
  }

  return { profileAvatar };
};

export const removeUser = async userId => FirebaseService.remove('user', userId);

export const saveUser = async (data, idEdit) => {
  if (idEdit) {
    await FirebaseService.update('user', idEdit, data);
    return idEdit;
  }

  const newUser = await FirebaseService.createUserWithEmail(data.email, '123456');
  await FirebaseService.set('user', newUser.uid, {
    ...data,
    createdAt: new Date(),
  });
  return newUser.uid;
};
